// @flow
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

import type { DropdownProps } from './dropdown';
import Dropdown from '../../components/Dropdown';

type Props = DropdownProps;

const DropdownElement = (p: Props) => (
  <Dropdown
    options={p.answerOptions.map(option => ({
      label: ReactHtmlParser(option.text),
      value: option.answerOptionId,
    }))}
    mandatory={p.mandatory}
    disabled={p.disabled}
    id={p.id}
    onSelectedOption={value =>
      p.onSelectedOption(value != null ? parseInt(value) : null)
    }
    isClearable
    error={p.invalid}
    defaultValue={p.selectedOptionId}
  />
);

export default DropdownElement;

import Nettskjema from '../../nettskjema';

const isCompatible = () => {
  return fetch(`${Nettskjema.getBaseUrl()}/api/app/browsers/isSupported`)
    .then(res => res.json())
    .then(res => {
      if (res.supported === true) {
        return true;
      } else if (res.supported === false) {
        return false;
      } else {
        // unknown error, must assume browser is supported
        console.error('Could not determine browser');
        return true;
      }
    })
    .catch(error => {
      //unknown error, must assume browser is supported
      console.error('Could not determine browser ', error);
      return true;
    });
};

export default isCompatible;

// @flow

import { connect } from 'react-redux';

import * as Backend from '../../backend/legacyJsonBackend';
import { OptionElement } from './multipleOptions';

export type Correction = { correct: boolean };

export const OptionElementWithPossibleQuizResult = connect(
  (state, ownProps) => {
    const { submissionResponse } = state.answer;
    if (submissionResponse == null || submissionResponse.quizFeedback == null) {
      return ownProps;
    }
    const { quizFeedback } = submissionResponse;
    return {
      ...ownProps,
      correction: ownProps.checked
        ? getCorrection(
            quizFeedback.correctedAnswerOptions,
            ownProps.questionAnswerOptionId,
          )
        : undefined,
      correctButNotSelected:
        !ownProps.checked &&
        quizFeedback.fullSolution != null &&
        quizFeedback.fullSolution[ownProps.questionId] ===
          ownProps.questionAnswerOptionId,
    };
  },
)(OptionElement);

const getCorrection = (
  correctedAnswerOptions?: Backend.CorrectedAnswerOptionsMap,
  answerOptionId: number,
) => {
  if (correctedAnswerOptions == null) {
    return undefined;
  }
  const correction = correctedAnswerOptions[answerOptionId.toString()];
  return { correct: correction };
};

// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { connect, useDispatch } from 'react-redux';
import { responsive } from 'react-usit-ui';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import * as colors from './design/colors';
import { underlineButtonLink } from './design/focusFrame';
import FontAwesomeIcon from './design/icons';
import { ConfirmDialog } from './modals/ConfirmDialog';
import * as ModeTypes from './modes/types';
import { removeAlertDataMayBeLost } from './routing/redirect';
import type { Dispatch } from './store';

const style = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: colors.primary.dark,
    fontWeight: 'bold',
    fontSize: 18,
    margin: '10px 0',
    border: 'none',
    background: 'inherit',
    '&:hover': { cursor: 'pointer' },
    ...underlineButtonLink,
  },
  arrow: {
    marginLeft: 10,
    paddingTop: 2,
  },
  actionButton: {
    height: '50px',
    minWidth: '213px',
    borderColor: colors.primary.dark,
  },
  closeButton: {
    height: '50px',
    minWidth: '150px',
  },
  [responsive.media.max640]: {
    container: {
      justifyContent: 'flex-start',
    },
    arrow: { marginLeft: 8 },
    link: { margin: 0 },
  },
};

const getOldUIHref = () => {
  const search = new URLSearchParams(window.location.search);
  search.set('oldUI', '1');
  return '?' + search.toString();
};

type Classes = { [$Keys<typeof style>]: string };
const UnstyledHatch = (p: {
  classes: Classes,
  onClickLink: (mode: ModeTypes.StandaloneMode) => void,
  mode: ModeTypes.StandaloneMode,
}) => (
  <div className={p.classes.container}>
    <a
      className={p.classes.link}
      id="old-design"
      href={getOldUIHref()}
      onClick={e => {
        e.preventDefault();
        p.onClickLink(p.mode);
      }}
    >
      <Trans>Bytt til gammelt design</Trans>
      <FontAwesomeIcon className={p.classes.arrow} icon="arrow-right" />
    </a>
  </div>
);
const StyledHatch = injectSheet(style)(UnstyledHatch);
const ConnectedHatch = connect(
  state => ({
    anyFieldsFilledIn: state.answer.dirty,
  }),
  (dispatch: Dispatch) => ({
    onClickLink: mode => {
      dispatch({ type: 'OLD_DESIGN_REQUESTED', mode });
    },
  }),
)(StyledHatch);

const EscapeDialog = injectSheet(style)(
  (p: { classes: Classes, hideModal: () => void, onOK: () => void }) => {
    const dispatch = useDispatch();
    const openFeedbackDialog = () =>
      dispatch({ type: 'SHOW_MODAL', modalType: 'escapeHatchFeedbackDialog' });
    return (
      <I18n>
        {({ i18n }) => (
          <ConfirmDialog
            destructive
            dialogTitle={i18n._(
              t`Det du har fylt ut i skjemaet vil gå tapt hvis du bytter visning`,
            )}
            confirmAction={openFeedbackDialog}
            abortAction={p.hideModal}
            confirmText={i18n._(t`OK, bytt visning`)}
          >
            <Trans>
              <p>
                Du må starte utfylling av skjemaet på nytt hvis du bytter
                visning til gammelt design.
              </p>
            </Trans>
          </ConfirmDialog>
        )}
      </I18n>
    );
  },
);

export const ConfirmSwitchDesignModal = connect(
  () => ({}),
  (dispatch: Dispatch) => ({
    hideModal: () => {
      dispatch({ type: 'HIDE_MODAL' });
    },
    onOK: () => {
      dispatch({
        type: 'OLD_DESIGN_CONFIRMED',
      });
    },
  }),
)(EscapeDialog);

export const ErrorHatch = () => (
  <p>
    <Trans>
      Feil under visning av skjemaet. Våre utviklere er på saken. Prøv igjen
      senere, eller{' '}
      <a href={getOldUIHref()}>
        <Trans>
          trykk her for å åpne skjemaet i den gamle versjonen av Nettskjema
        </Trans>
      </a>
    </Trans>
  </p>
);

function redirectToOldDesign() {
  window.location.hash = '';
  window.location.search = getOldUIHref();
}

export function* onOldDesignRequested(action: any): Saga<void> {
  const anyFieldsFilledIn = yield select(state => state.answer.dirty);
  if (anyFieldsFilledIn && action.mode !== 'showSubmission') {
    yield put({ type: 'SHOW_MODAL', modalType: 'confirmSwitchDesign' });
  } else {
    yield put({ type: 'SHOW_MODAL', modalType: 'escapeHatchFeedbackDialog' });
  }
}
export function* onOldDesignConfirmed(): Saga<void> {
  yield call(removeAlertDataMayBeLost);
  yield call(redirectToOldDesign);
}

export default ConnectedHatch;

// @flow

import 'url-search-params-polyfill';

if (global.Intl == null) {
  global.Intl = require('intl');
  require('intl/locale-data/jsonp/en');
  require('intl/locale-data/jsonp/nb-NO');
  require('intl/locale-data/jsonp/nn-NO');
}

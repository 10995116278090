// @flow

import last from 'lodash/last';
import * as React from 'react';

import * as Backend from '../../backend/legacyJsonBackend';
import { makeFocusClassName } from '../../design/focusFrame';
import { MultipleOptions } from './multipleOptions';
import { OptionElementWithPossibleQuizResult } from './OptionElementWithPossibleQuizResult';

export const radioButtonCanBeUnchecked = (args: {
  checked: boolean,
  mandatory: boolean,
}) => args.checked && !args.mandatory;

const getCurrentIndex = (answerOptions: Backend.AnswerOption[]) => {
  const focusedElement = document.activeElement;
  const splitElementId = focusedElement && focusedElement.id.split('-');
  const focusedId = splitElementId && parseInt(last(splitElementId));
  const indexOfId = answerOptions.findIndex(
    (element: Backend.AnswerOption) => element.answerOptionId === focusedId,
  );

  return indexOfId > 0 ? indexOfId : 0;
};

const getNextIndex = (key: string, currentIndex: number, maxIndex: number) => {
  if (key === 'ArrowDown' || key === 'ArrowRight') {
    return currentIndex < maxIndex ? currentIndex + 1 : 0;
  } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
    return currentIndex > 0 ? currentIndex - 1 : maxIndex;
  } else {
    return currentIndex;
  }
};

export const getOnKeyDown = (
  answerOptions: Backend.AnswerOption[],
  selectElement: (id: number) => void,
  elementPrefix?: string,
) => (e: SyntheticKeyboardEvent<HTMLElement>) => {
  const currentIndex = getCurrentIndex(answerOptions);
  const nextIndex = getNextIndex(e.key, currentIndex, answerOptions.length - 1);

  if (currentIndex === nextIndex) return;
  const nextElement = document.getElementById(
    (elementPrefix ? elementPrefix : '') +
      `${answerOptions[nextIndex].answerOptionId}`,
  );
  selectElement(answerOptions[nextIndex].answerOptionId);
  nextElement && nextElement.focus();
  e.preventDefault();
};

const createIsIdOfFirstOption = (
  firstOption: ?number,
  selectedOption: ?number,
) => (id: string) => firstOption === id && selectedOption == null;

const RadioButtons = (p: {
  answerOptions: Backend.AnswerOption[],
  horizontal: boolean,
  onInputValue: (value: ?number) => void,
  selectedOptionId: ?number,
  invalid?: boolean,
  labelledBy: string,
  questionId: number,
  mandatory: boolean,
  responsive: boolean,
  disabled?: boolean,
}) => {
  const {
    answerOptions,
    selectedOptionId,
    mandatory,
    onInputValue,
    horizontal,
  } = p;
  const onKeyDown = getOnKeyDown(answerOptions, id => onInputValue(id));
  const isIdOfFirstOption = createIsIdOfFirstOption(
    answerOptions.length > 0 ? answerOptions[0].answerOptionId : null,
    selectedOptionId,
  );
  return (
    <MultipleOptions
      answerOptions={answerOptions}
      horizontal={horizontal}
      role="radiogroup"
      labelledBy={p.labelledBy}
      responsive={p.responsive}
      disabled={p.disabled}
      mandatoryMark={{ mandatory: p.mandatory, useAriaRequired: true }}
    >
      {answerOptions.map((answerOption, index) => {
        const checked = answerOption.answerOptionId === selectedOptionId;
        const selectOption = () =>
          radioButtonCanBeUnchecked({ checked, mandatory })
            ? onInputValue(null)
            : onInputValue(answerOption.answerOptionId);
        return (
          <OptionElementWithPossibleQuizResult
            disabled={p.disabled}
            inInvalidQuestion={p.invalid}
            key={`optionElement-${answerOption.answerOptionId}`}
            questionAnswerOptionId={answerOption.answerOptionId}
            horizontal={p.horizontal}
            checked={checked}
            last={index === answerOptions.length - 1}
            isIdOfFirstOption={isIdOfFirstOption}
            onSelect={selectOption}
            onKeyDown={onKeyDown}
            answerOption={answerOption}
            focusElement={
              answerOption.answerOptionId === answerOptions[0].answerOptionId
            }
            focusClass={makeFocusClassName(p.questionId)}
            type="radio"
            questionId={p.questionId}
          />
        );
      })}
    </MultipleOptions>
  );
};

export default RadioButtons;

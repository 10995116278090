// @flow

import { moment } from '../../../locale';
import getSettings from '../backend/settings';
import ValidationSequence from './ValidationSequence';

const { DATE_FORMAT, TIME_FORMAT } = getSettings();

export const validateTime = (value: string) =>
  ValidationSequence()
    .when(!isWellFormedTime(value), {
      includeError: 'invalid_time_format',
    })
    .when(isWellFormedTime(value) && !moment(value, TIME_FORMAT).isValid(), {
      includeError: 'time_does_not_exist',
    });

export const validateDate = (value: string) =>
  ValidationSequence()
    .when(!isWellFormedDate(value), {
      includeError: 'invalid_date_format',
    })
    .when(
      isWellFormedDate(value) &&
        (!moment(value, DATE_FORMAT).isValid() ||
          !moment(value, DATE_FORMAT).year() > 0),
      {
        includeError: 'date_does_not_exist',
      },
    );

export const validateDateTime = (dateValue: ?string, timeValue: ?string) =>
  ValidationSequence()
    .when(!dateValue && !!timeValue, {
      includeError: 'missing_date',
    })
    .when(!timeValue && !!dateValue, {
      includeError: 'missing_time',
    })
    .when(!!dateValue, {
      alsoPerform: () => validateDate((dateValue: $FlowIgnore)),
    })
    .when(!!timeValue, {
      alsoPerform: () => validateTime((timeValue: $FlowIgnore)),
    });

const isWellFormedDate = (value: string) =>
  /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/.test(value);
const isWellFormedTime = (value: string) => /^(\d{2}):(\d{2})$/.test(value);

// @flow

import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import * as Backend from '../backend/legacyJsonBackend';
import { elementForInput } from '../form/elements';
import type { FormInput } from '../inputs/types';
import { inputValidationForElement, invalidInput } from './validateInputs';

const allInputsValidated: (FormInput[], Backend.Form) => FormInput[] = (
  inputs,
  form,
) =>
  inputs.map(input => {
    const element = elementForInput(input, form);
    if (element == null) return input;
    return {
      ...input,
      validation: inputValidationForElement(
        element,
        input.value,
        input.questionId,
        inputs,
        form.pages,
      ),
    };
  });

const allInputsAreValid: (FormInput[]) => boolean = inputs =>
  inputs.find(invalidInput) == null;

export function* validateForm(): Saga<boolean> {
  const { form, inputs } = yield select(state => state.answer);
  const validatedInputs = allInputsValidated(inputs, form);
  yield put({
    type: 'VALIDATION_PERFORMED',
    payload: { validatedInputs },
  });
  return yield call(allInputsAreValid, validatedInputs, form);
}

export const forTesting = { allInputsAreValid };

// @flow

import * as React from 'react';
// $FlowIgnore
import { connect, useDispatch } from 'react-redux';

import { StyledConnectedFormContainer } from '../form/FormContainer';
import * as Loading from '../form/loading';
import Receipt from '../form/Receipt';
import type { Mode } from '../modes';
import { ShowSubmissionContainer } from '../modes/editSubmission/showSubmission';
import { formUIId } from './formUIId';
import { IframeFormContainer } from './iframeEmbedding/container';
import FormPreviewContainer from './preview';
import { ReactEmbeddedFormContainer } from './reactEmbeddingContainer';

const FormUI = (p: {
  formLoadingStatus: Loading.FormLoadingStatus,
  hasResponse: boolean,
  mode: Mode,
  onAbort?: () => void,
  submitText?: string,
  abortText?: string,
}) => {
  const dispatch = useDispatch();
  dispatch({ type: 'SET_MODE', mode: p.mode });

  let Component: ?React.ComponentType<{
    onAbort?: () => void,
    mode?: ?Mode,
    submitText?: string,
    abortText?: string,
  }>;
  if (p.formLoadingStatus === 'NOT_STARTED') {
    Component = Loading.PreparingLoad;
  } else if (p.formLoadingStatus === 'LOADING') {
    Component = Loading.LoadingForm;
  } else if (p.formLoadingStatus === 'FAILED') {
    Component = Loading.Failed;
  } else if (p.formLoadingStatus === 'LOADED') {
    if (p.hasResponse && p.mode !== 'showSubmission') {
      Component = Receipt;
    } else if (p.mode === 'preview') {
      Component = FormPreviewContainer;
    } else if (p.mode === 'iframeEmbedded') {
      Component = IframeFormContainer;
    } else if (p.mode === 'reactEmbedded') {
      Component = ReactEmbeddedFormContainer;
    } else if (p.mode === 'showSubmission') {
      Component = ShowSubmissionContainer;
    } else {
      Component = StyledConnectedFormContainer;
    }
  }
  if (Component == null) {
    throw new Error(
      `No UI component found for prop combination ${JSON.stringify(p)}`,
    );
  }
  return (
    <div
      id={formUIId}
      tabIndex={-1}
      style={{
        outline: 'none',
        wordBreak: 'break-word',
        overflowWrap: 'anywhere',
        hyphens: 'auto',
      }}
      role="form"
    >
      <Component onAbort={p.onAbort} mode={p.mode} />
    </div>
  );
};

export const forTesting = { FormUI };

export const FormDisplay = connect(
  (
    { answer: { submissionResponse, formLoadingStatus } },
    ownProps: { mode: Mode, onAbort?: () => void },
  ) => ({
    mode: ownProps.mode,
    onAbort: ownProps.onAbort,
    formLoadingStatus,
    hasResponse: submissionResponse != null,
  }),
  () => ({}),
)(FormUI);

// @flow

import { Trans } from '@lingui/macro';
import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Button, Row, responsive } from 'react-usit-ui';

import * as Backend from '../../backend/legacyJsonBackend';
import infoIcon from '../../components/info-blue.svg';
import * as colors from '../../design/colors';
import { ConnectedAllPages } from '../../form/AllPages';
import FormTitle from '../../form/Title';
import { Score } from '../../question-elements/multiple-choice/QuizFeedback';
import type { Dispatch } from '../../store';
import { triggerDeleteModal } from './ConfirmDeleteModal';

const style = {
  info: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 18,
    backgroundColor: colors.infoMessage.background,
    borderColor: '#64a8d1',
    borderWidth: 2,
    borderStyle: 'solid',
    padding: [16, 25, 20, 25],
    margin: [20, 0],
    borderRadius: 4,
    alignItems: 'center',
  },
  infoIcon: { marginRight: 16 },
  infoText: { flex: 1, minWidth: 100 },
  actionButton: {
    marginLeft: 44,
    fontWeight: 'bold',
    color: colors.primary.dark,
    backgroundColor: 'transparent',
    fontSize: '100%',
  },
  [responsive.media.max640]: {
    actionButton: {
      marginLeft: 0,
      paddingLeft: 36,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const InfoContainer = injectSheet(style)(
  (p: { children: React.Node, classes: Classes }) => (
    <div className={p.classes.info}>
      <img alt="Info" src={infoIcon} className={p.classes.infoIcon} />
      {p.children}
    </div>
  ),
);

const DeliveryStatusText = (p: {
  delivered: boolean,
  type: Backend.FormType,
}) => {
  switch (p.type) {
    case 'MULTIPLE_CHOICE':
      return p.delivered ? (
        <Trans>Svaret er levert</Trans>
      ) : (
        <Trans>Svaret er ikke levert</Trans>
      );
    case 'SIGN_UP':
      return p.delivered ? (
        <Trans>Påmeldingen er levert</Trans>
      ) : (
        <Trans>Påmeldingen er ikke levert</Trans>
      );
    default:
      return p.delivered ? (
        <Trans>Svaret er levert</Trans>
      ) : (
        <Trans>Svaret er ikke levert</Trans>
      );
  }
};

const EditSubmissionText = (p: { type: Backend.FormType }) => {
  switch (p.type) {
    case 'SIGN_UP':
      return <Trans>Rediger påmeldingen</Trans>;
    default:
      return <Trans>Rediger svaret</Trans>;
  }
};

const DeleteSubmissionText = (p: { type: Backend.FormType }) => {
  switch (p.type) {
    case 'SIGN_UP':
      return <Trans>Slett påmeldingen</Trans>;
    default:
      return <Trans>Slett svaret</Trans>;
  }
};

const SubmittedInfo = injectSheet(style)(
  (p: {
    classes: Classes,
    delivered: boolean,
    onDeleteRequested: () => void,
    formType: Backend.FormType,
  }) => (
    <InfoContainer>
      <span className={p.classes.infoText}>
        <DeliveryStatusText delivered={p.delivered} type={p.formType} />
      </span>
      <span>
        <Button
          type="text"
          className={p.classes.actionButton}
          onClick={() => {
            window.location.assign('edit.html' + window.location.search);
          }}
        >
          <EditSubmissionText type={p.formType} />
        </Button>
        <Button
          type="text"
          className={p.classes.actionButton}
          onClick={p.onDeleteRequested}
        >
          <DeleteSubmissionText type={p.formType} />
        </Button>
      </span>
    </InfoContainer>
  ),
);

const DeletedInfo = injectSheet(style)((p: { classes: Classes => void }) => (
  <InfoContainer>
    <span className={p.classes.infoText}>
      <Trans>Svaret er slettet</Trans>
    </span>
  </InfoContainer>
));

const NotOpenInfo = injectSheet(style)((p: { classes: Classes => void }) => (
  <InfoContainer>
    <span className={p.classes.infoText}>
      <Trans>Skjemaet er stengt, du kan ikke lenger endre svaret ditt.</Trans>
    </span>
  </InfoContainer>
));

const Submission = (p: {
  onDeleteRequested: () => void,
  canDeliverSubmission: boolean,
  deleted: boolean,
  delivered: boolean,
  open: boolean,
  formType: Backend.FormType,
  quizFeedback: ?Backend.QuizFeedback,
}) => (
  <React.Fragment>
    <Row>
      <FormTitle />
    </Row>
    <Row>
      {p.deleted ? (
        <DeletedInfo />
      ) : !p.open ? (
        <NotOpenInfo />
      ) : (
        p.canDeliverSubmission != null &&
        p.canDeliverSubmission && (
          <SubmittedInfo
            formType={p.formType}
            delivered={p.delivered}
            onDeleteRequested={p.onDeleteRequested}
          />
        )
      )}
    </Row>
    {!p.deleted && (
      <Row>
        {p.quizFeedback && p.quizFeedback.score && (
          <Score quizFeedback={p.quizFeedback} />
        )}
        <ConnectedAllPages disabled />
      </Row>
    )}
  </React.Fragment>
);

export const ShowSubmissionContainer = connect(
  ({ answer: { form, submissionDeleted, submissionResponse } }) => ({
    canDeliverSubmission: form.meta.canDeliverSubmission,
    deleted: submissionDeleted,
    delivered: form.meta.showingDeliveredSubmission,
    open: form.meta.open,
    formType: form.meta.type,
    quizFeedback: submissionResponse && submissionResponse.quizFeedback,
  }),
  (dispatch: Dispatch) => ({
    onDeleteRequested: () => triggerDeleteModal(dispatch),
  }),
)(Submission);

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classnames from 'classnames';
import React from 'react';
import { Handles, Rail, Slider, Ticks } from 'react-compound-slider';
import { useSelector } from 'react-redux';

import s from './slider.less';

const Handle = ({
  max,
  min,
  handle,
  getHandleProps,
  isActive,
  setActive,
  setInactive,
  onInputValue,
  active,
  minimumValueText,
  maximumValueText,
  componentId,
  vertical,
  handleRef,
  questionTitleId,
  setHandlePercentage,
}) => {
  const { id, value, percent } = handle;

  const valueText =
    value === max
      ? maximumValueText
      : value === min
      ? minimumValueText
      : undefined;

  React.useEffect(() => {
    setHandlePercentage(isActive ? (vertical ? 100 - percent : percent) : null);
  }, [isActive, percent, vertical, setHandlePercentage]);

  return (
    <div
      id={componentId}
      ref={handleRef}
      className={classnames([
        s.handle,
        vertical && s.vertical,
        { [s.active]: active },
        `${componentId}-focus`,
        !isActive && s.inactiveHandle,
      ])}
      role="slider"
      tabIndex="0"
      aria-labelledby={questionTitleId}
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={isActive ? value : -1}
      aria-valuetext={valueText}
      onFocus={event => {
        if (!isActive) {
          setActive(true);
          onInputValue([Math.round((min + max) / 2)]);
          event.preventDefault();
        }
      }}
      onKeyPress={event => {
        if (event.key === ' ' && isActive) {
          setInactive();
          event.preventDefault();
        } else if (event.key === ' ' && !isActive) {
          setActive(true);
          onInputValue([value]);
          event.preventDefault();
        }
      }}
      style={
        vertical
          ? {
              top: `${percent}%`,
            }
          : {
              left: `${percent}%`,
            }
      }
      {...getHandleProps(id)}
    ></div>
  );
};

const ValueField = ({
  value,
  onInputValue,
  min,
  max,
  setSliderActive,
  setSliderInactive,
  questionId,
  mandatory,
  vertical,
}) => {
  const inputFieldWidth = 60 + max.toString().length * 4;
  const [fieldValue, setFieldValue] = React.useState('');
  const [localState, setLocalState] = React.useState(false);
  const sliderValue = value != null ? value : '';

  return (
    <I18n>
      {({ i18n }) => (
        <div className={s.valueFieldContainer}>
          {vertical && (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label htmlFor={questionId}>
              <Trans>Verdi</Trans>
            </label>
          )}
          <input
            className={s.valueField}
            style={{
              width: `${inputFieldWidth}px`,
            }}
            value={localState ? fieldValue : sliderValue}
            type="number"
            id={questionId}
            aria-label={i18n._(t`Verdi`)}
            min={min}
            max={max}
            step={1}
            onBlur={() => {
              setLocalState(false);
              if (fieldValue !== value && value != null) {
                setFieldValue(value);
              }
            }}
            onFocus={() => {
              if (fieldValue !== value && value != null) {
                setFieldValue(value);
              }
              setLocalState(true);
            }}
            placeholder="-"
            onChange={event => {
              const inputValue = event.target.value;
              const numberValue = Number.parseInt(inputValue);
              if (numberValue > max) {
                setFieldValue(max);
                setSliderActive();
                onInputValue([max]);
              } else if (numberValue < min) {
                setFieldValue(numberValue);
                if (!mandatory) {
                  setSliderInactive();
                  onInputValue([]);
                }
              } else if (inputValue === '') {
                setFieldValue('');
                setSliderInactive();
                onInputValue([]);
              } else if (!isNaN(numberValue)) {
                setFieldValue(numberValue);
                setSliderActive();
                onInputValue([numberValue]);
              }
            }}
          />
        </div>
      )}
    </I18n>
  );
};

const Tick = ({
  tick,
  showTickValue,
  chosenValue,
  vertical,
  variedTicks,
  componentId,
  isHovering,
  isNearHandle,
}) => {
  const activeTick = chosenValue && chosenValue[0] == tick.value;
  const tickValueLength = tick.value.toString().length;
  const marginLeftHorizontalTickValue =
    showTickValue && !vertical
      ? tickValueLength == 1
        ? 10
        : 8 - tickValueLength * 2
      : 0;
  const isVariedTick = variedTicks && tick.value % 2 !== 0;

  return (
    <div
      className={classnames([
        s.tick,
        (isHovering || isNearHandle) && s.offset,
        isNearHandle && s.nearHandle,
        vertical && s.vertical,
        isVariedTick && s.variedTick,
      ])}
      style={
        vertical
          ? {
              top: `${tick.percent}%`,
            }
          : {
              left: `${tick.percent}%`,
            }
      }
    >
      {showTickValue && !isVariedTick && (
        <div
          className={classnames([
            s.tickValue,
            activeTick && s.activeTickValue,
            vertical && s.vertical,
            variedTicks && s.variedTick,
          ])}
        >
          <span
            id={componentId}
            className={vertical ? s.vertical : undefined}
            style={{
              marginLeft: `${marginLeftHorizontalTickValue}px`,
            }}
          >
            {tick.value}
          </span>
        </div>
      )}
    </div>
  );
};

const TextMarkers = ({
  vertical,
  linearScaleType,
  maximumValue,
  minimumValue,
  maximumValueText,
  minimumValueText,
  midValueText,
  showTickValues,
}) => {
  const displayMin =
    linearScaleType === 'TEXT_AND_NUM' || minimumValueText !== null
      ? minimumValueText
      : minimumValue;
  const displayMax =
    linearScaleType === 'TEXT_AND_NUM' || maximumValueText !== null
      ? maximumValueText
      : maximumValue;

  return (
    <div className={classnames([s.endMarkers, vertical && s.vertical])}>
      {!vertical && (
        <div
          className={classnames([
            s.text,
            s.endMarkerLeft,
            showTickValues && s.showTickValues,
          ])}
        >
          {displayMin}
        </div>
      )}

      {(linearScaleType === 'START_MID_END' ||
        linearScaleType === 'TEXT_AND_NUM') &&
        midValueText !== null && (
          <div
            className={classnames([
              s.text,
              s.midMarker,
              vertical && s.vertical,
              showTickValues && s.showTickValues,
            ])}
          >
            {midValueText}
          </div>
        )}
      {!vertical && (
        <div
          className={classnames([
            s.text,
            s.endMarkerRight,
            showTickValues && s.showTickValues,
          ])}
        >
          {displayMax}
        </div>
      )}
    </div>
  );
};

const RemoveValue = ({ onClick, show }) => (
  <I18n>
    {({ i18n }) => (
      <div className={s.removeButtonContainer}>
        {show ? (
          <button
            className={s.removeButton}
            onClick={onClick}
            aria-label={i18n._(t`Nullstill`)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2L2 14"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 2L14 14"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        ) : null}
      </div>
    )}
  </I18n>
);

const getValuePercentage = (value, min, max) =>
  ((value - min) / (max - min)) * 100;

const getNearestValidPercentage = (percentage, min, max) => {
  const percentageValue = Math.max(
    Math.min(Math.round((percentage / 100) * (max - min) + min), max),
    min,
  );
  return getValuePercentage(percentageValue, min, max);
};

const RailComponent = ({
  min,
  max,
  getRailProps,
  vertical,
  handleRef,
  setHoverPercentage,
  hoverPercentage,
}) => {
  const onPointerMove = e => {
    if (e.pointerType === 'mouse') {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;
      var percentage = vertical
        ? 100 - (y / rect.height) * 100
        : (x / rect.width) * 100;
      setHoverPercentage(getNearestValidPercentage(percentage, min, max));
    }
  };

  return (
    <div
      className={classnames([s.railContainer, vertical && s.vertical])}
      {...getRailProps()}
      role="presentation"
      onClick={() => {
        if (handleRef) handleRef.current.focus();
      }}
      onKeyDown={() => {}}
      onPointerMove={onPointerMove}
      onPointerLeave={() => setHoverPercentage(null)}
    >
      <div className={classnames([s.rail, vertical && s.vertical])} />
      <div
        className={s.hoverCircle}
        style={{
          display: hoverPercentage === null ? 'none' : 'block',
          bottom: vertical ? `calc(${hoverPercentage}% - 10px)` : -13,
          left: vertical ? 6 : `calc(${hoverPercentage}% - 6px)`,
        }}
      />
    </div>
  );
};

const measureMaxValueWidth = textContent => {
  const ctx = document.createElement('canvas').getContext('2d');
  ctx.font = 'bold 16px Arial';
  return ctx.measureText(textContent).width;
};

const SliderComponent = props => {
  const { linearScaleType, question, onInputValue, id } = props;

  const [isActive, setActive] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [hoverPercentage, setHoverPercentage] = React.useState(null);
  const [handlePercentage, setHandlePercentage] = React.useState(null);

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  const handleRef = React.useRef(null);

  const mandatory = question.mandatory;
  const min = question.minimumValue;
  const max = question.maximumValue;
  const minimumValueText = question.minimumValueText;
  const midValueText = question.midValueText;
  const maximumValueText = question.maximumValueText;
  const vertical = !question.horizontal;

  const showTickValues =
    linearScaleType === 'ALL' || linearScaleType === 'TEXT_AND_NUM';

  const showStartEndText =
    linearScaleType !== 'ALL' && linearScaleType !== 'NONE';

  const showMidText =
    (linearScaleType === 'START_MID_END' ||
      linearScaleType === 'TEXT_AND_NUM') &&
    midValueText !== null;

  const chosenValue = useSelector(
    state =>
      state.answer.inputs.find(
        input => input.questionId === question.questionId,
      ).value.given,
  );

  /* Beregninger av margin-left på vertical slider, topp- og bunntekst. 
     Iom. at vi har absoluttverdier på brukerinput (tickvalues), så må dette gjøres. 
  */

  const maxElement = document.getElementById(`${id}-tick-0`);
  const clientWidthForMaxValue =
    maxElement !== null ? measureMaxValueWidth(maxElement.textContent) : 0;

  const rangeMarkOffset = question.rangeMarksShown ? 20 : 0;
  const marginLeftOnverticalEdgeText =
    104 + clientWidthForMaxValue + rangeMarkOffset;
  const marginLeftOnSliderContainer = vertical
    ? 145 + clientWidthForMaxValue + rangeMarkOffset
    : 0;

  const getTickValues = () => {
    if (!question.rangeMarksShown) {
      return [min, max];
    } else {
      return null;
    }
  };

  const getNumberOfTicks = () => {
    if (min === 0 && max === 100) {
      return 20;
    }
    return max - min < 11 ? max - min : 11;
  };

  const setSliderActive = () => {
    setActive(true);
  };

  const setSliderInactive = () => {
    if (!mandatory) {
      onInputValue([]);
      setActive(false);
      setLoaded(false);
    }
  };

  const valueContainer = (
    <div className={classnames([s.valueContainer, vertical && s.vertical])}>
      <ValueField
        min={min}
        max={max}
        onInputValue={onInputValue}
        setSliderActive={setSliderActive}
        setSliderInactive={setSliderInactive}
        value={chosenValue}
        questionId={question.questionId}
        mandatory={mandatory}
        vertical={vertical}
      />
      {!mandatory && (
        <RemoveValue onClick={setSliderInactive} show={isActive} />
      )}
    </div>
  );

  return (
    <div className={classnames([s.slid, vertical && s.vertical])}>
      {vertical && (
        <div
          className={classnames([s.verticalEdgeText, s.top])}
          style={{ marginLeft: `${marginLeftOnverticalEdgeText}px` }}
        >
          {maximumValueText}
        </div>
      )}
      <div className={classnames([s.sliderBox, vertical && s.vertical])}>
        {vertical && valueContainer}
        {linearScaleType !== 'ALL' &&
          linearScaleType !== 'NONE' &&
          ((!vertical && showStartEndText) || (vertical && showMidText)) && (
            <TextMarkers
              questionId={id}
              vertical={vertical}
              linearScaleType={linearScaleType}
              minimumValue={min}
              maximumValue={max}
              minimumValueText={minimumValueText}
              midValueText={midValueText}
              maximumValueText={maximumValueText}
              showTickValues={showTickValues}
            />
          )}
        <div style={{ marginLeft: marginLeftOnSliderContainer }}>
          <Slider
            vertical={vertical}
            reversed={vertical}
            className={classnames([s.sliderContainer, vertical && s.vertical])}
            domain={[min, max]}
            step={1}
            mode={2}
            values={[
              chosenValue != null && chosenValue.length != 0
                ? chosenValue[0]
                : -1,
            ]}
            onChange={value => {
              if (value >= min && loaded) {
                setActive(true);
                onInputValue(value);
              }
            }}
          >
            <Ticks count={getNumberOfTicks()} values={getTickValues()}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {[...ticks].reverse().map((tick, idx) => {
                    const tickPercentage = getValuePercentage(
                      tick.value,
                      min,
                      max,
                    );
                    return (
                      <Tick
                        variedTicks={getNumberOfTicks() === 20}
                        key={tick.id}
                        tick={tick}
                        count={ticks.length}
                        showTickValue={showTickValues}
                        chosenValue={chosenValue}
                        vertical={vertical}
                        componentId={`${id}-tick-${idx}`}
                        isHovering={
                          hoverPercentage != null &&
                          Math.abs(hoverPercentage - tickPercentage) < 2
                        }
                        isNearHandle={
                          handlePercentage != null &&
                          Math.abs(handlePercentage - tickPercentage) < 2.5
                        }
                      />
                    );
                  })}
                </div>
              )}
            </Ticks>
            <Rail>
              {({ getRailProps }) => (
                <RailComponent
                  getRailProps={getRailProps}
                  vertical={vertical}
                  handleRef={handleRef}
                  setHoverPercentage={setHoverPercentage}
                  hoverPercentage={hoverPercentage}
                  min={min}
                  max={max}
                />
              )}
            </Rail>
            <Handles>
              {props => {
                const { handles, getHandleProps, activeHandleID } = props;

                return (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <Handle
                        componentId={`${id}-handle-${handle.id}`}
                        active={activeHandleID === handle.id}
                        isActive={isActive}
                        setActive={setSliderActive}
                        setInactive={setSliderInactive}
                        onInputValue={onInputValue}
                        linearScaleType={linearScaleType}
                        min={min}
                        max={max}
                        minimumValueText={minimumValueText}
                        midValueText={midValueText}
                        maximumValueText={maximumValueText}
                        key={handle.id}
                        handle={handle}
                        getHandleProps={getHandleProps}
                        vertical={vertical}
                        handleRef={handleRef}
                        questionTitleId={id}
                        setHandlePercentage={setHandlePercentage}
                      />
                    ))}
                  </div>
                );
              }}
            </Handles>
          </Slider>
        </div>
      </div>
      {vertical && (
        <div
          className={classnames([s.verticalEdgeText, s.bottom])}
          style={{ marginLeft: `${marginLeftOnverticalEdgeText}px` }}
        >
          {minimumValueText}
        </div>
      )}
      {!vertical && valueContainer}
    </div>
  );
};

export default SliderComponent;

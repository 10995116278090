import * as colors from '../../design/colors';
// @flow
import { focusFrameDate } from '../../design/focusFrame';
// Adapted from https://github.com/gpbl/react-day-picker/blob/master/src/style.css
export default {
  '& .DayPicker': {
    display: 'inline-block',
  },
  '& .DayPicker-wrapper': {
    position: 'relative',
    flexDirection: 'row',
    marginTop: 6,
    userSelect: 'none',
    borderRadius: 4,
    boxShadow: [0, 2, 11, 0, '#807e7e'],
    border: '2px solid #dddddd',
  },
  '& .DayPicker-Months': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  '& .DayPicker-Month': {
    display: 'table',
    margin: [8, 3, 10, 3],
    borderSpacing: '0',
    borderCollapse: 'collapse',
    userSelect: 'none',
  },

  '& .DayPicker-Weekdays': {
    display: 'table-header-group',
    marginTop: '1em',
  },

  '& .DayPicker-WeekdaysRow': {
    display: 'table-row',
  },

  '& .DayPicker-Weekday': {
    display: 'table-cell',
    padding: '0.5em',
    color: '#000000',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },

  '& .DayPicker-Weekday abbr[title]': {
    borderBottom: 'none',
    textDecoration: 'none',
  },

  '& .DayPicker-Body': {
    display: 'table-row-group',
  },

  '& .DayPicker-Week': {
    display: 'table-row',
  },

  '& .DayPicker-Day': {
    display: 'table-cell',
    padding: [9, 13],
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    '&:focus': { ...focusFrameDate },
  },

  '& .DayPicker-WeekNumber': {
    display: 'table-cell',
    padding: '0.5em',
    minWidth: '1em',
    borderRight: '1px solid #EAECEC',
    color: '#8B9898',
    verticalAlign: 'middle',
    textAlign: 'right',
    fontSize: '0.75em',
    cursor: 'pointer',
  },

  '& .DayPicker--interactionDisabled .DayPicker-Day': {
    cursor: 'default',
  },

  '& .DayPicker-Footer': {
    paddingTop: '0.5em',
  },

  '& .DayPicker-TodayButton': {
    border: 'none',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    boxShadow: 'none',
    color: '#4A90E2',
    fontSize: '0.875em',
    cursor: 'pointer',
  },

  /* Default modifiers */

  '& .DayPicker-Day.DayPicker-Day--today': {
    color: colors.primary.dark,
    backgroundColor: colors.primary.border,
  },

  '& .DayPicker-Day.DayPicker-Day--outside': {
    color: '#dddddd',
    cursor: 'default',
  },

  '& .DayPicker-Day.DayPicker-Day--disabled': {
    color: '#DCE0E0',
    cursor: 'default',
  },

  /* Example modifiers */

  '& .DayPicker-DayPicker-Day-Sunday': {},

  '& .DayPicker-DayPicker-Day--sunday:not(.DayPicker-Day-Today)': {},

  '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day-Outside)': {
    position: 'relative',
    backgroundColor: '#1c5081',
    color: '#ffffff',
  },

  '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day-Outside):hover': {},

  '& .DayPicker :not(.DayPicker--interactionDisabled), .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day-Outside):hover': {},
};

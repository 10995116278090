// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
// $FlowIgnore
import { useEffect, useState } from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useSelector } from 'react-redux';
import { Input, TextArea, responsive } from 'react-usit-ui';

import type { ElementType } from '../backend/legacyJsonBackend';
import getSettings from '../backend/settings';
import * as colors from '../design/colors';
import { focusClassNameFromStringId } from '../design/focusFrame';
import type { Validation } from '../submission-validation/types';
import { invalid } from '../submission-validation/types';

export const textInputHeight = 45;
const style = {
  textField: {
    color: colors.ns.black,
    borderColor: colors.primary.dark,
    minWidth: 322,
    height: [textInputHeight, '!important'],
    '-webkit-appearance': 'none',
  },
  textArea: {
    maxWidth: '100%',
    fontFamily: 'inherit',
    fontSize: 18,
    borderColor: colors.primary.dark,
  },
  [responsive.media.max640]: {
    textField: { minWidth: 'unset' },
  },
  disabled: {
    color: colors.ns.black,
    '-webkit-text-fill-color': colors.ns.black,
    opacity: 1,
    backgroundColor: colors.disabledBackground,
    borderColor: colors.disabledBackground,
  },
  caption: {
    color: colors.ns.greyishBrown,
    minHeight: 40,
    fontSize: 14,
    lineHeight: 1.5,
    marginLeft: 4,
    marginTop: 4,
    maxWidth: 285,
  },
  limitReached: {
    boxShadow: '0 0 0 4px #c50000 !important',
  },
};

const errorIdForField = (field: string) => `${field}-error`;
const ariaInvalid = (validation: Validation) =>
  validation != null ? invalid(validation) : false;

export const ariaValidationErrors = (
  validation: Validation,
  fieldErrorId: string,
) => {
  return {
    'aria-describedby': ariaInvalid(validation)
      ? errorIdForField(fieldErrorId)
      : null,
    'aria-invalid': ariaInvalid(validation),
  };
};

function preventValidationOfUneditedField<T>(
  Component: React.ComponentType<T>,
) {
  return class extends React.Component<
    {
      ...T,
      onBlur: (SyntheticInputEvent<HTMLInputElement>) => void,
      onChange: (SyntheticInputEvent<HTMLInputElement>) => void,
    },
    { edited: boolean },
  > {
    displayName = 'PreventValidationOfUneditedField';
    constructor() {
      super();
      this.state = { edited: false };
    }
    render() {
      const props = {
        ...this.props,
        onBlur: e => {
          e.persist();
          if (!this.state.edited && !e.target.value) {
            return;
          }
          this.props.onBlur(e);
          this.setState({ edited: true });
        },
        onChange: e => {
          e.persist();
          this.props.onChange(e);
          this.setState({ edited: true });
        },
      };
      return <Component {...props} />;
    }
  };
}

const CharacterCountStatus = ({
  charCount,
  limit,
}: {
  charCount: number,
  limit: number,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }}
  >
    <div>
      <p style={{ margin: 0 }}>
        {charCount}/{limit}
      </p>
    </div>
  </div>
);

const useCharLimit = (defaultValue: string, maxNumberOfCharacters?: number) => {
  const [charCount, setCharCount] = useState(defaultValue.length);
  const [showLimitReachedError, setShowLimitReachedError] = useState(false);

  useEffect(() => {
    if (showLimitReachedError) {
      const reset = setTimeout(() => setShowLimitReachedError(false), 500);
      return () => clearTimeout(reset);
    }
  }, [showLimitReachedError]);

  const onKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key.length === 1 && charCount === maxNumberOfCharacters) {
      setShowLimitReachedError(true);
    } else if (showLimitReachedError && e.key === 'Backspace') {
      setShowLimitReachedError(false);
    }
  };

  return { onKeyDown, charCount, setCharCount, showLimitReachedError };
};

const getElementTypeCaption = (i18n: I18n, elementType: ElementType) => {
  switch (elementType) {
    case 'USERNAME':
      return i18n._(t`Brukernavnet ditt`);
    case 'EMAIL':
      return i18n._(t`E-postadressen din`);
    case 'PHONE':
      return i18n._(t`Mobilnummeret ditt`);
    case 'NATIONAL_ID_NUMBER':
    case 'IDPORTEN_NATIONAL_ID':
      return i18n._(t`Fødselsnummeret ditt`);
    default:
      return i18n._(t`Svaret ditt`);
  }
};

const InputCaption = injectSheet(style)(
  (p: {
    classes: { [$Keys<typeof style>]: string },
    isAutofilled: boolean,
    isAnswerHased: boolean,
    elementType: ElementType,
  }) => (
    <I18n>
      {({ i18n }) => (
        <div className={p.classes.caption}>
          {p.isAutofilled && <Trans>Feltet er automatisk utfylt</Trans>}
          {p.isAutofilled && p.isAnswerHased && '. '}
          {p.isAnswerHased && (
            <React.Fragment>
              <Trans>
                {getElementTypeCaption(i18n, p.elementType)} blir ikke lagret,
                men vil bli brukt til å lage en unik reproduserbar kode som ikke
                kan kobles tilbake til deg.{' '}
                <a
                  href="https://www.uio.no/tjenester/it/adm-app/nettskjema/hjelp/pseudo.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer om hashing.
                </a>
              </Trans>
            </React.Fragment>
          )}
        </div>
      )}
    </I18n>
  ),
);

export const AccessibleInput = injectSheet(style)(
  preventValidationOfUneditedField(
    (p: {
      className: string,
      validation: Validation,
      focusElement?: boolean,
      formElementId: string,
      disabled?: boolean,
      onBlur: (SyntheticInputEvent<HTMLInputElement>) => void,
      customErrorId?: string,
      defaultValue: string,
      classes: { [$Keys<typeof style>]: string },
      maxNumberOfCharacters?: number,
      isAnswerHashed?: boolean,
      elementType: ElementType,
    }) => {
      const isReceiptPage = useSelector(
        state => state.answer && !!state.answer.submissionResponse,
      );
      const {
        validation,
        customErrorId,
        formElementId,
        focusElement,
        maxNumberOfCharacters,
        isAnswerHashed,
        elementType,
        defaultValue,
        ...inputProps
      } = p;

      const {
        onKeyDown,
        charCount,
        setCharCount,
        showLimitReachedError,
      } = useCharLimit(defaultValue, maxNumberOfCharacters);

      const showCaption =
        !isReceiptPage &&
        getSettings().mode !== 'showSubmission' &&
        (p.disabled || isAnswerHashed);

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <React.Fragment>
            <Input
              maxLength={maxNumberOfCharacters}
              defaultValue={defaultValue}
              {...inputProps}
              {...ariaValidationErrors(
                validation,
                customErrorId ? customErrorId : formElementId,
              )}
              className={classNames([
                p.classes.textField,
                p.className,
                p.disabled && p.classes.disabled,
                showLimitReachedError && p.classes.limitReached,
                focusElement && focusClassNameFromStringId(formElementId),
              ])}
              onKeyDown={onKeyDown}
              onChange={e => {
                setCharCount(e.target.value.length);
                // $FlowIgnore
                // eslint-disable-next-line flowtype-errors/show-errors
                inputProps.onChange && inputProps.onChange(e);
              }}
            />
            {showCaption && (
              <InputCaption
                isAnswerHased={isAnswerHashed}
                isAutofilled={p.disabled}
                elementType={elementType}
              />
            )}
          </React.Fragment>
          {!!maxNumberOfCharacters && (
            <CharacterCountStatus
              charCount={charCount}
              limit={maxNumberOfCharacters}
            />
          )}
        </div>
      );
    },
  ),
);

export const AccessibleTextarea = injectSheet(style)(
  preventValidationOfUneditedField(
    (p: {
      validation: Validation,
      formElementId: string,
      defaultValue: string,
      maxNumberOfCharacters?: number,
      isAnswerHashed?: boolean,
      elementType: ElementType,
      onBlur: (SyntheticInputEvent<HTMLInputElement>) => void,
      disabled?: boolean,
      classes: { [$Keys<typeof style>]: string },
    }) => {
      const {
        validation,
        formElementId,
        defaultValue,
        maxNumberOfCharacters,
        isAnswerHashed,
        elementType,
        ...inputProps
      } = p;

      const {
        onKeyDown,
        charCount,
        setCharCount,
        showLimitReachedError,
      } = useCharLimit(defaultValue, maxNumberOfCharacters);

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <TextArea
              maxLength={maxNumberOfCharacters}
              defaultValue={defaultValue}
              {...inputProps}
              className={classNames([
                p.classes.textArea,
                p.disabled && p.classes.disabled,
                showLimitReachedError && p.classes.limitReached,
              ])}
              onKeyDown={onKeyDown}
              onChange={e => {
                setCharCount(e.target.value.length);
                // $FlowIgnore
                // eslint-disable-next-line flowtype-errors/show-errors
                inputProps.onChange && inputProps.onChange(e);
              }}
              {...ariaValidationErrors(validation, formElementId)}
            />

            {!!maxNumberOfCharacters && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <CharacterCountStatus
                  charCount={charCount}
                  limit={maxNumberOfCharacters}
                />
              </div>
            )}
            {isAnswerHashed && (
              <InputCaption
                isAnswerHased={isAnswerHashed}
                elementType={elementType}
              />
            )}
          </div>
        </div>
      );
    },
  ),
);

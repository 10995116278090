// @flow

import type { Saga } from 'redux-saga';

import type { BackendInterface } from '../backend/interface';
import getSettings from '../backend/settings';
import { defaultSaga } from './default';
import {
  rootSagaForEditingSubmissions,
  rootSagaForShowingSubmissions,
} from './editSubmission/rootSagas';
import { previewSaga } from './previewSaga';
import type { ModeWithEndpoint } from './types';
import { MODES_WITH_ENDPOINTS } from './types';

export const getRootSaga: (ModeWithEndpoint, BackendInterface) => Saga<void> = (
  mode,
  api,
) => {
  const { formId, invitationId, viewSubmissionId } = getSettings();
  if (mode === 'showSubmission') {
    if (viewSubmissionId == null) {
      throw new Error(
        'When the mode is `showSubmission`, the submission ID should be set',
      );
    }
    return rootSagaForShowingSubmissions(api, {
      formId,
      submissionId: viewSubmissionId,
    });
  } else if (mode === 'editSubmission') {
    if (viewSubmissionId == null) {
      throw new Error(
        'When the mode is `editSubmission`, the submission ID should be set',
      );
    }
    return rootSagaForEditingSubmissions(api, {
      formId,
      submissionId: viewSubmissionId,
    });
  } else if (mode === 'preview') {
    return previewSaga(api, {
      formId,
    });
  } else {
    return defaultSaga(api, {
      formId,
      invitationId,
      submissionId: viewSubmissionId,
      mode,
    });
  }
};

export const getMode: () => ModeWithEndpoint = () => {
  const { mode } = getSettings();
  if (!mode) {
    throw new Error('Mode not specified');
  }
  if (!MODES_WITH_ENDPOINTS.includes(mode)) {
    throw new Error(
      `${mode} is not a supported mode. Expected one of ${JSON.stringify(
        MODES_WITH_ENDPOINTS,
      )}`,
    );
  }
  const specifiedMode: ModeWithEndpoint = mode;
  return specifiedMode;
};

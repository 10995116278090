// @flow

import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import * as React from 'react';

import english from '../../../locale/en/messages.js';
import bokmal from '../../../locale/nb/messages.js';
import nynorsk from '../../../locale/nn/messages.js';
import getCurrentLocale from '../../locale';

const catalogs = {
  en: english,
  nb: bokmal,
  nn: nynorsk,
};

export const i18n = setupI18n({ catalogs, language: getCurrentLocale() });

export const NettskjemaI18nProvider = (p: { children: React.Node }) => {
  return <I18nProvider i18n={i18n}>{p.children}</I18nProvider>;
};

export default i18n;

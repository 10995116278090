// @flow

import * as Backend from '../backend/legacyJsonBackend';
import type { TextInput } from '../inputs/types';
import validNorwegian from './norwegianIdNumber';
import ValidationSequence from './ValidationSequence';

function countInString(string, regex) {
  let result = 0;
  for (const char of string) {
    if (regex.test(char)) {
      result++;
    }
  }
  return result;
}

const letter = /^[a-zA-Z]|[ÆæØøÅå]/;
const digit = /^\d/;
const illegalChar = {
  test: char => !(letter.test(char) || digit.test(char)),
};
const numberOfLetters = value => countInString(value, letter);
const numberOfDigits = value => countInString(value, digit);
const containsIllegalChar = value => countInString(value, illegalChar) > 0;

export const validateCustom = (
  input: string,
  shouldHaveLetters: ?number,
  shouldHaveDigits: ?number,
) =>
  ValidationSequence()
    .when(
      shouldHaveLetters != null && numberOfLetters(input) !== shouldHaveLetters,
      {
        includeError: [
          'wrong_letter_count',
          { expected: (shouldHaveLetters: $FlowIgnore) },
        ],
      },
    )
    .when(
      shouldHaveDigits != null && numberOfDigits(input) !== shouldHaveDigits,
      {
        includeError: [
          'wrong_digit_count',
          { expected: (shouldHaveDigits: $FlowIgnore) },
        ],
      },
    )
    .when(containsIllegalChar(input), {
      includeError: 'contains_illegal_char',
    });

export const validateNumbersOnly = (input: string) =>
  ValidationSequence().when(numberOfDigits(input) !== input.length, {
    includeError: 'invalid_national_id_only_digits',
  });

export const validateNorwegianIDNumber = (input: string) =>
  ValidationSequence().when(!validNorwegian(input), {
    includeError: 'invalid_national_id_number',
  });

const validatePersonId = (value: TextInput, element: Backend.Element) =>
  ValidationSequence()
    .when(
      element.repeatedNationalIdRequired && value.given !== value.repeated,
      {
        includeError: 'repeat_not_identical',
      },
    )
    .when(element.nationalIdNumberType === 'ONLY_NUMBERS', {
      alsoPerform: () => validateNumbersOnly(value.given),
    })
    .when(
      element.nationalIdNumberType === 'NORWEGIAN_ID_NUMBER' &&
        !validNorwegian(value.given),
      {
        includeError: 'invalid_national_id_number',
      },
    )
    .when(element.nationalIdNumberType === 'CUSTOM', {
      alsoPerform: () =>
        validateCustom(
          value.given,
          element.nationalIdNumberOfLetters,
          element.nationalIdNumberOfDigits,
        ),
    })
    .when(
      !['ONLY_NUMBERS', 'NORWEGIAN_ID_NUMBER', 'CUSTOM'].includes(
        element.nationalIdNumberType,
      ),
      {
        doThrow: new Error('Validate Person ID type not provided'),
      },
    );

export default validatePersonId;

// @flow

import { scroller } from 'react-scroll';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import getSettings from '../backend/settings';
import { makeFocusClassName } from '../design/focusFrame';
import { pageHasVisibleElements } from '../form/hiddenElements';
import { formUIId } from '../modes/formUIId';
import { questionIdLabel } from '../question-elements/labels';
import type { AnswerState } from '../reducer';
import { history } from './history';

export function* questionMatcher(path: string): Saga<void> {
  const matcher = /^\/question\/(\d+)$/;
  const match = matcher.exec(path);
  if (match != null && match.length === 2) {
    yield call(scrollToQuestion, Number(match[1]));
  }
}

export function* pageMatcher(path: string): Saga<boolean> {
  const matcher = /^\/page\/(\d+)(.*)/;
  const match = matcher.exec(path);
  if (match != null && match.length > 1) {
    const computerReadablePageNumber = Number(match[1]) - 1;
    const restPath = match[2];
    yield call(
      goToPage,
      computerReadablePageNumber,
      'noHistory',
      restPath == null || restPath == '' ? 'top' : 'noScroll',
      true,
    );
    yield call(questionMatcher, restPath);
    return true;
  }
  return false;
}

export function* scrollToQuestion(questionId: number): Saga<void> {
  const labelId = questionIdLabel(questionId);
  const focusClass = makeFocusClassName(questionId);
  yield call([scroller, scroller.scrollTo], labelId, { smooth: false });
  const focusElement = document.querySelector(`[class*=${focusClass}]`);
  focusElement && focusElement.focus();
}

function* checkIfPageExists(index: number): Saga<boolean> {
  const answerState: AnswerState = yield select(state => state.answer);
  const { inputs } = answerState;
  if (answerState.form == null) {
    throw new Error('Form should be loaded at this point');
  }
  const { pages } = answerState.form;
  const requestedPage = pages[index];
  return (
    (requestedPage != null &&
      pageHasVisibleElements(requestedPage, inputs, pages)) ||
    index === 0
  );
}

const scrollToTop = () => window.scroll(0, 0);

export function* goToPage(
  index: number,
  historyAction: 'push' | 'replace' | 'noHistory' = 'push',
  scroll: 'top' | 'bottom' | 'noScroll' = 'top',
  firstLoad?: boolean,
): Saga<void> {
  const pageExists = yield call(checkIfPageExists, index);
  const mode = getSettings().mode;
  if (!pageExists) {
    yield put({ type: 'NON_EXISTING_PAGE_REQUESTED' });
    return;
  }
  const humanReadableIndex = index + 1;
  yield put({ type: 'GO_TO_PAGE', payload: { index } });
  if (historyAction !== 'noHistory') {
    yield call(
      [history, history[historyAction]],
      `/page/${humanReadableIndex}`,
    );
  }
  if (mode === 'iframeEmbedded' && firstLoad !== true) {
    yield call(focusFormContainer);
    window.parent.postMessage('scrollToStart', '*');
  } else if (mode !== 'iframeEmbedded') {
    yield call(resetFocus);
    yield call(focusFormContainer);
    if (scroll === 'top') {
      yield call(() => window.setTimeout(scrollToTop, 100));
    }
    if (scroll === 'bottom') {
      yield call(window.scroll, {
        left: 0,
        bottom: 0,
      });
    }
  }
}

function resetFocus() {
  if (document.activeElement != null) {
    document.activeElement.blur();
  }
}
function focusFormContainer() {
  const formUI = document.querySelector(`#${formUIId}`);
  if (formUI != null) {
    formUI.focus();
  }
}

export const forTesting = {
  checkIfPageExists,
  resetFocus,
  focusFormContainer,
};

import {
  ApiUserOwner,
  FieldError,
  FormFieldsKeys,
  FormMetaInformation,
  FormSettings,
  FormSettingsKeys,
} from '../types';

export type FormAction =
  | CancelEditActionType
  | FetchFormSettingsActionType
  | FetchFormSettingsSuccessActionType
  | FetchFormSettingsFailedActionType
  | AddSetting
  | RemovePermissionsActionType
  | SaveFormPermissionsSettingsActionType
  | SaveFormSettingsActionType
  | SaveSettingsSuccessActionType
  | SaveSettingsValidationFailedActionType
  | SaveSettingsValidationNestedErrors
  | SaveSettingsUnknownError
  | UpdateFormField
  | UpdateTitleShort
  | EditorsThroupApiUsers
  | OpenForm
  | CloseForm
  | ToggleEditTitle
  | CopyFormFailed
  | DeleteForm
  | DeleteFormFailed
  | ChangeCodebookValue
  | AutofillCodebook
  | SaveCodebook
  | UpdateFormId
  | UpdateFormFromGlobalObject
  | ShowSuccessMessage
  | SetDirty;

export type CancelEditActionType = {
  type: 'CANCEL_EDIT';
  data: { settingsToDiscard: FormSettingsKeys[]; shouldClearField: boolean };
};

export const cancelEdit = (
  settingsToDiscard: FormSettingsKeys[],
  shouldClearField: boolean,
): CancelEditActionType => ({
  type: 'CANCEL_EDIT',
  data: {
    settingsToDiscard: settingsToDiscard,
    shouldClearField: shouldClearField,
  },
});

export type FetchFormSettingsActionType = {
  type: 'FETCH_FORM_SETTINGS';
  data: { showLoadingIcon: boolean };
};

export const fetchFormSettings = (
  showLoadingIcon: boolean,
): FetchFormSettingsActionType => ({
  type: 'FETCH_FORM_SETTINGS',
  data: { showLoadingIcon: showLoadingIcon },
});

export type FetchFormSettingsSuccessActionType = {
  type: 'FETCH_FORM_SETTINGS_SUCCESSFUL';
  data: { form: FormSettings; metaInformation: FormMetaInformation };
};

export const fetchFormSettingsSuccess = (
  form: FormSettings,
  metaInformation: FormMetaInformation,
): FetchFormSettingsSuccessActionType => {
  return {
    type: 'FETCH_FORM_SETTINGS_SUCCESSFUL',
    data: { form: form, metaInformation: metaInformation },
  };
};

export type FetchFormSettingsFailedActionType = {
  type: 'FETCH_FORM_SETTINGS_FAILED';
  data: { error: string };
};

export const fetchFormSettingsFailed = (
  error: string,
): FetchFormSettingsFailedActionType => ({
  type: 'FETCH_FORM_SETTINGS_FAILED',
  data: { error: error },
});

export type AddSetting = {
  type: 'ADD_SETTING';
  data: {
    setting: FormSettingsKeys;
  };
};

export const addSetting = (setting: FormSettingsKeys): AddSetting => ({
  type: 'ADD_SETTING',
  data: {
    setting: setting,
  },
});

export type RemovePermissionsActionType = {
  type: 'REMOVE_PERMISSIONS';
  data: { setting: FormSettingsKeys; name: string };
};

export const removePermission = (
  setting: FormSettingsKeys,
  name?: string,
): RemovePermissionsActionType => ({
  type: 'REMOVE_PERMISSIONS',
  data: {
    setting: setting,
    name: name ? name : '',
  },
});

export type SaveFormSettingsActionType = {
  type: 'SAVE_FORM_SETTINGS';
  data: { fields: FormSettingsKeys[] };
};

export const saveFormSettings = (
  fields: FormSettingsKeys[],
): SaveFormSettingsActionType => ({
  type: 'SAVE_FORM_SETTINGS',
  data: { fields: fields },
});

export type SaveFormPermissionsSettingsActionType = {
  type: 'SAVE_FORM_PERMISSIONS_SETTINGS';
  data: { fields: FormSettingsKeys[] };
};

export const saveFormPermissionsSettings = (
  fields: FormSettingsKeys[],
): SaveFormPermissionsSettingsActionType => ({
  type: 'SAVE_FORM_PERMISSIONS_SETTINGS',
  data: { fields: fields },
});

export type SaveSettingsSuccessActionType = {
  type: 'SAVE_SETTINGS_SUCCESSFUL';
};

export const saveSettingsSuccess = (): SaveSettingsSuccessActionType => ({
  type: 'SAVE_SETTINGS_SUCCESSFUL',
});

export type SaveSettingsValidationFailedActionType = {
  type: 'SAVE_SETTINGS_VALIDATION_FAILED';
  data: { errors: FieldError[] };
};

export const saveSettingsValidationFailed = (
  errors: FieldError[],
): SaveSettingsValidationFailedActionType => ({
  type: 'SAVE_SETTINGS_VALIDATION_FAILED',
  data: { errors: errors },
});

export type SaveSettingsValidationNestedErrors = {
  type: 'SAVE_SETTINGS_VALIDATION_NESTED_ERRORS';
  data: { nestedErrors: object };
};

export const saveSettingsValidationNestedErrors = (
  nestedErrors: object,
): SaveSettingsValidationNestedErrors => ({
  type: 'SAVE_SETTINGS_VALIDATION_NESTED_ERRORS',
  data: { nestedErrors: nestedErrors },
});

export type SaveSettingsUnknownError = {
  type: 'SAVE_SETTINGS_UNKNOWN_ERROR';
};

export const saveSettingsUnknownError = (): SaveSettingsUnknownError => ({
  type: 'SAVE_SETTINGS_UNKNOWN_ERROR',
});

export type UpdateFormField = {
  type: 'UPDATE_FORM_FIELD';
  data: { formField: FormFieldsKeys; value: string };
};

export const updateFormField = (
  formField: FormFieldsKeys,
  value: string,
): UpdateFormField => ({
  type: 'UPDATE_FORM_FIELD',
  data: { formField: formField, value: value },
});

export type UpdateTitleShort = {
  type: 'UPDATE_TITLE_SHORT';
  data: { value: string };
};

export const updateTitleShort = (value: string): UpdateTitleShort => ({
  type: 'UPDATE_TITLE_SHORT',
  data: { value },
});

export type EditorsThroupApiUsers = {
  type: 'EDITORS_THROUGH_API_USERS';
  data: { editors: ApiUserOwner[] };
};

export const editorsThroughApiUsers = (
  editors: ApiUserOwner[],
): EditorsThroupApiUsers => ({
  type: 'EDITORS_THROUGH_API_USERS',
  data: { editors: editors },
});

export type OpenForm = {
  type: 'OPEN_FORM';
};

export const openForm = (): OpenForm => ({
  type: 'OPEN_FORM',
});

export type CloseForm = {
  type: 'CLOSE_FORM';
};

export const closeForm = (): CloseForm => ({
  type: 'CLOSE_FORM',
});

export type ToggleEditTitle = {
  type: 'TOGGLE_EDIT_TITLE';
};

export const toggleEditTitle = (): ToggleEditTitle => ({
  type: 'TOGGLE_EDIT_TITLE',
});

export type CopyFormWithoutDialog = {
  type: 'COPY_FORM_WITHOUT_DIALOG';
  payload: { formId: number };
};

export const copyFormWithoutDialog = (
  formId: number,
): CopyFormWithoutDialog => ({
  type: 'COPY_FORM_WITHOUT_DIALOG',
  payload: { formId: formId },
});

export type CopyFormFailed = {
  type: 'COPY_FORM_FAILED';
};

export type DeleteForm = {
  type: 'DELETE_FORM';
  data: { formId: number };
};

export const deleteForm = (formId: number): DeleteForm => ({
  type: 'DELETE_FORM',
  data: { formId: formId },
});

export type DeleteFormFailed = {
  type: 'DELETE_FORM_FAILED';
};

export const deleteFormFailed = (): DeleteFormFailed => ({
  type: 'DELETE_FORM_FAILED',
});

export type NavigateToPage = {
  type: 'NAVIGATE_TO_PAGE';
  data: { page: string };
};

export const navigateToPage = (page: string): NavigateToPage => ({
  type: 'NAVIGATE_TO_PAGE',
  data: { page: page },
});
export type ChangeCodebookValue = {
  type: 'CHANGE_CODEBOOK_VALUE';
  value: string;
  fieldInfo: {
    elementSequence: string;
    optionSequence?: string;
    questionSequence?: string;
  };
};

export const changeCodebookValue = (
  value: string,
  elementSequence: string,
  optionSequence?: string,
  questionSequence?: string,
): ChangeCodebookValue => ({
  type: 'CHANGE_CODEBOOK_VALUE',
  value: value,
  fieldInfo: { elementSequence, optionSequence, questionSequence },
});

export type AutofillCodebook = {
  type: 'AUTOFILL_CODEBOOK';
};

export const autofillCodebook = (): AutofillCodebook => ({
  type: 'AUTOFILL_CODEBOOK',
});

export type SaveCodebook = {
  type: 'SAVE_CODEBOOK';
};

export const saveCodebook = (): SaveCodebook => ({
  type: 'SAVE_CODEBOOK',
});

export type UpdateFormId = {
  type: 'UPDATE_FORM_ID';
  data: { formId: number };
};

export const updateFormId = (formId: number): UpdateFormId => ({
  type: 'UPDATE_FORM_ID',
  data: { formId: formId },
});

export type UpdateFormFromGlobalObject = {
  type: 'UPDATE_FORM_FROM_GLOBAL_OBJECT';
  data: { metaInformation: FormMetaInformation; formSettings: FormSettings };
};

export const updateFormFromGlobalObject = (
  metaInformation: FormMetaInformation,
  formSettings: FormSettings,
): UpdateFormFromGlobalObject => ({
  type: 'UPDATE_FORM_FROM_GLOBAL_OBJECT',
  data: { metaInformation: metaInformation, formSettings: formSettings },
});

export type ShowSuccessMessage = {
  type: 'SHOW_SUCCESS_MESSAGE';
};

export const showSuccessMessage = (): ShowSuccessMessage => ({
  type: 'SHOW_SUCCESS_MESSAGE',
});

export type SetDirty = {
  type: 'SET_DIRTY';
};

export const setDirty = () => ({ type: 'SET_DIRTY' });

// @flow
import ValidationSequence from './ValidationSequence';

// const validPhoneNumber = /^((\+47)?(4|9)[0-9]{7})$|^(\+(?!47)[0-9]{9,19})$/;
const validPhoneNumber = /^((\+47)?([1-9])[0-9]{7})$|^(\+(?!47)[0-9]{9,19})$/;

const wellFormedPhoneNumber = value => validPhoneNumber.test(value);

const validatePhone = (input: string) =>
  ValidationSequence().when(!wellFormedPhoneNumber(input), {
    includeError: 'invalid_phone',
  });

export default validatePhone;

// @flow

import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Heading, Row } from 'react-usit-ui';

const ErrorContainer = (p: { error: { heading: string, message: string } }) => (
  <Row>
    <Heading>{ReactHtmlParser(p.error.heading)}</Heading>
    {ReactHtmlParser(p.error.message)}
  </Row>
);

export default ErrorContainer;

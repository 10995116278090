// @flow

import type { Saga } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  addNoScrollToBodyGenerator,
  removeNoScrollFromBodyGenerator,
} from '../../../../components/preventScrolling';
import type { BackendInterface } from '../../backend/interface';
import { getApi } from '../../backend/interface';
import { absoluteUrl } from '../../backend/resources';
import {
  genericGetFormInformation,
  handleAuthenticationCheckResponse,
  handlePingResponse,
  reauthenticate,
  submitRequested,
} from '../../backend/sagas';
import getSettings from '../../backend/settings';
import * as EscapeHatch from '../../LegacyEscapeHatch';
import { redirectToUrl } from '../../routing/redirect';
import { listener as routingListenerSaga } from '../../routing/sagas';
import {
  listenForCountActions,
  listenForFormActions,
  listenForReauthenticateActions,
} from '../default';

function* getSubmission(api: BackendInterface, args: { submissionId: number }) {
  const response = yield call(api.fetchSubmission, args.submissionId);
  const json = yield call([response, response.json]);
  yield put({
    type: 'GOT_SUBMISSION_INFORMATION',
    payload: { answers: json.answers, delivered: json.delivered },
  });
}

function* deleteRequested(): Saga<void> {
  const submissionId = getSettings().viewSubmissionId;
  if (submissionId == null) {
    throw new Error(`Expected submission ID to be set`);
  }
  const api = getApi();
  yield call(handlePingResponse, api);
  yield call(handleAuthenticationCheckResponse, api);
  const authenticated = yield select(state => state.answer.authenticated);
  if (!authenticated) {
    yield call(reauthenticate);
    return;
  }

  // Use the legacy endpoint instead of the new API to delete, so we get the same email logic as in the old frontend.
  yield call(gotoDeletePage, submissionId);
}

function* gotoDeletePage(submissionId: number) {
  yield call(
    redirectToUrl,
    absoluteUrl(`/user/submission/delete.html?id=${submissionId}`),
  );
}

function* listenForSubmissionActions(): Saga<void> {
  yield takeEvery('DELETE_SUBMISSION_REQUESTED', deleteRequested);
}

function* getFormInformationForEditing(
  api: BackendInterface,
  submissionId: number,
) {
  yield call(genericGetFormInformation, api.fetchFormForEditing, submissionId);
}

function* getFormInformationForViewing(
  api: BackendInterface,
  submissionId: number,
) {
  yield call(genericGetFormInformation, api.fetchFormForViewing, submissionId);
}

function* fetchFormAndSubmission(
  api: BackendInterface,
  args: { submissionId: number, formId: number },
  mode: Function,
): Saga<void> {
  yield call(mode, api, args.submissionId);
  yield call(getSubmission, api, {
    submissionId: args.submissionId,
  });
}
export function* rootSagaForShowingSubmissions(
  api: BackendInterface,
  args: { submissionId: number, formId: number },
): Saga<void> {
  yield fork(listenForCountActions);
  yield call(fetchFormAndSubmission, api, args, getFormInformationForViewing);
  yield takeEvery('SUBMIT_FORM_REQUESTED', submitRequested, api);
  yield takeEvery('OLD_DESIGN_REQUESTED', EscapeHatch.onOldDesignRequested);
  yield takeEvery('SHOW_MODAL', addNoScrollToBodyGenerator);
  yield takeEvery('HIDE_MODAL', removeNoScrollFromBodyGenerator);
  yield fork(listenForSubmissionActions);
}

export function* rootSagaForEditingSubmissions(
  api: BackendInterface,
  args: { formId: number, submissionId: number },
): Saga<void> {
  yield fork(routingListenerSaga);
  yield fork(listenForCountActions);
  yield fork(listenForReauthenticateActions, api);
  yield call(fetchFormAndSubmission, api, args, getFormInformationForEditing);
  yield fork(listenForFormActions, api);
  yield fork(listenForSubmissionActions);
}

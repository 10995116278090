// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';
// $FlowIgnore
import { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useDispatch } from 'react-redux';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import * as Backend from '../../backend/legacyJsonBackend';
import getSettings from '../../backend/settings';
import * as colors from '../../design/colors';
import { makeFocusClassName } from '../../design/focusFrame';
import { create, structure } from '../../inputs/types';
import type {
  FormInput,
  MultipleOptionsInput,
  SingleOptionInput,
  UnprovidedInput,
} from '../../inputs/types';
import { invalid } from '../../submission-validation/types';
import { invalidInput } from '../../submission-validation/validateInputs';
import { ValidationErrors } from '../../submission-validation/ValidationErrorUI';
import { stripHtml } from '../../util';
import * as descriptives from '../descriptives';
import { QuestionHeading, questionIdLabel } from '../labels';
import { mandatoryHeadingStyle } from '../mandatoryMark';
import * as ScreenReaderDescription from '../ScreenReaderDescription';
import {
  InputIconWithBackground,
  OptionElementContainer,
  OptionInputElement,
} from './multipleOptions';
import { getOnKeyDown, radioButtonCanBeUnchecked } from './RadioButtons';

const style = {
  mandatory: mandatoryHeadingStyle,
  matrix: {
    width: 'auto',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    alignItems: 'stretch',
  },
  rowHiglight: {
    borderRadius: 8,
    backgroundColor: colors.white,
    '&.active-row': {
      backgroundColor: '#f6f6f6',
      fontWeight: 'bold',
    },
  },
  errorMatrix: {
    width: '100%',
    borderTop: 'none',
    left: 0,
    position: 'sticky',
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexShrink: 0,
    marginRight: 15,
    minHeight: 44,
    width: 80,
    alignItems: 'center',
  },
  errorCell: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 5,
  },
  headingContainer: {
    alignItems: 'flex-end',
  },
  heading: {
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 'inherit',
    hyphens: 'auto',
    '-ms-hyphens': 'auto',
    overflowWrap: 'anywhere',
    boxSizing: 'content-box',
    '&.active-heading': {
      width: 85,
      fontWeight: 'bold',
      '& div': {
        backgroundColor: colors.primary.dark,
      },
    },
  },
  focusPin: {
    height: 3,
    borderRadius: 1.5,
    backgroundColor: 'white',
    width: 'inherit',
    marginTop: '11px',
  },
  rowHeading: {
    color: colors.ns.black,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
    padding: [12, 0, 12, 10],
    marginRight: 10,
    width: 225,
    boxSizing: 'content-box',
    hyphens: 'auto',
    '-ms-hyphens': 'auto',
    overflowWrap: 'anywhere',
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
  },
  emptyCell: {
    backgroundColor: 'white !important',
    minHeight: 0,
    paddingTop: 0,
  },
  rowDescription: {
    lineHeight: '18px',
    flex: 1,
    fontSize: 14,
    backgroundColor: 'inherit',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center ',
  },
  bottomBorder: { borderBottom: `1px solid ${colors.ns.greyLight}` },
  matrixHeader: {
    position: 'sticky',
    top: 0,
    overflowX: 'auto',
    backgroundColor: 'white',
    zIndex: 3,
  },
  matrixBody: {
    overflowX: 'auto',
    paddingBottom: 10,
  },
  flexRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100vw',
  },
  headerRowWrapper: {
    marginTop: 10,
    marginBottom: 1,
  },
  smallColumnCells: {
    width: 56,
    boxSizing: 'content-box',
  },
  smallColumnHeading: {
    '&.active-heading': {
      width: 59,
    },
  },
  smallRowHeading: {
    width: 185,
  },
};

type Classes = { [$Keys<typeof style>]: string };

const useMountEffect = fun => useEffect(fun, []);

const TableHeading = injectSheet(style)(
  ({ children, classes, smallColumns, id }) => (
    <div
      className={classNames([
        classes.cell,
        smallColumns && classes.smallColumnCells,
        classes.headingContainer,
      ])}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'inherit',
          alignItems: 'center',
        }}
      >
        <div
          id={id}
          className={classNames([
            classes.heading,
            smallColumns && classes.smallColumnHeading,
          ])}
        >
          {children}
          <div className={classes.focusPin} />
        </div>
      </div>
    </div>
  ),
);

const TableRow = injectSheet(style)(
  ({
    children,
    classes,
    mandatory,
    rowRole,
    header,
    questionId,
    highlightRef,
  }) => {
    const activeRowHandler = () => {
      const activeRow = highlightRef.current.activeRow;
      if (activeRow && activeRow !== questionId)
        unsetRowActive(highlightRef.current.activeRow);
      setRowActive(questionId);
      highlightRef.current.activeRow = questionId;
    };
    const inactiveRowHandler = () => {
      unsetRowActive(questionId);
      if (highlightRef.current.activeRow === questionId)
        highlightRef.current.activeRow = null;
    };
    return (
      <div
        className={classNames([
          classes.flexRowWrapper,
          header && classes.headerRowWrapper,
        ])}
        role="presentation"
        onFocus={questionId && activeRowHandler}
        onBlur={questionId && inactiveRowHandler}
        onMouseOver={questionId && activeRowHandler}
        onMouseLeave={questionId && inactiveRowHandler}
      >
        <div
          className={classNames(classes.row, !header && classes.rowHiglight)}
          id={questionId}
          role={rowRole}
          aria-required={
            rowRole === 'radiogroup' ? mandatory.toString() : undefined
          }
        >
          {children}
        </div>
      </div>
    );
  },
);

const useResizeHandler = fun => {
  useEffect(() => {
    const debouncedFun = debounce(fun, 250);
    debouncedFun();
    window.addEventListener('resize', debouncedFun);
    return () => window.removeEventListener('resize', debouncedFun);
  }, [fun]);
};

const TableErrorRow = injectSheet(style)(({ children, classes }) => (
  <div className={classNames([classes.row, classes.errorMatrix])}>
    {children}
  </div>
));

const TableCell = injectSheet(style)(
  ({ children, classes, colSpan, noWrap, id, smallColumns }) => (
    <div
      id={id}
      className={classNames([
        classes.cell,
        noWrap && classes.noWrap,
        smallColumns && classes.smallColumnCells,
      ])}
      colSpan={colSpan}
    >
      {children}
    </div>
  ),
);

const RowHeading = injectSheet(style)(
  ({ children, classes, header, id, mandatory, rowRole, smallColumns }) => {
    const ref = useRef();
    useMountEffect(() => {
      if (ref.current && !header) {
        ref.current.style.minHeight = `${ref.current.firstChild.offsetHeight +
          26}px`;
      }
    });
    return (
      <div
        className={classNames([
          classes.cell,
          classes.rowHeading,
          header && classes.emptyCell,
          smallColumns && classes.smallRowHeading,
        ])}
        aria-describedby={
          rowRole === 'radiogroup' || !mandatory
            ? undefined
            : ScreenReaderDescription.descriptionOf(id)
        }
      >
        {rowRole !== 'radiogroup' && mandatory && (
          <ScreenReaderDescription.InvisibleComponent forElementId={id}>
            <Trans>Du må velge minst ett svaralternativ i denne raden.</Trans>
          </ScreenReaderDescription.InvisibleComponent>
        )}
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            ref={ref}
            className={classNames([classes.rowDescription])}
            id={id}
          >
            <span className={classNames(mandatory && classes.mandatory)}>
              {ReactHtmlParser(children)}
            </span>
          </div>
        </div>
      </div>
    );
  },
);

const TableErrorCell = injectSheet(style)(({ children, classes }) => (
  <div className={classNames([classes.cell, classes.errorCell])} data-ns-error>
    {children}
  </div>
));

const MatrixWrapper = injectSheet(style)(({ children, classes, id }) => (
  <div className={classes.matrix} id={id}>
    {children}
  </div>
));

export const inputMatchingQuestion = (
  inputs: FormInput[],
  question: Backend.Question,
) => inputs.find(input => question.questionId === input.questionId);

export const validationErrorsForQuestion = (
  question: Backend.Question,
  inputs: FormInput[],
) =>
  inputs
    .filter(input => input.questionId === question.questionId)
    .map(input => input.validation)
    .filter(validation => invalid(validation))
    // The previous filter means validation.error exists
    .map(validation => (validation: $FlowIgnore).errors)
    .reduce(flatten, []);

export const makeSelectElement = (
  questionId: number,
  onInputValue: (
    questionId: number,
    value: SingleOptionInput | UnprovidedInput,
  ) => void,
) => (optionId: number) => {
  const answerOption = create.singleOption(optionId);
  onInputValue(questionId, answerOption);
};

export const createIsIdOfFirstOption = (
  inputs: FormInput[],
  answerOptions: Backend.AnswerOption[],
  question: Backend.Question,
) => (id: string) => {
  const input = inputMatchingQuestion(inputs, question);
  const answerId = id.split('-')[3];
  return (
    (input == null || input.value.type === 'NOT_PROVIDED') &&
    answerId === answerOptions[0].answerOptionId.toString()
  );
};

const rowWidthCalculator = (smallColumns, numColumns) => {
  const cellWidth = smallColumns ? 71 : 95;
  const emptyCellWidth = smallColumns ? 205 : 245;

  return cellWidth * numColumns + emptyCellWidth;
};

const resizeHandler = (
  smallColumns: boolean,
  setSmallColumns: boolean => void,
  widthChecked: boolean,
  setWidthChecked: boolean => void,
  rowWidth: number,
  setRowWidth: number => void,
  dispatch: Object => void,
  wideRowWidth: number,
  smallerRowWidth: number,
  id: string,
) => {
  const appContainer = document.getElementById('answer-app-container');
  const formPage = document.getElementById('form-ui');
  const wrapper = document.getElementById(id);
  if (wrapper && appContainer && formPage) {
    const formWidth = formPage.offsetWidth;
    const bodyWidth = appContainer.offsetWidth;
    const maxMatrixWidth = (bodyWidth - formWidth) / 2 + formWidth;
    if (!smallColumns && maxMatrixWidth < wideRowWidth) {
      setSmallColumns(true);
      setRowWidth(smallerRowWidth);
    } else if (smallColumns && maxMatrixWidth > wideRowWidth) {
      setSmallColumns(false);
      setRowWidth(wideRowWidth);
    }
    if (maxMatrixWidth > rowWidth) {
      wrapper.style.width = `${rowWidth}px`;
    } else {
      wrapper.style.width = '';
    }
    if (!widthChecked) {
      const mode = getSettings().mode;
      const data = maxMatrixWidth < smallerRowWidth ? 'scroll' : 'noscroll';
      setWidthChecked(true);
      dispatch({
        type: 'COUNT',
        data: { [`answer.matrix.${mode}.${data}`]: 1 },
      });
    }
  }
};

const _Matrix = (p: {
  id: string,
  title?: string,
  description?: string,
  answerOptions: Backend.AnswerOption[],
  inputs: FormInput[],
  questions: Backend.Question[],
  rowRole?: string,
  classes: Classes,
  onInputValue?: (
    questionId: number,
    value: SingleOptionInput | UnprovidedInput,
  ) => void,
  renderInput: ({
    questionId: number,
    title?: string,
    description?: string,
    input: ?FormInput,
    answerOptionId: number,
    row: string,
    column: string,
    mandatory: boolean,
    onKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
    isIdOfFirstOption?: (id: string) => boolean,
  }) => React.Node,
}) => {
  const numColumns = p.answerOptions.length;
  const wideRowWidth = rowWidthCalculator(false, numColumns);
  const smallerRowWidth = rowWidthCalculator(true, numColumns);
  const [smallColumns, setSmallColumns] = useState(false);
  const [widthChecked, setWidthChecked] = useState(false);
  const [rowWidth, setRowWidth] = useState(
    smallColumns ? smallerRowWidth : wideRowWidth,
  );
  const ref = useRef();
  const dispatch = useDispatch();
  const highlightRef = useRef({ activeCol: null, activeRow: null });
  const getResizeHandler = widthChecked => () =>
    resizeHandler(
      smallColumns,
      setSmallColumns,
      widthChecked,
      setWidthChecked,
      rowWidth,
      setRowWidth,
      dispatch,
      wideRowWidth,
      smallerRowWidth,
      p.id,
    );

  useMountEffect(getResizeHandler(widthChecked));
  useResizeHandler(getResizeHandler(true));
  return (
    <div>
      {p.title && <QuestionHeading text={p.title} />}
      {p.description && <descriptives.DescriptionField text={p.description} />}
      <ScrollSync>
        <MatrixWrapper id={p.id}>
          <ScrollSyncPane>
            <div
              className={classNames([p.classes.matrixHeader])}
              ref={ref}
              tabIndex={-1}
            >
              <MatrixHeaderRow
                answerOptions={p.answerOptions}
                smallColumns={smallColumns}
              />
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div
              className={classNames([
                p.classes.matrixBody,
                p.classes.bottomBorder,
              ])}
              tabIndex={-1}
            >
              {p.questions.map(question => (
                <MatrixQuestionRow
                  highlightRef={highlightRef}
                  smallColumns={smallColumns}
                  key={question.questionId}
                  question={question}
                  rowRole={p.rowRole}
                  inputs={p.inputs}
                  answerOptions={p.answerOptions}
                  onInputValue={p.onInputValue}
                  renderInput={p.renderInput}
                />
              ))}
            </div>
          </ScrollSyncPane>
        </MatrixWrapper>
      </ScrollSync>
    </div>
  );
};

export const Matrix = injectSheet(style)(_Matrix);

// $FlowIgnore Flow 0.78 mangler type React.memo
const MatrixHeaderRow = React.memo(
  (p: { answerOptions: Backend.AnswerOption[], smallColumns: boolean }) => (
    <TableRow header>
      <RowHeading smallColumns={p.smallColumns} header>
        &nbsp;
      </RowHeading>
      {p.answerOptions.map(option => (
        <TableHeading
          smallColumns={p.smallColumns}
          key={option.answerOptionId}
          id={option.answerOptionId}
        >
          {ReactHtmlParser(option.text)}
        </TableHeading>
      ))}
    </TableRow>
  ),
);
MatrixHeaderRow.displayName = 'MatrixHeaderRow';

// $FlowIgnore Flow 0.78 mangler type React.memo
const MatrixQuestionRow = React.memo(
  (p: {
    question: Backend.Question,
    answerOptions: Backend.AnswerOption[],
    inputs: FormInput[],
    smallColumns?: boolean,
    onInputValue?: (
      questionId: number,
      value: SingleOptionInput | UnprovidedInput,
    ) => void,
    rowRole?: string,
    highlightRef: Object,
    renderInput: ({
      questionId: number,
      input: ?FormInput,
      answerOptionId: number,
      row: string,
      column: string,
      mandatory: boolean,
      onKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
      isIdOfFirstOption?: (id: string) => boolean,
      highlightRef: Object,
    }) => React.Node,
  }) => {
    const question = p.question;
    const rowId = questionIdLabel(question.questionId);
    const validationErrors = validationErrorsForQuestion(question, p.inputs);
    const hasValidationErrors = validationErrors.length > 0;
    const onKeyDown =
      p.rowRole === 'radiogroup' && p.onInputValue
        ? getOnKeyDown(
            p.answerOptions,
            makeSelectElement(question.questionId, p.onInputValue),
            `${question.questionId}-`,
          )
        : undefined;
    const isIdOfFirstOption = createIsIdOfFirstOption(
      p.inputs,
      p.answerOptions,
      question,
    );
    return (
      <React.Fragment key={question.questionId}>
        <TableRow
          questionId={question.questionId}
          mandatory={question.mandatory}
          rowRole={p.rowRole}
          highlightRef={p.highlightRef}
        >
          <RowHeading
            smallColumns={p.smallColumns}
            id={rowId}
            mandatory={question.mandatory}
            rowRole={p.rowRole}
          >
            {question.text}
          </RowHeading>
          {p.answerOptions.map(option => (
            <TableCell
              smallColumns={p.smallColumns}
              id={`cell-${question.questionId}-${option.answerOptionId}`}
              key={option.answerOptionId}
            >
              {p.renderInput({
                questionId: question.questionId,
                input: inputMatchingQuestion(p.inputs, question),
                answerOptionId: option.answerOptionId,
                row: question.text,
                column: option.text,
                mandatory: question.mandatory,
                onKeyDown,
                isIdOfFirstOption,
                highlightRef: p.highlightRef,
              })}
            </TableCell>
          ))}
        </TableRow>
        {hasValidationErrors && (
          <TableErrorRow>
            <TableErrorCell>
              <ValidationErrors
                errors={validationErrors}
                formElementId={question.questionId.toString()}
                inMatrix
              />
            </TableErrorCell>
          </TableErrorRow>
        )}
      </React.Fragment>
    );
  },
  (prev, next) => {
    let index = null;
    prev.inputs.some((input, idx) => {
      if (input.questionId === prev.question.questionId) {
        index = idx;
        return true;
      }
    });
    return (
      prev.inputs[index] === next.inputs[index] &&
      prev.smallColumns === next.smallColumns
    );
  },
);

MatrixQuestionRow.displayName = 'MatrixQuestionRow';

export const a11yLabel = (row: string, column: string) =>
  `${stripHtml(row)}: ${stripHtml(column)}`;

export const radioMatrixOptionProps = (
  input: ?FormInput,
  answerOptionId: number,
  questionId: number,
  onInputValue: (
    questionId: number,
    value: SingleOptionInput | UnprovidedInput,
  ) => void,
  mandatory: boolean,
  onKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
) => {
  const checked =
    input != null &&
    input.value.type === structure.singleOption.type &&
    input.value.given === answerOptionId;

  return {
    checked,
    id: `question-${questionId}-answer-${answerOptionId}`,
    answerOptionId: answerOptionId,
    questionId: questionId,
    disabled: input != null && input.value.disabled,
    onSelectElement() {
      onInputValue(
        questionId,
        radioButtonCanBeUnchecked({ checked, mandatory })
          ? create.singleOption(null)
          : create.singleOption(answerOptionId),
      );
    },
    type: 'radio',
    onKeyDown,
  };
};

export const RadioMatrix = (p: {
  answerOptions: Backend.AnswerOption[],
  id: string,
  title?: string,
  description?: string,
  inputs: FormInput[],
  questions: Backend.Question[],
  postHeading?: React.Node,
  onInputValue: (
    questionId: number,
    value: SingleOptionInput | UnprovidedInput,
  ) => void,
}) => {
  const { onInputValue, ...matrixProps } = p;
  const renderInput = ({
    questionId,
    input,
    answerOptionId,
    row,
    column,
    mandatory,
    onKeyDown,
    isIdOfFirstOption,
    highlightRef,
  }) => {
    return (
      <MatrixCell
        {...radioMatrixOptionProps(
          input,
          answerOptionId,
          questionId,
          onInputValue,
          mandatory,
          onKeyDown,
        )}
        matrixId={p.id}
        highlightRef={highlightRef}
        row={row}
        column={column}
        invalid={input != null && invalidInput(input)}
        isIdOfFirstOption={isIdOfFirstOption}
        focusClass={makeFocusClassName(questionId)}
        focusElement={answerOptionId === p.answerOptions[0].answerOptionId}
      />
    );
  };
  return (
    <React.Fragment>
      {p.postHeading}
      <Matrix
        rowRole="radiogroup"
        onInputValue={onInputValue}
        renderInput={renderInput}
        {...matrixProps}
      />
    </React.Fragment>
  );
};

export const checkboxMatrixOptionProps = (
  input: ?FormInput,
  answerOptionId: number,
  questionId: number,
  onInputValue: (questionId: number, value: MultipleOptionsInput) => void,
) => {
  const selected =
    input && input.value.type === structure.multipleOptions.type
      ? input.value.given
      : [];
  const checked = selected.includes(answerOptionId);
  return {
    type: 'checkbox',
    id: `question-${questionId}-answer-${answerOptionId}`,
    checked,
    answerOptionId: answerOptionId,
    questionId: questionId,
    disabled: input != null && input.value.disabled,
    onSelectElement() {
      if (!checked) {
        onInputValue(
          questionId,
          create.multipleOptions([...selected, answerOptionId]),
        );
      } else {
        onInputValue(
          questionId,
          create.multipleOptions(
            selected.filter(opt => opt !== answerOptionId),
          ),
        );
      }
    },
  };
};

export const CheckboxMatrix = (p: {
  answerOptions: Backend.AnswerOption[],
  id: string,
  title?: string,
  description?: string,
  inputs: FormInput[],
  questions: Backend.Question[],
  postHeading?: React.Node,
  onInputValue: (questionId: number, value: MultipleOptionsInput) => void,
}) => {
  const { onInputValue, ...matrixProps } = p;
  const renderInput = ({
    questionId,
    input,
    answerOptionId,
    column,
    row,
    highlightRef,
  }) => {
    return (
      <MatrixCell
        {...checkboxMatrixOptionProps(
          input,
          answerOptionId,
          questionId,
          onInputValue,
        )}
        matrixId={p.id}
        highlightRef={highlightRef}
        row={row}
        column={column}
        invalid={input != null && invalidInput(input)}
        focusElement={answerOptionId === p.answerOptions[0].answerOptionId}
        focusClass={makeFocusClassName(questionId)}
      />
    );
  };
  return (
    <React.Fragment>
      {p.postHeading}
      <Matrix renderInput={renderInput} {...matrixProps} />
    </React.Fragment>
  );
};

const setHeadingActive = id => {
  const heading = document.getElementById(id);
  if (heading) {
    const prevHeight = heading.offsetHeight;
    heading.classList.add('active-heading');
    const curHeight = heading.offsetHeight;
    if (curHeight > prevHeight) {
      heading.style.minHeight = `${curHeight}px`;
    }
  }
};

const unsetHeadingActive = id => {
  const heading = document.getElementById(id);
  if (heading) heading.classList.remove('active-heading');
};

const setRowActive = questionId => {
  const row = document.getElementById(questionId.toString());
  if (row) row.classList.add('active-row');
};

const unsetRowActive = questionId => {
  const row = document.getElementById(questionId.toString());
  if (row) row.classList.remove('active-row');
};

const MatrixCell = (p: {
  id: string,
  answerOptionId: number,
  questionId: number,
  matrixId: string,
  checked: boolean,
  disabled: boolean,
  invalid: boolean,
  row: string,
  column: string,
  onSelectElement: () => void,
  onKeyDown?: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  isIdOfFirstOption?: (id: string) => boolean,
  focusElement?: boolean,
  focusClass?: string,
  type: 'radio' | 'checkbox',
  highlightRef: Object,
}) => {
  const cellId = `${p.questionId}-${p.answerOptionId}`;
  const optionId = p.answerOptionId.toString();
  const setHeading = () => {
    const activeCol = p.highlightRef.current.activeCol;
    if (activeCol && activeCol !== optionId) {
      unsetHeadingActive(activeCol);
    }
    setHeadingActive(optionId);
    p.highlightRef.current.activeCol = optionId;
  };
  const unsetHeading = () => {
    unsetHeadingActive(optionId);

    if (p.highlightRef.current.activeCol === optionId)
      p.highlightRef.current.activeCol = null;
  };

  return (
    <OptionElementContainer
      checked={p.checked}
      matrixId={p.matrixId}
      type={p.type}
      onSelect={p.disabled ? () => {} : p.onSelectElement}
      id={cellId}
      onKeyDown={p.disabled ? () => {} : p.onKeyDown}
      disabled={p.disabled}
      focusElement={p.focusElement}
      focusClass={p.focusClass}
      first={p.isIdOfFirstOption && p.isIdOfFirstOption(p.id)}
      inMatrix
      inInvalidQuestion={p.invalid}
      setHeading={setHeading}
      unsetHeading={unsetHeading}
    >
      <OptionInputElement
        id={p.id}
        checked={p.checked}
        onClick={p.onSelectElement}
        ariaLabel={a11yLabel(p.row, p.column)}
        type={p.type}
      />
      <InputIconWithBackground
        type={p.type}
        checked={p.checked}
        inMatrix
        disabled={p.disabled}
        inInvalidQuestion={p.invalid}
      />
    </OptionElementContainer>
  );
};

const flatten = (accumulated, next) => [...accumulated, ...next];

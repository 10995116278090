import { Nettskjema } from '../../nettskjema';
import { Element, FormToCopy } from '../with-dialog/copyFormReducer';

/* Generates the format expected by the backend */
export const formatCopyFormData = (p: { formToCopy: FormToCopy }): Object => {
  const formToCopy = p.formToCopy;

  const getElements = retainAnswerOptionVisibilityFilterForElements(
    formToCopy.elements,
  );

  const basicData = {
    title: getTitle(formToCopy.title),
    formId: formToCopy.formId,
    formType: formToCopy.formType,
    languageCode: formToCopy.languageCode,
    deliveryDestination: 'DATABASE',
    collectsPersonalData: formToCopy.collectsPersonalData,
    sensitivePersonalDataCollected: formToCopy.sensitivePersonalDataCollected,
    personalDataPurposeTypes: formToCopy.personalDataPurposeTypes,
    personalDataPurposeDescription: formToCopy.personalDataPurposeDescription,
    personalDataSharedOutsideInstitutionWith:
      formToCopy.personalDataSharedOutsideInstitutionWith,
    postponable: formToCopy.postponable,
    clientPostponable: false,
    maxSubmissionsForm: formToCopy.maxSubmissionsForm,
    maxSubmissionsPerson: formToCopy.maxSubmissionsPerson,
    respondentGroup: setRespondentGroup(formToCopy.respondentGroup),
    codebookActivated: formToCopy.codebookActivated,
    retainRespondentAccessAfterDelivery:
      formToCopy.retainRespondentAccessAfterDelivery,
    scoreResultDisplayType: formToCopy.scoreResultDisplayType,

    receiptText: formToCopy.receiptText,
    canNotAnswerMessage: formToCopy.canNotAnswerMessage,
    emailInvitationText: formToCopy.emailInvitationText,
    emailReminderText: formToCopy.emailReminderText,

    sendingReceiptToRespondent: formToCopy.sendingReceiptToRespondent,
    shouldPreventDataManipulation: formToCopy.shouldPreventDataManipulation,
    shouldHideProgressBar: formToCopy.shouldHideProgressBar,
    newDesignEnabledSetting: 'ALWAYS',

    includingRefererUrl: formToCopy.includingRefererUrl,
    afterDeliveryForwardUrl: formToCopy.afterDeliveryForwardUrl,
    afterDeliveryForwardCodebookValues:
      formToCopy.afterDeliveryForwardCodebookValues,
    elements: getElements,
  };

  function setRespondentGroup(respondentgroup: string) {
    let notSuperuserRespondentGroups = [
      'ALL',
      'FEIDE',
      'INVITED',
      'ID_PORTEN_LEVEL_3',
    ];

    if (
      Nettskjema.isSuperuser() ||
      notSuperuserRespondentGroups.includes(respondentgroup)
    ) {
      return respondentgroup;
    }
    return 'ALL';
  }

  function getTitle(title: string) {
    let titleShortened = title;
    if (title.length > 120) {
      titleShortened = title.substring(0, 120);
    }
    switch (formToCopy.languageCode) {
      case 'en':
        return titleShortened + ' copy';
      default:
        return titleShortened + ' kopi';
    }
  }

  function retainAnswerOptionVisibilityFilterForElements(elements: Element[]) {
    return (
      elements &&
      elements.map(element => ({
        ...element,
        routingReferenceIds:
          element.answerOptionsVisibilityFilter &&
          element.answerOptionsVisibilityFilter.map(
            (answerOption: any) => answerOption.answerOptionId,
          ),
        questions:
          element.questions &&
          element.questions.map((question: any) => ({
            ...question,
            answerOptions:
              question.answerOptions &&
              question.answerOptions.map((answerOption: any) => ({
                ...answerOption,
                referenceId: answerOption.answerOptionId,
              })),
          })),
      }))
    );
  }

  return { ...basicData };
};

// @flow

export const HORIZONTAL_MIN_WIDTH = 100;
export const HORIZONTAL_MAX_WIDTH = 300;

const widthOfWidestImageIn = container =>
  Math.max(
    ...Array.from(container.getElementsByTagName('img')).map(
      image => image.getBoundingClientRect().width,
    ),
  );

const widthOfWidestDivIn = container =>
  Math.max(
    ...Array.from(container.getElementsByTagName('div')).map(
      div => div.getBoundingClientRect().width,
    ),
  );

const heightOfTallestIn = (nodes: NodeList<HTMLElement>) =>
  Math.max(
    ...Array.from(nodes).map(node => node.getBoundingClientRect().height),
  );

const commonOptionWidth = (args: {
  widthOfWidestOption: number,
  widthOfWidestImage: number,
}) =>
  Math.max(
    Math.min(args.widthOfWidestImage, HORIZONTAL_MAX_WIDTH),
    args.widthOfWidestOption,
  );

function setWidthOfAll(nodes: HTMLCollection<HTMLElement>, newWidth: ?number) {
  for (const option of Array.from(nodes)) {
    option.style.width = newWidth ? `${newWidth.toString()}px` : '';
  }
}

function setHeightOfAll(nodes: NodeList<HTMLElement>, newHeight: ?number) {
  for (const option of Array.from(nodes)) {
    option.style.height = newHeight ? `${newHeight.toString()}px` : '';
  }
}

export function harmonizeWidths(container: HTMLElement, reset?: boolean) {
  if (reset) {
    setWidthOfAll(container.children, null);
  }
  setWidthOfAll(
    container.children,
    commonOptionWidth({
      widthOfWidestOption: widthOfWidestDivIn(container),
      widthOfWidestImage: widthOfWidestImageIn(container),
    }),
  );
}

export function harmonizeHeights(args: {
  horizontal: boolean,
  responsive: boolean,
  containers: NodeList<HTMLElement>,
}) {
  if (args.horizontal) {
    setHeightOfAll(args.containers, heightOfTallestIn(args.containers));
  } else if (args.responsive) {
    // If the view becomes responsive, clear any previously set heights
    setHeightOfAll(args.containers, null);
  }
}

export const forTesting = {
  commonOptionWidth,
  heightOfTallestIn,
  setHeightOfAll,
  setWidthOfAll,
};

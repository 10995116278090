// @flow

import type { Saga } from 'redux-saga';
import { call, fork } from 'redux-saga/effects';

import type { BackendInterface } from '../backend/interface';
import { getPreviewInformation } from '../backend/sagas';
import { saga as embeddingSaga } from './iframeEmbedding/saga';

export function* previewSaga(
  api: BackendInterface,
  args: { formId: number },
): Saga<void> {
  yield fork(embeddingSaga);
  yield call(getPreviewInformation, api, args.formId);
}

import { VisibleDialog } from '../types';

export type UiAction = ToggleDialog | ScrollToTop | CountAction;

export type ToggleDialog = {
  type: 'TOGGLE_DIALOG';
  data: { dialog: VisibleDialog };
};

export const toggleDialog = (dialog: VisibleDialog): ToggleDialog => ({
  type: 'TOGGLE_DIALOG',
  data: { dialog: dialog },
});

export type ScrollToTop = {
  type: 'SCROLL_TO_TOP';
};

export const scrollToTop = (): ScrollToTop => ({
  type: 'SCROLL_TO_TOP',
});

export type CountAction = {
  type: 'COUNT_ACTION';
  data: { action: string };
};

export const countAction = (action: string): CountAction => ({
  type: 'COUNT_ACTION',
  data: { action: action },
});

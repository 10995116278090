//@flow
import * as colors from '../../design/colors/index';
import { focusFrameNoBorder } from '../../design/focusFrame';
import { errorFocusFrameNoBorder } from '../../design/focusFrame';

export const rootStyle = (theme: Object) => ({
  root: {
    ...theme.typography,
    appearance: 'none',
    padding: '6px 30px 6px 11px',
    border: '2px solid #ccc',
    borderRadius: 4,
    color: colors.ns.black,
    margin: 0,
    height: '45px',
    width: '322px',
    fontSize: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:hover': { cursor: 'pointer' },
    '&:focus': focusFrameNoBorder,

    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    '&::-ms-expand': {
      display: 'none',
    },

    '&:-moz-focusring': {
      color: 'transparent',
      textShadow: '0 0 0 #000',
    },
  },
  invalid: {
    border: 'solid 2px ' + colors.warning.dark,
    '&:focus': errorFocusFrameNoBorder,
  },
});

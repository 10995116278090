// @flow

import * as Backend from '../backend/legacyJsonBackend';
import type { FormInput } from '../inputs/types';
import { structure } from '../inputs/types';
import { elementForInput } from './elements';

export const findElementWithIdx = (
  pages: Backend.Page[],
  visibilityParent: { parentElementIdx: number, parentElementPageIdx: number },
) => {
  const { parentElementIdx, parentElementPageIdx } = visibilityParent;
  return pages[parentElementPageIdx].elements[parentElementIdx];
};

const findElementWithAnswerOption = (pages, answerOptionId) => {
  let parentElementIdx = 0;
  let parentElementPageIdx = 0;
  pages.some((page, pageIdx) =>
    page.elements.some((element, elementIdx) =>
      element.questions.some(question => {
        let elementFound = false;
        question.answerOptions.some(option => {
          elementFound = option.answerOptionId === answerOptionId;
          return elementFound;
        });
        if (elementFound) {
          parentElementIdx = elementIdx;
          parentElementPageIdx = pageIdx;
        }
        return elementFound;
      }),
    ),
  );
  return { parentElementIdx, parentElementPageIdx };
};

const findAndInsertVisibilityParent = (
  element: Backend.Element,
  pages: Backend.Page[],
) => {
  const anyVisibilityOptionId = element.visibilityAnswerOptionIds[0];
  const visibilityParentElement = findElementWithAnswerOption(
    pages,
    anyVisibilityOptionId,
  );
  return {
    ...element,
    visibilityParent: visibilityParentElement,
  };
};

export const linkVisibilityOptions = (form: Backend.Form) => {
  const updatedPages = form.pages.map(page => {
    const elements = page.elements.map(element => {
      if (element.visibilityAnswerOptionIds.length !== 0) {
        return findAndInsertVisibilityParent(element, form.pages);
      } else {
        return element;
      }
    });
    return { ...page, elements: elements };
  });
  return { ...form, pages: updatedPages };
};

export const elementShouldBeHidden = (
  element: Backend.Element,
  inputs: FormInput[],
  pages: Backend.Page[],
) => {
  const { visibilityAnswerOptionIds } = element;
  if (visibilityAnswerOptionIds.length === 0) {
    return false;
  } else {
    const visibilityOptionInInputs = inputs.find(
      input =>
        (input.value.type === structure.singleOption.type &&
          singleNumberIsInArray(
            input.value.given,
            visibilityAnswerOptionIds,
          )) ||
        (input.value.type === structure.multipleOptions.type &&
          arraysHaveNumberInCommon(
            input.value.given,
            visibilityAnswerOptionIds,
          )),
    );
    if (visibilityOptionInInputs) {
      const { visibilityParent } = element;
      if (visibilityParent == null) {
        return false;
      }
      const parent = findElementWithIdx(pages, visibilityParent);
      return parent != null && elementShouldBeHidden(parent, inputs, pages);
    }
    return true;
  }
};

export const inputIsForHiddenElement: (
  FormInput,
  Backend.Form,
  FormInput[],
) => boolean = (input, form, allInputs) =>
  elementShouldBeHidden(elementForInput(input, form), allInputs, form.pages);

export function pageHasVisibleElements(
  page: Backend.Page,
  inputs: FormInput[],
  pages: Backend.Page[],
): boolean {
  const visibleElement = page.elements.find(
    element => !elementShouldBeHidden(element, inputs, pages),
  );
  return visibleElement != null;
}

export function indexOfPreviousPageWithVisibleQuestions(
  currentPageIndex: number,
  pages: Backend.Page[],
  inputs: FormInput[],
): number {
  return (
    currentPageIndex -
    pages
      .slice(0, currentPageIndex)
      .map(reverse)
      .findIndex(page => pageHasVisibleElements(page, inputs, pages)) -
    1
  );
}

export function indexOfNextPageWithVisibleQuestions(
  currentPageIndex: number,
  pages: Backend.Page[],
  inputs: FormInput[],
): number {
  return (
    pages
      .slice(currentPageIndex + 1)
      .findIndex(page => pageHasVisibleElements(page, inputs, pages)) +
    currentPageIndex +
    1
  );
}

const singleNumberIsInArray = (value: number, array) => array.includes(value);

const arraysHaveNumberInCommon = (arrayA: number[], arrayB: number[]) =>
  arrayA.find(inA => arrayB.includes(inA));

const reverse = (element, index, array) => array[array.length - 1 - index];

// @flow

import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import * as UI from 'react-usit-ui';

const style = {
  formSubHeading: {
    fontSize: 26,
    fontWeight: 'bold!important;', //TODO: Remove important after update of react-usit-ui.Heading
    lineHeight: 1.04,
    marginBottom: 0,
    marginTop: 25,
  },
  descriptionField: {
    fontSize: '18px',
    marginBottom: 10,
    '& span': {
      display: 'inline-block',
      fontSize: '18px',
    },
  },
  image: {
    maxWidth: '100%',
  },
};

export const Heading = injectSheet(style)(
  (p: {
    id: ?string,
    text: ?string,
    classes: { [$Keys<typeof style>]: string },
  }) => (
    <UI.Heading level={2} id={p.id} className={p.classes.formSubHeading}>
      {p.text || ''}
    </UI.Heading>
  ),
);

export const DescriptionField = injectSheet(
  style,
)((p: { text: ?string, classes: { [$Keys<typeof style>]: string } }) => (
  <div className={p.classes.descriptionField}>
    {ReactHtmlParser(p.text || '')}
  </div>
));

export const Text = (p: { text: ?string }) => (
  <div dangerouslySetInnerHTML={{ __html: p.text || '' }} />
);

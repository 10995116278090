//@flow

import classnames from 'classnames';
import last from 'lodash/last';
import React from 'react';

import {
  InputIconWithBackground,
  OptionElementContainer,
  OptionInputElement,
} from '../multiple-choice/multipleOptions';
//$FlowIgnore
import s from './linearScale.less';

const LinearScaleRadio = (p: {
  max: number,
  min: number,
  maximumValueText: string,
  minimumValueText: string,
  id: string,
  onInputValue: (value: number | string) => void,
  input: any,
  mandatory: Boolean,
}) => {
  const choices = p.max - p.min;
  const maxText = p.maximumValueText;
  const minText = p.minimumValueText;

  const getText = index => {
    if (index === 0) return minText;
    if (index === choices - 1) return maxText;
  };

  const onKeyDown = getOnKeyDown(choices, p.min, p.id, value =>
    p.onInputValue(value),
  );

  const isChecked = index => p.input.value.given === p.min + index;

  const handleSelectValue = index => {
    const value = p.min + index;
    if (isChecked(index) && !p.mandatory) {
      p.onInputValue(null);
    } else {
      p.onInputValue(value);
    }
  };

  return (
    <div className={s.container}>
      {[...Array(choices)].map((a, i) => (
        <RadioOption
          id={p.id + '-' + i}
          key={i}
          first={i === 0}
          step={p.min + i}
          text={getText(i)}
          onKeyDown={onKeyDown}
          press={() => handleSelectValue(i)}
          checked={isChecked(i)}
        />
      ))}
    </div>
  );
};

const getCurrent = (): ?number => {
  const focusedElement = document.activeElement;
  const splitId = focusedElement && focusedElement.id.split('-');
  const focusedOption = splitId && parseInt(last(splitId));
  return focusedOption;
};

const getNextIndex = (key: string, currentIndex: number, maxIndex: number) => {
  if (key === 'ArrowDown' || key === 'ArrowRight') {
    return currentIndex < maxIndex ? currentIndex + 1 : 0;
  } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
    return currentIndex > 0 ? currentIndex - 1 : maxIndex;
  } else {
    return currentIndex;
  }
};

export const getOnKeyDown = (
  answerOptions: number,
  mininumValue: number,
  questionId: string,
  selectElement: number => void,
) => (e: SyntheticKeyboardEvent<HTMLElement>) => {
  const currentIndex = getCurrent();
  if (currentIndex == null) return;
  const nextIndex = getNextIndex(e.key, currentIndex, answerOptions - 1);

  if (currentIndex === nextIndex) return;

  const nextElementId = `${questionId}-${nextIndex}`;
  const nextElement = document.getElementById(nextElementId);

  selectElement(mininumValue + nextIndex);
  nextElement && nextElement.focus();
  e.preventDefault();
};

const RadioOption = (p: any) => (
  <div className={s.radioContainer}>
    <span className={classnames(s.radioLabel, s.stepText)}>{p.text}</span>
    <span className={s.radioLabel}>{p.step}</span>
    <OptionElementContainer
      overrideStyle={s.radioButtonContainer}
      checked={p.checked}
      type={'radio'}
      onSelect={p.disabled ? () => {} : p.press}
      id={p.id}
      onKeyDown={p.disabled ? () => {} : p.onKeyDown}
      disabled={p.disabled}
      focusElement={p.focusElement}
      focusClass={p.focusClass}
      first={p.first}
      inInvalidQuestion={p.invalid}
    >
      <OptionInputElement
        id={p.id}
        checked={p.checked}
        onClick={p.onSelectElement}
        ariaLabel={'none'}
        type={'radio'}
      />
      <InputIconWithBackground
        type={'radio'}
        checked={p.checked}
        inMatrix
        disabled={p.disabled}
        inInvalidQuestion={p.invalid}
      />
    </OptionElementContainer>
  </div>
);

export default LinearScaleRadio;

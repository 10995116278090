// @flow

import type { AnswerAction } from './actions';
import * as Backend from './backend/legacyJsonBackend';
import { allElements } from './form/elements';
import { linkVisibilityOptions } from './form/hiddenElements';
import type { FormLoadingStatus } from './form/loading';
import { processElementRandomization } from './form/randomized';
import {
  initialInputsWithAutofills,
  inputsFromSubmissionAnswers,
} from './inputs/initialInputs';
import type { FormInput } from './inputs/types';
import {
  inputsValidatedForElementAndQuestion,
  inputsWithValidationsFromBackend,
  invalidInput,
} from './submission-validation/validateInputs';

type AttachmentDeliveryStatus = ['STARTED', 'SUCCESS', 'FAILED'];

export type Attachment = {
  questionId: number,
  filename: string,
  status: AttachmentDeliveryStatus,
};

export type AnswerState = {
  formLoadingStatus: FormLoadingStatus,
  formLoadingError?: string,
  form: ?Backend.Form,
  inputs: FormInput[],
  dirty: boolean,
  currentPageIndex: number,
  submitting: boolean,
  submissionResponse:
    | ?Backend.SuccessfulSubmitResponse
    | ?Backend.MockSubmitResponse,
  emailReceiptBlockOpen?: boolean,
  emailReceiptSent: ?boolean,
  errorMessage: ?string,
  hideErrorBox: boolean,
  showingDeliveredSubmission?: boolean,
  submissionDeleted: boolean,
  lastActive: number,
  retries: number,
  mode?: string,
  invitationToken?: string,
  attachmentDeliveryInProgress?: boolean,
  numberOfAttachments?: number,
  numberOfDeliveredAttachments?: number,
  currentAttachmentNumber?: number,
  currentAttachment?: Attachment,
  failedAttachments: Attachment[],
};

const initialState = {
  formLoadingStatus: 'NOT_STARTED',
  form: null,
  dirty: false,
  inputs: [],
  currentPageIndex: 0,
  submitting: false,
  submissionResponse: null,
  emailReceiptBlockOpen: false,
  emailReceiptSent: false,
  errorMessage: null,
  showValidationError: false,
  invalidQuestions: [],
  hideErrorBox: true,
  submissionDeleted: false,
  startTime: 0,
  lastActive: new Date().getTime(),
  authenticated: true,
  retries: 0,
  attachmentDeliveryInProgress: undefined,
  numberOfAttachments: undefined,
  numberOfDeliveredAttachments: 0,
  currentAttachmentNumber: undefined,
  currentAttachment: undefined,
  failedAttachments: [],
};

export default function reducer(
  state: AnswerState = initialState,
  action: AnswerAction,
): AnswerState {
  switch (action.type) {
    case 'SET_MODE':
      return { ...state, mode: action.mode };
    case 'FETCH_FORM_INFORMATION_STARTED':
      return { ...state, formLoadingStatus: 'LOADING' };
    case 'REAUTHENTICATION_FAILED':
    case 'FORM_POSTPONE_FAILED':
    case 'SUBMIT_FORM_ERROR':
      return { ...state, submitting: false };
    case 'SUBMIT_ATTACHMENT_STARTED':
      return {
        ...state,
        attachmentDeliveryInProgress: true,
        numberOfAttachments: action.numberOfAttachments,
        currentAttachment: action.currentAttachment,
        currentAttachmentNumber: state.currentAttachmentNumber
          ? state.currentAttachmentNumber + 1
          : 1,
      };
    case 'SUBMIT_ATTACHMENT_FAILED':
      return {
        ...state,
        failedAttachments: [
          ...state.failedAttachments,
          action.currentAttachment,
        ],
        attachmentDeliveryInProgress:
          state.currentAttachmentNumber == state.numberOfAttachments
            ? false
            : true,
      };
    case 'SUBMIT_ATTACHMENT_SUCCESS':
      return {
        ...state,

        numberOfDeliveredAttachments: state.numberOfDeliveredAttachments + 1,
        attachmentDeliveryInProgress:
          state.currentAttachmentNumber == state.numberOfAttachments
            ? false
            : true,
      };
    case 'FETCH_FORM_INFORMATION_FAILED':
      return {
        ...state,
        formLoadingStatus: 'FAILED',
        formLoadingError: action.messageToUser,
      };
    case 'GOT_FORM_INFORMATION': {
      const formWithLinkedVisibilityOptions = linkVisibilityOptions(
        action.form,
      );
      const formWithProcessedRandomization = processElementRandomization(
        formWithLinkedVisibilityOptions,
        !!action.randomizedOrder,
      );

      return {
        ...state,
        formLoadingStatus: 'LOADED',
        startTime: new Date().getTime(),
        invitationToken: action.invitationToken,
        form: formWithProcessedRandomization,
        inputs:
          state.inputs.length > 0
            ? state.inputs
            : initialInputsWithAutofills(
                action.form.answers,
                allElements(action.form.pages),
              ),
        submissionResponse: action.quizFeedback
          ? { quizFeedback: action.quizFeedback }
          : undefined,
      };
    }
    case 'GOT_SUBMISSION_INFORMATION': {
      if (state.form == null) {
        throw new Error('Form should be loaded at this point');
      }
      const submissionAnswerInputs = inputsFromSubmissionAnswers(
        action.payload.answers,
        state.inputs,
        allElements(state.form.pages),
      );
      const newInputs = state.inputs.map((question, index) => {
        if (question.value.disabled === true) {
          return question;
        } else {
          return submissionAnswerInputs[index];
        }
      });
      return {
        ...state,
        inputs: newInputs,
        showingDeliveredSubmission: action.payload.delivered,
      };
    }
    case 'GOT_INPUT_VALUE': {
      if (state.form == null) throw 'form not loaded';
      const { elementId, questionId, value } = action;
      const element = allElements(state.form.pages).find(
        element => element.elementId === elementId,
      );
      if (element == null) throw 'element does not exist';
      const inputsWithNewValue = state.inputs.map(input =>
        input.questionId === questionId ? { ...input, value } : input,
      );
      if (state.form == null || state.form.pages == null)
        throw 'pages do not exist';
      const inputs = inputsValidatedForElementAndQuestion(
        inputsWithNewValue,
        element,
        questionId,
        state.form.pages,
      );
      return {
        ...state,
        dirty: true,
        inputs,
      };
    }
    case 'GO_TO_PAGE': {
      return {
        ...state,
        currentPageIndex: action.payload.index,
        hideErrorBox: state.inputs.filter(invalidInput).length === 0,
      };
    }
    case 'VALIDATION_PERFORMED': {
      return { ...state, inputs: action.payload.validatedInputs };
    }
    case 'SUBMIT_FORM_AS_JSON_REQUESTED':
    case 'FORM_POSTPONE_REQUESTED':
    case 'SUBMIT_FORM_REQUESTED':
    case 'DELETE_SUBMISSION_REQUESTED':
    case 'SEND_EMAIL_RECEIPT_REQUESTED':
      return { ...state, submitting: true };
    case 'SUBMIT_FINISHED':
      return {
        ...state,
        submitting: false,
        submissionResponse: action.data,
        hideErrorBox: true,
      };
    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitting: false,
        hideErrorBox: false,
        inputs: inputsWithValidationsFromBackend(
          state.inputs,
          action.payload.errors,
        ),
      };
    case 'INCREMENT_RETRIES':
      return { ...state, retries: ++state.retries };
    case 'REAUTHENTICATION_REQUIRED': {
      return { ...state, authenticated: false, submitting: false };
    }
    case 'REAUTHENTICATION_SUCCESSFUL': {
      return { ...state, authenticated: true, submitting: false };
    }
    case 'REQUEST_DENIED_DUE_TO_VALIDATION_ERROR':
      return { ...state, hideErrorBox: false, submitting: false };
    case 'FORM_POSTPONE_STARTED':
      return { ...state, submitting: true };
    case 'FORM_POSTPONE_FINISHED':
      return {
        ...state,
        submitting: false,
        submissionResponse: action.data,
      };
    case 'OPEN_EMAIL_RECEIPT_CONTAINER':
      return { ...state, emailReceiptBlockOpen: true };
    case 'EMAIL_SUBMIT_FINISHED':
      return { ...state, emailReceiptSent: true, errorMessage: null };
    case 'EMAIL_SUBMIT_FAILED':
      return {
        ...state,
        errorMessage: action.response.message,
        submitting: false,
      };
    case 'SUBMISSION_DELETED':
      return { ...state, submissionDeleted: true };
    case 'UPDATE_LAST_ACTIVE':
      return {
        ...state,
        lastActive: action.data.lastActive,
      };
  }
  return state;
}

import Nettskjema from '../nettskjema';

function handleResponseErrors(response: Response): Response {
  if (!response.ok) {
    throw response;
  }
  return response;
}

export function apiGet(endpoint: string) {
  return fetch(Nettskjema.getContextPath() + endpoint, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
    .then(handleResponseErrors)
    .then(response => response.json());
}

export function apiPost(endpoint: string, body: string, csrf?: string) {
  return fetch(Nettskjema.getContextPath() + endpoint, {
    credentials: 'same-origin',
    method: 'POST',
    body: body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      NETTSKJEMA_CSRF_PREVENTION: csrf
        ? csrf
        : Nettskjema.getCsrfPreventionToken(),
    },
  })
    .then(handleResponseErrors)
    .then(response => response.json());
}

export function apiDelete(endpoint: string, csrf?: string) {
  return fetch(Nettskjema.getContextPath() + endpoint, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      NETTSKJEMA_CSRF_PREVENTION: csrf
        ? csrf
        : Nettskjema.getCsrfPreventionToken(),
    },
  })
    .then(handleResponseErrors)
    .then(response => response.json());
}

// @flow
import getSettings from '../backend/settings';
import ValidationSequence from './ValidationSequence';

const maxFileSize = getSettings().MAX_UPLOAD_FILE_SIZE_MB * 1024 * 1024;

const validateFile = (value: File) =>
  ValidationSequence()
    .when(value.size > maxFileSize, {
      includeError: [
        'attachment_file_size_max',
        { maxMb: getSettings().MAX_UPLOAD_FILE_SIZE_MB },
      ],
    })
    .when(!!value.name && !hasSupportedFileName(value), {
      includeError: 'attachment_file_name_not_supported',
    })
    .when(!!value.name && !hasSupportedFileType(value), {
      includeError: [
        'attachment_file_type_not_supported',
        { extension: value.name.substring(value.name.lastIndexOf('.')) + 1 },
      ],
    });

const hasSupportedFileName = (file: File) =>
  getSettings().SUPPORTED_FILE_NAME_REGEXP.test(file.name);
const hasSupportedFileType = (file: File) =>
  getSettings().SUPPORTED_FILE_TYPES.reduce(
    (result, fileExtension) =>
      result || file.name.toLowerCase().endsWith(fileExtension),
    false,
  );

export default validateFile;

// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import { connect } from 'react-redux';

import { ConfirmDialog } from '../modals/ConfirmDialog';
import type { Dispatch } from '../store';

const PostponeDialog = (p: {
  hideModal: () => void,
  onPostpone: () => void,
}) => {
  return (
    <I18n>
      {({ i18n }) => (
        <ConfirmDialog
          dialogTitle={i18n._(t`Lagre og utsett?`)}
          confirmText={i18n._(t`Ja, lagre`)}
          confirmAction={p.onPostpone}
          abortAction={p.hideModal}
          lockWhenSubmitting
        >
          <Trans>
            <p>Vil du fortsette denne undersøkelsen senere?</p>
            <p>
              Vi lagrer alt du har svart på, og du kan fortsette når som helst.
            </p>
          </Trans>
        </ConfirmDialog>
      )}
    </I18n>
  );
};

const ConnectedDialog = connect(
  () => ({}),
  (dispatch: Dispatch) => ({
    hideModal: () => {
      dispatch({ type: 'HIDE_MODAL' });
    },
    onPostpone: () => {
      dispatch({ type: 'FORM_POSTPONE_REQUESTED' });
    },
  }),
)(PostponeDialog);

export default ConnectedDialog;

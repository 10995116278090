// @flow

import { combineReducers } from 'redux';

import modalReducer from '../../components/modal/reducer';
import answerReducer from './reducer';

export default combineReducers({
  answer: answerReducer,
  modal: modalReducer,
});

// @flow

import * as React from 'react';
import { responsive } from 'react-usit-ui';

import type {
  AnswerOption,
  ElementType,
  Question,
} from '../backend/legacyJsonBackend';
import type {
  FormInput,
  MultipleOptionsInput,
  SingleOptionInput,
  UnprovidedInput,
} from '../inputs/types';
import { Matrix, MobileMatrix } from './multiple-choice';

const MultipleQuestionsElement = responsive.HOC(responsive.media.max640)(
  (p: {
    id: string,
    title?: string,
    description?: string,
    type: ElementType,
    questions: Question[],
    answerOptions: AnswerOption[],
    inputs: FormInput[],
    onInputValue: (
      questionId: number,
      value: MultipleOptionsInput | SingleOptionInput | UnprovidedInput,
    ) => void,
    responsive: boolean,
    postHeading?: React.Node,
  }) => {
    const elementModule = p.responsive ? MobileMatrix : Matrix;
    const ElementComponent =
      p.type === 'MATRIX_CHECKBOX'
        ? elementModule.OfCheckboxes
        : elementModule.OfRadioButtons;
    const inputsOfQuestions = p.inputs.filter(
      input =>
        p.questions.find(
          question => input.questionId === question.questionId,
        ) != null,
    );
    return (
      <ElementComponent
        id={p.id}
        title={p.title}
        description={p.description}
        answerOptions={p.answerOptions}
        postHeading={p.postHeading}
        questions={p.questions}
        inputs={inputsOfQuestions}
        onInputValue={p.onInputValue}
      />
    );
  },
);

export default MultipleQuestionsElement;

// @flow

import type { Dispatch as ReduxDispatch } from 'redux';
import type { Saga } from 'redux-saga';

import createNettskjemaStore from '../../createNettskjemaStore';
import type { AnswerAction } from './actions';
import type { ModalAction } from './modals';
import rootReducer from './rootReducer';
import * as history from './routing/history';

export function createStore(rootSaga: (...args: any) => Saga<void>) {
  const store = createNettskjemaStore({
    appReducer: rootReducer,
    rootSaga,
  });
  history.initialize(store);
  return store;
}

export type Dispatch = ReduxDispatch<AnswerAction | ModalAction>;

// @flow

import { Trans } from '@lingui/macro';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useSelector } from 'react-redux';
import { Button, responsive } from 'react-usit-ui';

import { createModal } from '../../../components/modal/Modal';
import { ns } from '../design/colors';

const style = {
  controls: {
    paddingTop: '20px',
    height: '28px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 50,
    minWidth: 150,
    margin: [0, 12],
  },
  [responsive.media.max640]: {
    controls: {
      display: 'block',
    },
    button: {
      display: 'block',
      width: '100%',
      margin: [0, 0, 20, 0],
    },
  },
};

const customModalStyle = {
  dialog: {
    zIndex: 199,
    padding: '60px',
    maxWidth: '620px',
    minWidth: '350px',
    backgroundColor: '#ffffff',
    boxShadow: '0 10px 40px 0 rgba(204, 204, 204, 0.5)',
    borderRadius: '8px',
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.04,
    marginBottom: '20px',
    color: ns.black,
  },
  body: {
    color: ns.black,
    marginBottom: '20px',
  },
  responsive: {
    dialog: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      border: 'none',
      borderRadius: 0,
      overflow: 'scroll',
    },
  },
};

const Dialog = (p: {
  actionButton: Button,
  closeButton: Button,
  dialogTitle: string,
  destructive?: boolean,
  classes: Object,
  children: Object,
  abortAction: () => void,
  confirmText: string,
  confirmAction: () => void,
  lockWhenSubmitting?: boolean,
}) => {
  const submitting = useSelector(state => state.answer.submitting);
  const firstButton = (
    <Button
      className={p.classes.button}
      type="primary"
      onClick={p.destructive ? p.abortAction : p.confirmAction}
      disabled={p.lockWhenSubmitting && !p.destructive && submitting}
    >
      {p.destructive ? <Trans>Avbryt</Trans> : p.confirmText}
    </Button>
  );
  const secondButton = (
    <Button
      className={p.classes.button}
      type="secondary"
      border
      onClick={p.destructive ? p.confirmAction : p.abortAction}
      disabled={p.lockWhenSubmitting && p.destructive && submitting}
    >
      {p.destructive ? p.confirmText : <Trans>Avbryt</Trans>}
    </Button>
  );
  const CustomModal = createModal();
  return (
    <CustomModal
      headerContent={<React.Fragment>{p.dialogTitle}</React.Fragment>}
      mainContent={
        <React.Fragment>
          {p.children}
          <div className={p.classes.controls}>
            {firstButton}
            {secondButton}
          </div>
        </React.Fragment>
      }
      abortAction={p.abortAction}
      customModalStyle={customModalStyle}
    />
  );
};

export const ConfirmDialog = injectSheet(style)(Dialog);

// @flow

import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Heading } from 'react-usit-ui';

import getSettings from '../backend/settings';

const Title = () => (
  <Heading>{ReactHtmlParser(getSettings().formTitle)}</Heading>
);

// $FlowIgnore Flow 0.78 mangler type React.memo
export default React.memo(Title);

// @flow

import * as React from 'react';

import type { ModalAction as GenericModalAction } from '../../../components/modal/actions';
import GenericModalRoot from '../../../components/modal/ModalRoot';
import { ConfirmSwitchDesignModal } from '../LegacyEscapeHatch.js';
import { ConfirmDeleteModal } from '../modes/editSubmission/ConfirmDeleteModal';
import PostponeModal from '../postponed-response/PostponeModal';
import {
  ConnectedFeedbackDialog,
  EscapeHatchFeedbackDialog,
} from '../user-feedback/FeedbackDialog';
import { ReauthenticationModal } from './ReauthenticationModal';
import { RetryModal } from './RetryModal';

const modalComponents = {
  postpone: PostponeModal,
  confirmSwitchDesign: ConfirmSwitchDesignModal,
  feedbackDialog: ConnectedFeedbackDialog,
  escapeHatchFeedbackDialog: EscapeHatchFeedbackDialog,
  confirmDeleteSubmission: ConfirmDeleteModal,
  reauthentication: ReauthenticationModal,
  retry: RetryModal,
};

export type ModalAction = GenericModalAction<$Keys<typeof modalComponents>>;

export const ModalRoot = () => (
  <GenericModalRoot components={modalComponents} />
);

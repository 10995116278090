// @flow

import * as React from 'react';

import { logError } from '../../errorHandling';

class ErrorBoundary extends React.Component<
  { children: React.Node, name: string, fallback?: React.Node },
  { hasError: boolean },
> {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error | string, errorInfo: Object) {
    this.setState({ hasError: true });
    logError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || false;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import { AuthenticatedInfo } from '../../../../nettskjema';

export type PersonAction = UpdateCurrentPerson | UpdateSuperuser;

export type UpdateCurrentPerson = {
  type: 'UPDATE_CURRENT_PERSON';
  data: { authenticatedInfo: AuthenticatedInfo };
};

export const updateCurrentPerson = (
  authenticatedInfo: AuthenticatedInfo,
): UpdateCurrentPerson => ({
  type: 'UPDATE_CURRENT_PERSON',
  data: { authenticatedInfo: authenticatedInfo },
});

export type UpdateSuperuser = {
  type: 'UPDATE_SUPERUSER';
  data: { isSuperuser: boolean };
};

export const updateSuperuser = (isSuperuser: boolean): UpdateSuperuser => ({
  type: 'UPDATE_SUPERUSER',
  data: { isSuperuser: isSuperuser },
});

// @flow

export { default as Checkboxes } from './Checkboxes';
export { default as RadioButtons } from './RadioButtons';

import * as matrix from './matrix';
import * as mobileMatrix from './mobileMatrix';
export { optionMaxWidth } from './multipleOptions';

export const Matrix = {
  OfCheckboxes: matrix.CheckboxMatrix,
  OfRadioButtons: matrix.RadioMatrix,
};
export const MobileMatrix = {
  OfCheckboxes: mobileMatrix.CheckboxMatrix,
  OfRadioButtons: mobileMatrix.RadioMatrix,
};

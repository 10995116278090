// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import * as Backend from '../backend/legacyJsonBackend';
import type { Dispatch } from '../store';

const routeToSubpage: (string, number) => string = (
  route,
  currentPageIndex,
) => {
  return `/page/${currentPageIndex + 1}/${route}`;
};

const rescrollToElement: (string, number, (number) => void) => void = (
  route,
  pageIndex,
  scrollOnPage,
) => {
  const subpageUrl = `#${routeToSubpage(route, pageIndex)}`;
  if (subpageUrl === window.location.hash) {
    const matcher = /^#\/page\/\d+\/question\/(\d+)$/;
    const match = matcher.exec(window.location.hash);
    if (match && match.length == 2) {
      scrollOnPage(Number(match[1]));
    }
  }
};

const LinkToSubpageOfPage = (p: {
  to: string,
  pageIndex: number,
  children: React.Node,
  className?: string,
  scrollOnPage: number => void,
}) => (
  <a
    className={p.className}
    onClick={() => {
      rescrollToElement(p.to, p.pageIndex, p.scrollOnPage);
    }}
    onKeyDown={event => {
      if (event.key === 'Enter' || event.key === 'Space') {
        rescrollToElement(p.to, p.pageIndex, p.scrollOnPage);
      }
    }}
    href={`#${routeToSubpage(p.to, p.pageIndex)}`}
  >
    {p.children}
  </a>
);

const pageIndexOfQuestion = (questionId: number, pages: Backend.Page[]) => {
  const pagesContainingQuestion = pages
    .map((page, index) => ({ elements: page.elements, index }))
    .filter(
      indexedPage =>
        indexedPage.elements.find(
          element =>
            element.questions.find(
              question => question.questionId === questionId,
            ) != null,
        ) != null,
    );
  if (pagesContainingQuestion.length !== 1) {
    throw new Error(
      `Question ID ${questionId} not found on one and only one page`,
    );
  }
  return pagesContainingQuestion[0].index;
};

const nameOfQuestion = (questionId: number, pages: Backend.Page[]) => {
  const questionsMatchingId = pages
    .reduce((prev, next) => [...prev, ...next.elements], [])
    .reduce((prev, next) => [...prev, ...next.questions], [])
    .filter(question => question.questionId === questionId);
  if (questionsMatchingId.length !== 1) {
    throw new Error(`Question ID ${questionId} not found and unique`);
  }
  return questionsMatchingId[0].text;
};

const LinkToQuestion = (p: {
  questionId: number,
  pages: Backend.Page[],
  className?: string,
  transformTitle: string => React.Node,
  scrollOnPage: number => void,
}) => (
  <LinkToSubpageOfPage
    className={p.className}
    to={`question/${p.questionId}`}
    pageIndex={pageIndexOfQuestion(p.questionId, p.pages)}
    scrollOnPage={p.scrollOnPage}
  >
    {p.transformTitle(nameOfQuestion(p.questionId, p.pages))}
  </LinkToSubpageOfPage>
);

export const ConnectedLinkToQuestion = connect(
  (state, ownProps) => ({
    pages: state.answer.form.pages,
    ...ownProps,
  }),
  (dispatch: Dispatch) => ({
    scrollOnPage: (questionId: number) =>
      dispatch({
        type: 'SCROLL_ON_PAGE',
        questionId,
      }),
  }),
)(LinkToQuestion);

// @flow

import { createHashHistory } from 'history';
import type { Store } from 'redux';

import type { AnswerAction } from '../actions';

export const history = createHashHistory({});
export type LocationWrapper = {
  hash: string,
  pathname: string,
  search: string,
};

export function initialize(store: Store<any, AnswerAction>) {
  history.listen((location, action) => {
    if (action === 'POP' || action === 'REPLACE') {
      store.dispatch({
        type: 'URL_CHANGED',
        payload: {
          toLocation: { ...location },
          fromLocation: { ...history.location },
        },
      });
    }
  });
}

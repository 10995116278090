// @flow

import shuffle from 'lodash/shuffle';

import * as Backend from '../backend/legacyJsonBackend';
import { isQuestionOrMatrixElement } from '../../user/form/sagas';

const isShuffleableQuestion = (element: Backend.Element) =>
  isQuestionOrMatrixElement(element.elementType) &&
  element.elementType !== 'SUBMISSION_REFERENCE';

// Shuffle question elements but keep non-question elements in place
const shuffleQuestionElements = (elements: Backend.Element[]) => {
  const shuffledQuestionIndeces = shuffle(
    elements
      .map((element, index) => [element, index])
      .filter(([element]) => isShuffleableQuestion(element))
      .map(([, originalIndex]) => originalIndex),
  );

  let pickCounter = 0;
  return Array.from({ length: elements.length }).map((_, index) =>
    isShuffleableQuestion(elements[index])
      ? elements[shuffledQuestionIndeces[pickCounter++]]
      : elements[index],
  );
};

export const processElementRandomization = (
  form: Backend.Form,
  randomizeQuestionOrder: boolean,
) => {
  const updatedPages = form.pages.map(page => {
    const updatedElements = page.elements.map(element => {
      if (
        element.randomizedOrder &&
        ['RADIO', 'CHECKBOX', 'SELECT'].includes(element.elementType)
      ) {
        return {
          ...element,
          questions: element.questions.map(question => ({
            ...question,
            answerOptions: shuffle(question.answerOptions),
          })),
        };
      } else if (
        element.randomizedOrder &&
        ['MATRIX_RADIO', 'MATRIX_CHECKBOX'].includes(element.elementType)
      ) {
        return { ...element, questions: shuffle(element.questions) };
      } else {
        return element;
      }
    });

    const shuffledElements = randomizeQuestionOrder
      ? shuffleQuestionElements(updatedElements)
      : updatedElements;

    return { ...page, elements: shuffledElements };
  });
  return { ...form, pages: updatedPages };
};

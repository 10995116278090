//@flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { connect, useDispatch } from 'react-redux';
import { responsive } from 'react-usit-ui';

import { createModal } from '../../../components/modal/Modal';
import { primary } from '../design/colors';
import { EmbeddedForm } from '../modes/reactEmbedding';
import type { Dispatch } from '../store';
import feedbackIcon from './tilbakemelding-inverted.png';

const style = {
  title: {
    marginLeft: 24,
  },
  [responsive.media.max640]: {
    title: {
      margin: '0 18px 0 18px',
      fontWeight: 'bold',
      fontSize: '30px',
    },
  },
};

const customModalStyle = {
  dialog: {
    zIndex: 199,
    padding: 0,
    maxWidth: '620px',
    width: '566px',
    minWidth: '350px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: `3px solid ${primary.dark}`,
  },
  header: {
    backgroundColor: primary.dark,
    height: 67,
    paddingLeft: 20,
    fontSize: 28,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    padding: '55px 83px 55px 83px',
  },
  responsive: {
    dialog: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      border: 'none',
      borderRadius: 0,
      overflow: 'scroll',
      width: '100%',
    },
    header: {
      backgroundColor: 'transparent',
      color: '#555555',
      marginBottom: '16px',
    },
    body: {
      padding: 0,
      margin: '0 18px 0 18px',
      alignItems: 'unset',
    },
  },
};

const hatchFeedbackStyle = {
  ...customModalStyle,
  dialog: {
    ...customModalStyle.dialog,
    width: '700px',
    maxWidth: '90vw',
    textarea: {
      minWidth: '100%',
    },
  },
  responsive: {
    ...customModalStyle,
    dialog: {
      ...customModalStyle.responsive.dialog,
      maxWidth: '100%',
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const CustomModal = createModal();

const FeedbackDialog = (p: {
  classes: Classes,
  children: Object,
  hideModal: () => void,
}): React.Node => (
  <I18n>
    {({ i18n }) => (
      <CustomModal
        headerContent={
          <React.Fragment>
            <img alt="" src={feedbackIcon} />
            <span className={p.classes.title}>
              <Trans>Gi oss din tilbakemelding</Trans>
            </span>
          </React.Fragment>
        }
        mainContent={
          <EmbeddedForm
            formId={i18n._(t('feedbackFormId')`tilbakemelding-nyttdesign`)}
            onSubmitFinished={p.hideModal}
            onAbort={p.hideModal}
          />
        }
        customModalStyle={customModalStyle}
        abortAction={p.hideModal}
      />
    )}
  </I18n>
);

const EscapeHatchFeedbackDialog_ = (p: { classes: Classes }) => {
  const dispatch = useDispatch();
  const goToOldDesign = () => dispatch({ type: 'OLD_DESIGN_CONFIRMED' });
  const hideModal = () => dispatch({ type: 'HIDE_MODAL' });
  return (
    <I18n>
      {({ i18n }) => (
        <CustomModal
          headerContent={
            <>
              <img alt="" src={feedbackIcon} />
              <span className={p.classes.title}>
                <Trans>Hjelp oss å forbedre Nettskjema</Trans>
              </span>
            </>
          }
          mainContent={
            <EmbeddedForm
              formId={i18n._(t`legacy-feedback-no`)}
              abortText={i18n._(t`Jeg vil ikke svare`)}
              submitText={i18n._(t`Gi tilbakemelding`)}
              onSubmitFinished={goToOldDesign}
              onAbort={goToOldDesign}
            />
          }
          customModalStyle={hatchFeedbackStyle}
          abortAction={hideModal}
        />
      )}
    </I18n>
  );
};

const StyledFeedbackDialog = injectSheet(style)(FeedbackDialog);

export const EscapeHatchFeedbackDialog = injectSheet(style)(
  EscapeHatchFeedbackDialog_,
);

export const ConnectedFeedbackDialog = connect(
  () => ({}),
  (dispatch: Dispatch) => ({
    hideModal: () => {
      dispatch({ type: 'HIDE_MODAL' });
    },
  }),
)(StyledFeedbackDialog);

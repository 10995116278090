// @flow

import type {
  BasicResponsePromise,
  JSONResponsePromise,
} from '../../../responseTypes';
import * as ApiV2 from './apiV2';
import type {
  ErrorResponse,
  FormResponse,
  SubmitResponse,
} from './legacyJsonBackend';
import getSettings from './settings';

export type Submitter = (
  id: number,
  formData: FormData,
) => JSONResponsePromise<SubmitResponse>;

export type SubmitterAnswerJsonType = (
  formData: string,
  formId: number,
) => JSONResponsePromise<SubmitResponse>;

export type SubmitterInvitationJsonType = (
  formData: string,
  invitationId: number,
  invitationToken?: string,
) => JSONResponsePromise<SubmitResponse>;

export type SubmitterAttachment = (
  formId: number,
  questionId: number,
  submissionId: number,
  attachment: any,
  attachmentToken: string,
) => JSONResponsePromise<SubmitResponse>;

type PingResponse = {
  NETTSKJEMA_CSRF_PREVENTION: string,
};

type AuthenticationStatusResponse = {
  isAuthenticated: boolean,
};

export type BackendInterface = {
  fetchForm: (
    formId: number | string,
  ) => JSONResponsePromise<FormResponse | ErrorResponse>,
  fetchFormForEditing: (
    submissionId: number,
  ) => JSONResponsePromise<FormResponse | ErrorResponse>,
  fetchFormForViewing: (
    submissionId: number,
  ) => JSONResponsePromise<FormResponse | ErrorResponse>,
  fetchInvitation: (invitationId: number) => JSONResponsePromise<any>,
  fetchPreview: (formId: number) => JSONResponsePromise<any>,
  fetchSubmission: (
    submissionId: number,
  ) => JSONResponsePromise<ApiV2.SubmissionResponse>,
  updateSubmission: Submitter,
  submitSubmission: Submitter,
  submitForm: Submitter,
  submitFormJson: SubmitterAnswerJsonType,
  submitAttachment: any,
  submitInvitation: Submitter,
  submitInvitationJson: SubmitterInvitationJsonType,
  submitEmail: (email: string) => BasicResponsePromise,
  postponeForm: (
    formId: number,
    submissionId: number,
    invitationId: number,
    formData: FormData,
  ) => BasicResponsePromise,
  callPingNettskjema: () => JSONResponsePromise<PingResponse | ErrorResponse>,
  callPingNettskjemaNewApi: () => JSONResponsePromise<
    PingResponse | ErrorResponse,
  >,
  requestNewTokenForSubmission: (
    expiredToken: string,
    submissionId: number,
  ) => BasicResponsePromise,
  requestNewTokenForInvitation: (
    expiredToken: string,
    invitationId: number,
  ) => BasicResponsePromise,
  sendReauthenticationCodeForSubmission: (
    expiredToken: string,
    token: string,
    submissionId: number,
  ) => BasicResponsePromise,
  sendReauthenticationCodeForInvitation: (
    expiredToken: string,
    token: string,
    invitationId: number,
  ) => BasicResponsePromise,
  authenticationStatus: (
    submissionId: ?number,
    invitationId: ?number,
  ) => JSONResponsePromise<AuthenticationStatusResponse>,
};

function getCodebookParameters(locationSearch: string) {
  let restParameters = '';
  if (locationSearch) {
    locationSearch.split(/[&?]/).forEach(param => {
      const keyValue = param.split('=');
      if (
        keyValue.length === 2 &&
        (keyValue[0].indexOf('CB') === 0 || keyValue[0].indexOf('LCK') === 0)
      ) {
        restParameters += `&${keyValue[0]}=${keyValue[1]}`;
      }
    });
  }
  return restParameters;
}

export function getApi(
  baseUrl: string = getSettings().baseUrl,
): BackendInterface {
  const nettskjemaAnswerUrl = (endpoint, formId, ...restParameters) =>
    `${baseUrl}/answer/${endpoint}.json?formId=${formId}${restParameters.join(
      '',
    )}`;
  const nettskjemaInvitationUrl = (endpoint, formId, ...restParameters) =>
    `${baseUrl}/invitation/${endpoint}.json?invitationId=${formId}${restParameters.join(
      '',
    )}`;
  const nettskjemaPreviewUrl = formId =>
    `${baseUrl}/user/form/preview.json?formId=${formId}`;
  const legacySubmissionEndpoint = (
    endpoint,
    submissionId,
    ...restParameters
  ) =>
    `${baseUrl}/user/submission/${endpoint}.json?submissionId=${submissionId}${restParameters.join(
      '',
    )}`;
  const submissionEndpoint = submissionId =>
    `${baseUrl}/api/v2/submissions/${submissionId}?submissionId=${submissionId}`;

  const quizResultParameter = '&quizResultAsJson=true';

  return {
    fetchForm: (formId: number | string) =>
      getRequest(
        nettskjemaAnswerUrl(
          'answer',
          formId,
          getCodebookParameters(window.location.search),
        ),
      ),
    fetchFormForEditing: (submissionId: number) =>
      getRequest(legacySubmissionEndpoint('edit', submissionId)),
    fetchFormForViewing: (submissionId: number) =>
      getRequest(legacySubmissionEndpoint('show', submissionId)),
    fetchInvitation: (invitationId: number) =>
      getRequest(nettskjemaInvitationUrl('answer', invitationId)),
    fetchPreview: (formId: number) => getRequest(nettskjemaPreviewUrl(formId)),
    fetchSubmission: (submissionId: number) =>
      getRequest(submissionEndpoint(submissionId)),
    updateSubmission: (
      submissionId: number,
      formData: FormData,
      elapsedTimeParameter?: string,
    ) =>
      postRequest(
        legacySubmissionEndpoint(
          'update',
          submissionId,
          quizResultParameter,
          elapsedTimeParameter,
        ),
        formData,
      ),
    submitSubmission: (
      submissionId: number,
      formData: FormData,
      elapsedTimeParameter?: string,
      retriesParameter?: string,
    ) =>
      postRequest(
        legacySubmissionEndpoint(
          'deliver',
          submissionId,
          quizResultParameter,
          elapsedTimeParameter,
          retriesParameter,
        ),
        formData,
      ),
    submitForm: (
      formId: number,
      formData: FormData,
      elapsedTimeParameter?: string,
      retriesParameter?: string,
    ) =>
      postRequest(
        nettskjemaAnswerUrl(
          'deliver',
          formId,
          quizResultParameter,
          elapsedTimeParameter,
          retriesParameter,
        ),
        formData,
      ),
    submitFormJson: (formData: string, formId: number) => {
      return jsonPostRequest(
        `${getSettings().CONTEXT_PATH}/api/v3/form/${formId}/submission`,
        formData,
      );
    },

    submitAttachment: (
      formId: number,
      questionId: number,
      submissionId: number,
      attachment: any,
      attachmentToken: string,
    ) => {
      return attachmentPostRequest(
        `${
          getSettings().CONTEXT_PATH
        }/api/v3/form/submission/${submissionId}/attachment?questionId=${questionId}&filename=${
          attachment.name
        }&fileSize=${attachment.size}`,
        attachment,
        attachmentToken,
      );
    },
    submitInvitation: (
      invitationId: number,
      formData: FormData,
      elapsedTimeParameter?: string,
      retriesParameter?: string,
    ) =>
      postRequest(
        nettskjemaInvitationUrl(
          'deliver',
          invitationId,
          quizResultParameter,
          elapsedTimeParameter,
          retriesParameter,
        ),
        formData,
      ),
    submitInvitationJson: (
      json: any,
      invitationId: number,
      invitationToken?: string,
    ) =>
      jsonPostRequest(
        `${
          getSettings().CONTEXT_PATH
        }/api/v3/form/invitation/${invitationId}/submission`,
        json,
        invitationToken,
      ),

    postponeForm: (
      formId: number,
      submissionId: number,
      invitationId: number,
      formData: FormData,
      elapsedTimeParameter?: string,
    ) =>
      postRequest(
        submissionId
          ? legacySubmissionEndpoint(
              'postpone',
              submissionId,
              elapsedTimeParameter,
            )
          : invitationId
          ? nettskjemaInvitationUrl(
              'postpone',
              invitationId,
              elapsedTimeParameter,
            )
          : nettskjemaAnswerUrl('postpone', formId, elapsedTimeParameter),
        formData,
      ),
    submitEmail: (email: string) => {
      // POST with empty body? Should be fixed. NETTSKJEMA-5508
      const languageCode = getSettings().languageCode;
      return window.fetch(
        `${baseUrl}/receipt.json?email=${email}&languageCode=${languageCode}`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: postHeaders(),
        },
      );
    },
    callPingNettskjema: () =>
      window.fetch(`${baseUrl}/ping.json`, {
        method: 'GET',
        credentials: 'same-origin',
      }),
    callPingNettskjemaNewApi: () =>
      window.fetch(`${baseUrl}/api/v3/csrftoken`, {
        method: 'GET',
        credentials: 'same-origin',
      }),
    requestNewTokenForSubmission: (
      expiredToken: string,
      submissionId: number,
    ) =>
      postRequest(
        `${
          getSettings().CONTEXT_PATH
        }/request-token-for-submission.json?token=${expiredToken}&submissionId=${submissionId}`,
      ),
    requestNewTokenForInvitation: (
      expiredToken: string,
      invitationId: number,
    ) =>
      postRequest(
        `${
          getSettings().CONTEXT_PATH
        }/request-token-for-invitation.json?token=${expiredToken}&invitationId=${invitationId}`,
      ),
    sendReauthenticationCodeForInvitation: (
      expiredToken: string,
      token: string,
      invitationId: number,
    ) => {
      const queryString = `?token=${expiredToken}&reauthenticationToken=${token}&invitationId=${invitationId}`;
      return postRequest(
        `${
          getSettings().CONTEXT_PATH
        }/reauthenticate-invitation.json${queryString}`,
      );
    },
    sendReauthenticationCodeForSubmission: (
      expiredToken: string,
      token: string,
      submissionId: number,
    ) => {
      const queryString = `?token=${expiredToken}&reauthenticationToken=${token}&submissionId=${submissionId}`;
      return postRequest(
        `${
          getSettings().CONTEXT_PATH
        }/reauthenticate-submission.json${queryString}`,
      );
    },
    authenticationStatus: (submissionId, invitationId) => {
      let queryString = '';
      if (submissionId) {
        queryString += `?submissionId=${submissionId}`;
      } else if (invitationId) {
        queryString += `?invitationId=${invitationId}`;
      }

      return getRequest(
        `${getSettings().CONTEXT_PATH}/authenticationStatus.json${queryString}`,
      );
    },
  };
}

export const downloadAttachmentUrl = (answerAttachmentId: number) =>
  `${getSettings().baseUrl}/user/submission/download-attachment.html?id=` +
  answerAttachmentId;

const postRequest = (url: string, data?: FormData | string) => {
  const headers = postHeaders();
  return window.fetch(url, {
    method: 'POST',
    body: data,
    credentials: 'same-origin',
    headers: headers,
  });
};

const postHeaders = () => ({
  NETTSKJEMA_CSRF_PREVENTION: getSettings().csrfPreventionToken,
  'X-CSRF-TOKEN': getSettings().csrfPreventionToken,
});

const getRequest = (url: string) =>
  window.fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  });

const attachmentPostRequest = (url, attachment, attachmentToken) => {
  const headers = {
    'X-CSRF-TOKEN': getSettings().csrfPreventionToken,
    'X-nettskjema-language': getSettings().formLanguage,
    'Content-Type': 'application/octet-stream',
    'X-nettskjema-attachment-token': attachmentToken,
  };

  return window.fetch(url, {
    method: 'PUT',
    body: attachment,
    credentials: 'same-origin',
    headers: headers,
  });
};

const jsonPostRequest = (
  url: string,
  data: string,
  invitationToken?: string,
) => {
  const headers = {
    'X-CSRF-TOKEN': getSettings().csrfPreventionToken,
    'X-nettskjema-language': getSettings().formLanguage,
    'X-nettskjema-invitation-token': invitationToken,
    'Content-Type': 'application/json',
  };

  return window.fetch(url, {
    method: 'POST',
    body: data,
    credentials: 'same-origin',
    headers: headers,
  });
};

export const forTesting = { getCodebookParameters };

// @flow

import type {
  Element,
  Form,
  Page,
  Question,
} from '../backend/legacyJsonBackend';
import {
  elementShouldBeHidden,
  inputIsForHiddenElement,
} from '../form/hiddenElements';
import type { FormInput, InputValue } from '../inputs/types';
import { create, invalid } from './types';
import type {
  BackendValidationError,
  PerformedValidation,
  Validation,
} from './types';
import fieldValidation from './validateField';

function getExistingQuestion(questionId: number, element: Element): Question {
  const question = element.questions.find(
    question => question.questionId === questionId,
  );
  if (question == null) {
    throw new Error(
      `Question ID ${questionId} not found in element ${JSON.stringify(
        element,
      )}`,
    );
  }
  return question;
}

export const invalidInput = (input: FormInput) => invalid(input.validation);

const elementOnPageForInput = (input: FormInput, page: Page) =>
  page.elements.find(element =>
    element.questions.find(
      question => question.questionId === input.questionId,
    ),
  );

const inputQuestionIsOnPage = (input: FormInput, page: Page) =>
  elementOnPageForInput(input, page) != null;

export const inputsForPageAreAllValid = (inputs: FormInput[], page: Page) =>
  !inputs.find(
    input => invalid(input.validation) && inputQuestionIsOnPage(input, page),
  );

function inputValidation(args: {
  value: InputValue,
  element: Element,
  question: Question,
  visible: boolean,
}): Validation {
  if (!args.visible) {
    return create.passing();
  }
  return fieldValidation(args.value, args.element, args.question).getResult();
}

export function inputValidationForElement(
  element: Element,
  value: InputValue,
  questionId: number,
  allInputs: FormInput[],
  pages: Page[],
): Validation {
  return inputValidation({
    value,
    element,
    question: getExistingQuestion(questionId, element),
    visible: !elementShouldBeHidden(element, allInputs, pages),
  });
}

export const inputsValidatedForPage: (
  FormInput[],
  Page,
  Page[],
) => FormInput[] = (inputs, page, pages) =>
  inputs.map(input => {
    const element = elementOnPageForInput(input, page);
    if (element == null) return input;
    return {
      ...input,
      validation: inputValidationForElement(
        element,
        input.value,
        input.questionId,
        inputs,
        pages,
      ),
    };
  });

const validatedForElement = (
  element: Element,
  questionId: number,
  inputs: FormInput[],
  pages: Page[],
) => input =>
  input.questionId !== questionId
    ? input
    : {
        ...input,
        validation: inputValidationForElement(
          element,
          input.value,
          questionId,
          inputs,
          pages,
        ),
      };

export const currentPageInputsValidated = (
  currentPageIndex: number,
  form: Form,
  inputs: FormInput[],
) => inputsValidatedForPage(inputs, form.pages[currentPageIndex], form.pages);

export const BACKEND_ERROR_CODE = 'backend_error';

const isBackendValidated: FormInput => boolean = input =>
  input.validation.performed === true &&
  input.validation.errors.find(error => error.code === BACKEND_ERROR_CODE) !=
    null;

export const inputsWithValidationsFromBackend: (
  FormInput[],
  BackendValidationError[],
) => FormInput[] = (inputs, errors) =>
  inputs.map(input => {
    const matchingError = errors.find(
      error => error.questionId === input.questionId,
    );
    if (matchingError != null) {
      const validation: PerformedValidation = {
        performed: true,
        errors: [
          {
            code: BACKEND_ERROR_CODE,
            data: { message: matchingError.message },
          },
        ],
      };
      return {
        ...input,
        validation,
      };
    } else {
      return input;
    }
  });

export function inputsValidatedForElementAndQuestion(
  inputs: FormInput[],
  element: Element,
  questionId: number,
  pages: Page[],
): FormInput[] {
  return inputs.map(validatedForElement(element, questionId, inputs, pages));
}

export const invalidInputsFilteredForRespondent: (
  Form,
  FormInput[],
  Page,
  boolean,
) => FormInput[] = (form, inputs, currentPage, isLastPage) =>
  inputs.filter(invalidInput).filter(
    input =>
      !inputIsForHiddenElement(input, form, inputs) &&
      (isLastPage || // Always show all validation errors when on last page
      isBackendValidated(input) || // Always show backend validation errors
        currentPage.elements.find(
          element =>
            element.questions.find(
              question => question.questionId === input.questionId,
            ) != null,
        ) != null), // Apart from that, show only validation errors on the current page
  );

export const hasInvalidInputValues: (FormInput[]) => boolean = inputs => {
  const inputsWithInvalidValues = inputs.filter(
    input =>
      input.validation.performed &&
      input.validation.errors &&
      input.validation.errors.length !== 0 &&
      input.validation.errors.reduce(
        (containsError: boolean, currentElement) =>
          containsError || currentElement.code !== 'mandatory_question',
        false,
      ),
  );

  return inputsWithInvalidValues.length > 0;
};

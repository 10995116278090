import classNames from 'classnames';
// @flow
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import { responsive } from 'react-usit-ui';

import getSettings from '../backend/settings';
import * as colors from '../design/colors';
import info from './info-white.svg';

const style = {
  operationMessageWrapper: {
    backgroundColor: colors.operationMessage.background,
    color: colors.white,
    fontSize: 18,
    padding: [10, 20],
    borderRadius: 4,
    marginBottom: 30,
  },
  operationMessageContainer: {
    background: `url(${info}) 0 2px no-repeat`,
    backgroundSize: 18,
    margin: 'auto',
    maxWidth: 970,
    paddingLeft: 30,
    '& a': {
      color: colors.white,
      textDecoration: 'underline',
      fontWeight: 'bolder',
    },
    '& a:focus': {
      color: colors.operationMessage.background,
      backgroundColor: colors.white,
    },
  },

  noHeading: { marginBottom: 30 },

  [responsive.media.max640]: {
    operationMessageWrapper: {
      maxWidth: '100%',
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const OperationMessageContainer_ = (p: {
  classes: Classes,
  noHeading?: boolean,
}) => {
  return getSettings().operationMessage ? (
    <div
      className={classNames([
        p.classes.operationMessageWrapper,
        p.noHeading && p.classes.noHeading,
      ])}
    >
      <div className={p.classes.operationMessageContainer}>
        <span>{ReactHtmlParser(getSettings().operationMessage)}</span>
      </div>
    </div>
  ) : null;
};

export const OperationMessageContainer = injectSheet(style)(
  OperationMessageContainer_,
);

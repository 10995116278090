// @flow

import * as React from 'react';

import * as Backend from '../../backend/legacyJsonBackend';
import type { FormInput, InputValue } from '../../inputs/types';
import { create, structure } from '../../inputs/types';
import * as combinations from '../elementValueCombinations.js';
import { DatePicker, DateTimePicker, TimePicker } from './DatePicker.js';

const DateAndOrTimeElement = ({
  onInputValue,
  input: { value, validation },
  type,
  id,
  dateFormat,
  mandatory,
}: {
  onInputValue: (value: InputValue) => void,
  input: FormInput,
  type: Backend.ElementType,
  id: string,
  dateFormat: ?Backend.DateFormat,
  mandatory: boolean,
}) => {
  const commonProps = { id, mandatory, focusClass: id };
  if (dateFormat == null) {
    throw new Error('dateFormat must be specified');
  }
  if (combinations.date(value, type, dateFormat)) {
    return (
      <DatePicker
        {...commonProps}
        onChange={given => {
          onInputValue(!given ? create.notProvided() : create.date(given));
        }}
        selectedDate={value.type === structure.date.type ? value.given : null}
        disabled={value.disabled}
        validation={validation}
      />
    );
  }
  if (combinations.time(value, type, dateFormat)) {
    return (
      <TimePicker
        focusElement={true}
        {...commonProps}
        onChange={given => {
          onInputValue(!given ? create.notProvided() : create.time(given));
        }}
        selectedTime={value.type === structure.time.type ? value.given : null}
        disabled={value.disabled}
        validation={validation}
      />
    );
  }
  if (combinations.dateTime(value, type, dateFormat)) {
    return (
      <DateTimePicker
        {...commonProps}
        onChange={(givenDate, givenTime) => {
          onInputValue(create.dateTime(givenDate, givenTime));
        }}
        selectedDate={
          value.type === structure.dateTime.type ? value.givenDate : null
        }
        selectedTime={
          value.type === structure.dateTime.type ? value.givenTime : null
        }
        disabled={value.disabled}
        validation={validation}
      />
    );
  }
  throw new Error(`Unmatched combination: ${value.type}, ${type}`);
};

export default DateAndOrTimeElement;

// @flow

import { t } from '@lingui/macro';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { logError } from '../../../errorHandling';
import type { SendEmailReceipt } from '../actions';
import i18n from '../i18n';
import getFormData from '../inputs/getFormData';
import { structure } from '../inputs/types';
import { redirectToUrl } from '../routing/redirect';
import { denyUserRequestDueToValidation } from '../submission-validation/denyUserRequest';
import { hasInvalidInputValues } from '../submission-validation/validateInputs';
import { validateForm } from '../submission-validation/validateSubmission';
import type {
  BackendInterface,
  Submitter,
  SubmitterAnswerJsonType,
  SubmitterAttachment,
  SubmitterInvitationJsonType,
} from './interface';
import * as Backend from './legacyJsonBackend';
import getSettings from './settings';
import type { FormResponse } from './types';

export function* genericGetFormInformation(
  fetchFunction: (id: any) => Promise<any>,
  id: string | number,
): Saga<void> {
  yield put({ type: 'FETCH_FORM_INFORMATION_STARTED' });
  try {
    const response = yield call(fetchFunction, id);
    const json: Backend.FormResponse | Backend.ErrorResponse = yield call([
      response,
      response.json,
    ]);

    if (json.status === 'success') {
      const form = getFormFromJson(json);

      let invitationToken = undefined;
      if (response != null && response.headers != null) {
        invitationToken = response.headers.get('X-nettskjema-invitation-token');
      }

      let deliverWithApiEnabled = false;
      if (response != null && response.headers != null) {
        const apiDelivery = response.headers.get(
          'X-nettskjema-deliver-using-nettskjema-api',
        );

        if (apiDelivery === 'true') {
          deliverWithApiEnabled = true;
        }

        if (apiDelivery === 'false') {
          deliverWithApiEnabled = false;
        }
      }

      yield put({
        type: 'GOT_FORM_INFORMATION',
        invitationToken: invitationToken,
        form: { ...form, deliverWithApiEnabled: deliverWithApiEnabled },
        quizFeedback: json.quizFeedback,
        randomizedOrder: json.form.randomizedOrder,
      });
    } else if (json.status === 'failure') {
      yield put({
        type: 'FETCH_FORM_INFORMATION_FAILED',
        messageToUser: json.message,
      });
    } else {
      throw new Error('Unrecognized JSON response from backend');
    }
  } catch (error) {
    yield call(logError, error);
    yield put({
      type: 'FETCH_FORM_INFORMATION_FAILED',
      messageToUser: i18n._(
        t`Skjemaet kunne ikke lastes. Feilen er registrert og vil bli utbedret så snart som mulig.`,
      ),
    });
  }
}

export function* getPreviewInformation(
  api: BackendInterface,
  formId: number,
): Saga<void> {
  yield call(genericGetFormInformation, api.fetchPreview, formId);
}

export function* getFormInformation(
  api: BackendInterface,
  formId: number | string,
): Saga<void> {
  yield call(genericGetFormInformation, api.fetchForm, formId);
}

export function* getInvitationInformation(
  api: BackendInterface,
  invitationId: number,
): Saga<void> {
  yield call(genericGetFormInformation, api.fetchInvitation, invitationId);
}

function getFormFromJson(json: Backend.FormResponse): FormResponse {
  const form: FormResponse = {
    expiredToken: json.form.expiredToken,
    meta: {
      title: json.form.title,
      respondentGroup: json.form.respondentGroup,
      editorsContact: json.form.editorsContact,
      editorsContactAddressAsLink: json.form.editorsContactAddressAsLink,
      postponable: json.form.postponable,
      anonymous: json.form.anonymous,
      open: json.form.open,
      shouldGetRespondentInfoFromPerson:
        json.form.shouldGetRespondentInfoFromPerson,
      containsDirectlyPersonIdentifyingQuestions:
        json.form.containsDirectlyPersonIdentifyingQuestions,
      includeSubmissionInUserReceiptEmail:
        json.form.includeSubmissionInUserReceiptEmail,
      type: json.form.type,
      deliveryDestinationIsTsd:
        json.form.deliveryDestination === 'TSD' ? true : false,
      signingRequired: json.form.signingRequired,
      canDeliverSubmission:
        json.canDeliverSubmission == null ? true : json.canDeliverSubmission,
      showingDeliveredSubmission: !!json.form.delivered,
      authenticationRequired: json.form.authenticationRequired,
      highSecurityEditorContactMessageAsLink:
        json.form.highSecurityEditorContactMessageAsLink,
      tsdProjectName: json.form.tsdProjectName,
      afterDeliveryForwardFormIds: json.form.afterDeliveryForwardFormIds,
    },
    pages: json.form.pages,
    answers: json.form.answers,
    formId: json.form.formId,
    submissionId: json.form.submissionId,
    invitationId: json.form.invitationId,
  };
  return form;
}

export function* handleAuthenticationCheckResponse(
  api: BackendInterface,
): Saga<void> {
  try {
    const { submissionId, invitationId } = yield select(
      state => state.answer.form,
    );
    const json = yield call(
      checkAuthenticationStatus,
      api,
      submissionId,
      invitationId,
    );
    const isAuthenticated = json.isAuthenticated;
    const authenticationRequired = yield select(
      state => state.answer.form.meta.authenticationRequired,
    );
    const authenticated = yield select(state => state.answer.authenticated);

    if (
      authenticationRequired &&
      ((authenticated && !isAuthenticated) ||
        (!authenticated && !isAuthenticated))
    ) {
      yield put({ type: 'REAUTHENTICATION_REQUIRED' });
    } else if (authenticationRequired && !authenticated && isAuthenticated) {
      yield put({ type: 'REAUTHENTICATION_SUCCESSFUL' });
    }
  } catch (e) {
    yield put({ type: 'REAUTHENTICATION_FAILED' });
    yield call(logError, e);
  }
}

export function* handlePingResponseNewApi(api: BackendInterface): Saga<void> {
  try {
    if (getSettings().isRunningAsResourceServer) {
      const csrf = yield call(fetchCsrfToken);
      window.Nettskjema.CSRF_PREVENTION_TOKEN = csrf;
    } else {
      const json = yield call(pingNettskjemaNewApi, api);
      const csrf = json.csrf;
      if (csrf && window.Nettskjema.CSRF_PREVENTION_TOKEN !== csrf) {
        window.Nettskjema.CSRF_PREVENTION_TOKEN = csrf;
      }
    }
  } catch (e) {
    yield call(logError, e);
  }
}

const pingNettskjemaNewApi = (api: BackendInterface) => {
  return api.callPingNettskjemaNewApi().then(response => {
    return response.json();
  });
};

export function* handlePingResponse(api: BackendInterface): Saga<void> {
  try {
    if (getSettings().isRunningAsResourceServer) {
      const csrf = yield call(fetchCsrfToken);
      window.Nettskjema.CSRF_PREVENTION_TOKEN = csrf;
    } else {
      const json = yield call(pingNettskjema, api);
      const csrf = json.NETTSKJEMA_CSRF_PREVENTION;
      if (csrf && window.Nettskjema.CSRF_PREVENTION_TOKEN !== csrf) {
        window.Nettskjema.CSRF_PREVENTION_TOKEN = csrf;
      }
    }
  } catch (e) {
    yield call(logError, e);
  }
}

const pingNettskjema = (api: BackendInterface) => {
  return api.callPingNettskjema().then(response => {
    return response.json();
  });
};

const fetchCsrfToken = () => {
  return fetch('/csrf').then(response => {
    if (response.status === 200) {
      return response.headers.get('X-csrf-token');
    } else {
      return null;
    }
  });
};

const checkAuthenticationStatus = (
  api: BackendInterface,
  submissionId: ?number,
  invitationId: ?number,
) =>
  api
    .authenticationStatus(submissionId, invitationId)
    .then(response => response.json());

export function* reauthenticate(): Saga<void> {
  let type = 'normal';
  const { submissionId, invitationId } = yield select(
    state => state.answer.form,
  );
  if ((submissionId || invitationId) && getSettings().isTokenAuthenticated) {
    type = 'token';
  }
  yield put({
    type: 'SHOW_MODAL',
    modalType: 'reauthentication',
    modalProps: { authenticationType: type },
  });
}

/* 
Denne brukes kun av ny leveringsknapp til TSD API
*/
export function* submitJsonRequested(api: BackendInterface): Saga<void> {
  if (!(yield call(validateForm))) {
    yield call(denyUserRequestDueToValidation);
    return;
  }
  const { invitationId, submissionId } = yield select(
    state => state.answer.form,
  );

  const mode = yield select(state => state.answer.mode);

  yield call(handlePingResponse, api);

  yield call(handleAuthenticationCheckResponse, api);
  const authenticated = yield select(state => state.answer.authenticated);
  if (!authenticated) {
    yield call(reauthenticate);
    return;
  }
  if (invitationId) {
    yield call(
      genericJsonSubmit,
      api.submitInvitationJson,
      api.submitAttachment,
    );
  } else if (mode === 'editSubmission') {
    if (submissionId == null) {
      throw new Error(
        'When the mode is `editSubmission`, the submission ID should be set',
      );
    }

    const { showingDeliveredSubmission } = yield select(
      state => state.answer.form.meta,
    );

    if (showingDeliveredSubmission) {
      // yield call(genericJsonSubmit, api.updateSubmission, submissionId);
    } else {
      // yield call(genericJsonSubmit, api.submitSubmission, submissionId);
    }
  } else {
    yield call(genericJsonSubmit, api.submitFormJson, api.submitAttachment);
  }
}

/* TODO: probably move this */
const formatInput = (
  { questionId, value: inputValue },
  filenameOnly?: boolean,
) => {
  const normalValue = inputValue.given
    ? inputValue.given
    : structure.notProvided.type;
  const dateTimeValue =
    inputValue.givenDate && inputValue.givenTime
      ? `${inputValue.givenDate} ${inputValue.givenTime}`
      : structure.notProvided.type;
  const value =
    inputValue.type === structure.dateTime.type ? dateTimeValue : normalValue;

  const type =
    value === structure.notProvided.type
      ? structure.notProvided.type
      : inputValue.type;

  switch (type) {
    case structure.multipleOptions.type:
      return { type, questionId, ids: value };
    case structure.singleOption.type:
      return { type, questionId, id: value };
    case structure.date.type:
      return { type, questionId, date: value };
    case structure.dateTime.type:
      return { type, questionId, datetime: value };
    case structure.time.type:
      return { type, questionId, time: value };
    case structure.text.type: {
      if (Array.isArray(value)) {
        return { type, questionId, text: `${value[0]}` };
      }
      return { type, questionId, text: value };
    }
    case structure.file.type || structure.storedFile.type:
      if (filenameOnly) {
        //$FlowIgnore
        return { type, questionId, text: value.name };
      }
      return { type, questionId, value };
    case structure.notProvided.type:
      return { type, questionId, value };
    default:
      return { type, questionId, value };
  }
};

/* 
Denne brukes kun av ny levering til TSD-API
*/
function* genericJsonSubmit(
  submitter: SubmitterAnswerJsonType | SubmitterInvitationJsonType,
  attachmentSubmitter: SubmitterAttachment,
): Saga<void> {
  yield put({ type: 'SUBMIT_STARTED' });
  try {
    const { inputs, invitationToken } = yield select(state => state.answer);

    const { formId, invitationId } = yield select(state => state.answer.form);

    const elapsedTime = yield call(getElapsedTime);
    const mappedAnswers = inputs.map(input => formatInput(input));

    const mappedAnswersWithFilenameOnly = inputs.map(input =>
      formatInput(input, true),
    );

    const submission = {
      metadata: {
        elapsedTime,
        retries: mappedAnswersWithFilenameOnly.retries,
      },
      answers: mappedAnswersWithFilenameOnly,
    };

    const response: Response =
      invitationId != null
        ? yield call(
            submitter,
            JSON.stringify(submission),
            invitationId,
            invitationToken,
          )
        : yield call(submitter, JSON.stringify(submission), formId);

    const json: Backend.SubmitResponse = yield call([response, response.json]);

    let noErrors = true;

    if (json.errors != null) {
      yield call(
        handleSubmitError,
        ((json: any): Backend.FailedSubmitResponse).errors,
      );
      noErrors = false;
      return;
    } else {
      if (response.status < 200 || response.status > 299) {
        yield put({
          type: 'SHOW_MODAL',
          modalType: 'retry',
          modalProps: {
            retryType: 'generic',
            newDelivery: true,
            text: response.status,
            message: json.message,
          },
        });
        return;
      }
    }

    const receiptJson: Backend.SuccessfulSubmitResponse = (json: any);

    if (noErrors) {
      yield put({
        type: 'SUBMIT_FINISHED',
        data: receiptJson,
      });
    }

    yield put({
      type: 'HIDE_MODAL',
    });

    // Start attchments
    // Noe tull med typer, derfor de to neste
    const submissionId = receiptJson.submissionId
      ? receiptJson.submissionId
      : -1;
    const attachmentToken = receiptJson.attachmentToken
      ? receiptJson.attachmentToken
      : 'null';

    const mappedAttachmentAnswers = mappedAnswers.filter(
      answer => answer.type === 'FILE',
    );

    for (let i = 0; i < mappedAttachmentAnswers.length; i++) {
      yield put({
        type: 'SUBMIT_ATTACHMENT_STARTED',
        numberOfAttachments: mappedAttachmentAnswers.length,
        currentAttachment: {
          id: mappedAttachmentAnswers[i].questionId,
          filename: mappedAttachmentAnswers[i].value.name,
          status: 'STARTED',
        },
      });

      const attachmentResponse = yield call(
        attachmentSubmitter,
        formId,
        mappedAttachmentAnswers[i].questionId,
        submissionId,
        mappedAttachmentAnswers[i].value,
        attachmentToken,
      );
      if (attachmentResponse.status > 199 && attachmentResponse.status < 300) {
        yield put({
          type: 'SUBMIT_ATTACHMENT_SUCCESS',
        });
      } else if (
        attachmentResponse.status < 200 ||
        attachmentResponse.status > 299
      ) {
        noErrors = false;
        yield put({
          type: 'SUBMIT_ATTACHMENT_FAILED',
          currentAttachment: {
            id: mappedAttachmentAnswers[i].questionId,
            filename: mappedAttachmentAnswers[i].value.name,
            status: 'FAILED',
          },
        });
      }
    }

    /* end attachments */

    if (receiptJson.redirectUrl) {
      yield call(redirectToUrl, receiptJson.redirectUrl);
      return;
    }
  } catch (error) {
    yield call(logError, error);
    yield put({
      type: 'SUBMIT_FORM_ERROR',
      messageToUser: i18n._(
        t`Svaret kunne ikke leveres. Feilen er registrert og vil bli utbedret så snart som mulig.`,
      ),
    });

    if (error.cause) {
      yield put({
        type: 'SHOW_MODAL',
        modalType: 'retry',
        modalProps: {
          retryType: 'generic',
          newDelivery: true,
          text: error.cause,
        },
      });
    } else {
      yield put({
        type: 'SHOW_MODAL',
        modalType: 'submit',
        modalProps: { retryType: 'generic', newDelivery: true },
      });
    }
  }
}

export function* submitRequested(api: BackendInterface): Saga<void> {
  if (!(yield call(validateForm))) {
    yield call(denyUserRequestDueToValidation);
    return;
  }
  const { invitationId, formId, submissionId } = yield select(
    state => state.answer.form,
  );
  const mode = yield select(state => state.answer.mode);
  yield call(handlePingResponse, api);
  yield call(handleAuthenticationCheckResponse, api);
  const authenticated = yield select(state => state.answer.authenticated);
  if (!authenticated) {
    yield call(reauthenticate);
    return;
  }
  if (mode === 'editSubmission') {
    if (submissionId == null) {
      throw new Error(
        'When the mode is `editSubmission`, the submission ID should be set',
      );
    }
    const { showingDeliveredSubmission } = yield select(
      state => state.answer.form.meta,
    );
    if (showingDeliveredSubmission) {
      yield call(genericSubmit, api.updateSubmission, submissionId);
    } else {
      yield call(genericSubmit, api.submitSubmission, submissionId);
    }
  } else if (invitationId) {
    yield call(genericSubmit, api.submitInvitation, invitationId);
  } else {
    yield call(genericSubmit, api.submitForm, formId);
  }
}
function* getElapsedTime() {
  const startTime = yield select(state => state.answer.startTime);
  const elapsedTime = new Date().getTime() - startTime;
  return elapsedTime;
}

function* getElapsedTimeParameter() {
  const startTime = yield select(state => state.answer.startTime);
  const elapsedTime = new Date().getTime() - startTime;
  return `&elapsedTime=${elapsedTime}`;
}

function* genericSubmit(submitter: Submitter, id: number): Saga<void> {
  yield put({ type: 'SUBMIT_STARTED' });

  try {
    const answerState = yield select(state => state.answer);
    const formData = getFormData(answerState.inputs);
    const elapsedTimeParameter = yield call(getElapsedTimeParameter);
    const retriesParameter = `&retries=${answerState.retries}`;
    const response: Response = yield call(
      submitter,
      id,
      formData,
      elapsedTimeParameter,
      retriesParameter,
    );

    const json: Backend.SubmitResponse = yield call([response, response.json]);

    if (json.errors != null) {
      yield call(
        handleSubmitError,
        ((json: any): Backend.FailedSubmitResponse).errors,
      );
      return;
    }

    const receiptJson: Backend.SuccessfulSubmitResponse = (json: any);

    if (receiptJson.redirectUrl) {
      yield call(redirectToUrl, receiptJson.redirectUrl);
      return;
    }

    yield put({
      type: 'SUBMIT_FINISHED',
      data: receiptJson,
    });

    yield put({
      type: 'HIDE_MODAL',
    });
  } catch (error) {
    console.log(error);
    yield call(logError, error);
    yield put({
      type: 'SUBMIT_FORM_ERROR',
      messageToUser: i18n._(
        t`Svaret kunne ikke leveres. Feilen er registrert og vil bli utbedret så snart som mulig.`,
      ),
    });

    yield put({
      type: 'SHOW_MODAL',
      modalType: 'retry',
      modalProps: { retryType: 'submit' },
    });
  }
}

function* handleSubmitError(errors: { [key: string]: string }) {
  yield put({
    type: 'SUBMIT_FAILED',
    payload: {
      errors: Object.keys(errors).map(errorKey => {
        const legacyQuestionIdMatch = /^answersAsMap\[(\d+)\]/.exec(errorKey);
        const apiQuestionIdMatch = /^question-(\d+)/.exec(errorKey);

        if (legacyQuestionIdMatch != null) {
          return {
            questionId: Number(legacyQuestionIdMatch[1]),
            message: errors[errorKey],
          };
        } else if (apiQuestionIdMatch != null) {
          return {
            questionId: Number(apiQuestionIdMatch[1]),
            message: errors[errorKey][0],
          };
        }
      }),
    },
  });
}

export function* postponeForm(api: BackendInterface): Saga<void> {
  const inputs = yield select(state => state.answer.inputs);
  const containsInvalidInput = yield call(hasInvalidInputValues, inputs);
  if (containsInvalidInput) {
    yield put({ type: 'HIDE_MODAL' });
    yield call(denyUserRequestDueToValidation);
    return;
  }
  try {
    yield call(handlePingResponse, api);
    yield call(handleAuthenticationCheckResponse, api);
    const authenticated = yield select(state => state.answer.authenticated);
    if (!authenticated) {
      yield call(reauthenticate);
      return;
    }

    yield put({ type: 'FORM_POSTPONE_STARTED' });
    const answerState = yield select(state => state.answer);
    const formData = getFormData(answerState.inputs);
    const elapsedTimeParameter = yield call(getElapsedTimeParameter);
    const response: Response = yield call(
      api.postponeForm,
      answerState.form.formId,
      answerState.form.submissionId,
      answerState.form.invitationId,
      formData,
      elapsedTimeParameter,
    );

    const json: Backend.SuccessfulSubmitResponse = yield call([
      response,
      response.json,
    ]);

    const receiptJson: Backend.SuccessfulSubmitResponse = (json: any);

    yield put({ type: 'FORM_POSTPONE_FINISHED', data: receiptJson });
    yield put({ type: 'HIDE_MODAL' });
  } catch (e) {
    yield put({ type: 'FORM_POSTPONE_FAILED' });
    yield put({
      type: 'SHOW_MODAL',
      modalType: 'retry',
      modalProps: { retryType: 'postpone' },
    });
  }
}

export function* submitEmail(
  api: BackendInterface,
  action: SendEmailReceipt,
): Saga<void> {
  yield put({ type: 'EMAIL_SUBMIT_STARTED ' });
  const response: Response = yield call(api.submitEmail, action.email);
  const json = yield call([response, response.json]);
  if (json.status === 'success') {
    yield put({ type: 'EMAIL_SUBMIT_FINISHED' });
  } else {
    yield put({ type: 'EMAIL_SUBMIT_FAILED', response: json });
  }
}

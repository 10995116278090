import { call, put } from 'redux-saga/effects';

import { Nettskjema } from '../nettskjema';
import { redirectToNewForm } from './redirect';

export function fetchDataUsingApi(formId: number) {
  return fetch(Nettskjema.getContextPath() + '/api/v2/forms/' + formId, {
    credentials: 'include',
  }).then(response => response.json());
}

function handleResponseErrors(response: Response) {
  if (!response.ok) {
    if (response.status === 422) {
      return response;
    }
    throw Error(
      `${response.status} (${response.statusText}), request to ${response.url}`,
    );
  }
  return response;
}

function performCopyForm(formToCopy: any) {
  return fetch(
    Nettskjema.getContextPath() +
      '/api/v2/forms/?copiedFromForm=' +
      formToCopy.formId,
    {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(formToCopy),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        NETTSKJEMA_CSRF_PREVENTION: Nettskjema.getCsrfPreventionToken(),
      },
    },
  )
    .then(handleResponseErrors)
    .then(response => response.json());
}

export function* copyFormSaga(formattedFormToCopy: any) {
  try {
    const result = yield call(performCopyForm, formattedFormToCopy);

    if (result.statusCode === 422) {
      const values = Object.values(result.errors);
      yield put({
        type: 'COPY_FORM_FAILED',
        data: { errors: values },
      });
    } else {
      yield put({
        type: 'COPY_FORM_SUCCESSFUL',
        data: { id: result.formId },
      });
      yield call(redirectToNewForm, result.formId);
    }
  } catch (e) {
    yield put({
      type: 'COPY_FORM_UNKNOWN_ERROR',
      data: { unknownError: [e.toString()] },
    });
  }
}

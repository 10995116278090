// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, responsive } from 'react-usit-ui';

import { createModal } from '../../../components/modal/Modal';
import getSettings from '../backend/settings';
import { ns } from '../design/colors';

// $FlowIgnore
const { useState } = React;

const style = {
  controls: {
    paddingTop: '20px',
    height: '28px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 50,
    minWidth: 150,
    margin: [0, 12],
  },
  [responsive.media.max640]: {
    controls: {
      display: 'block',
    },
    button: {
      display: 'block',
      width: '100%',
      margin: [0, 0, 20, 0],
    },
  },
};

const customModalStyle = {
  dialog: {
    zIndex: 199,
    padding: '60px',
    maxWidth: '620px',
    minWidth: '350px',
    backgroundColor: '#ffffff',
    boxShadow: '0 10px 40px 0 rgba(204, 204, 204, 0.5)',
    borderRadius: '8px',
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.04,
    marginBottom: '20px',
    color: ns.black,
  },
  body: {
    color: ns.black,
    marginBottom: '20px',
  },
  responsive: {
    dialog: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      border: 'none',
      borderRadius: 0,
      overflow: 'scroll',
    },
  },
};

const normalAuthentication = (
  submissionId: ?number,
  abortAction: () => void,
) => {
  const url = `${window.location.pathname}?reauthenticate${
    submissionId ? `&id=${submissionId}` : ''
  }`;
  window.handleAuthentication = abortAction;
  window.open(url, '', 'width=1020,height=800');
};

const tokenRequest = (dispatch: Object => void) => {
  dispatch({ type: 'REQUEST_TOKEN' });
};

const tokenAuthentication = (dispatch: Object => void, token: string) =>
  dispatch({ type: 'AUTHENTICATE_TOKEN', token: token });

const _ReauthenticationModal = (p: {
  classes: Object,
  modalProps: {
    authenticationType: 'normal' | 'token' | 'tokenValidation' | 'failed',
  },
}) => {
  const CustomModal = createModal();
  const dispatch = useDispatch();
  const abortAction = () => dispatch({ type: 'HIDE_MODAL' });
  const { submissionId, invitationId } = useSelector(
    state => state.answer.form,
  );
  const type = p.modalProps.authenticationType;

  let ModalContent = null;
  if (type === 'normal') {
    ModalContent = (
      <NormalAuthenticationContent
        abortAction={abortAction}
        classes={p.classes}
        submissionId={submissionId}
        dispatch={dispatch}
      />
    );
  } else if (type === 'token') {
    ModalContent = (
      <TokenRequestContent
        abortAction={abortAction}
        classes={p.classes}
        submissionId={submissionId}
        invitationId={invitationId}
        dispatch={dispatch}
      />
    );
  } else if (type === 'tokenValidation') {
    ModalContent = (
      <TokenValidationContent
        abortAction={abortAction}
        classes={p.classes}
        submissionId={submissionId}
        invitationId={invitationId}
        dispatch={dispatch}
      />
    );
  } else if (type === 'failed') {
    ModalContent = (
      <AuthenticationFailedContent
        abortAction={abortAction}
        classes={p.classes}
        dispatch={dispatch}
      />
    );
  }

  return (
    <I18n>
      {({ i18n }) => (
        <CustomModal
          headerContent={i18n._(t`Du må logge inn på nytt`)}
          customModalStyle={customModalStyle}
          abortAction={abortAction}
          mainContent={ModalContent}
        />
      )}
    </I18n>
  );
};

const NormalAuthenticationContent = (p: {
  classes: Object,
  abortAction: () => void,
  submissionId: ?number,
}) => (
  <>
    <p>
      <Trans>
        Du er blitt logget ut. Logg inn på nytt for å fortsette med det du holdt
        på med.
      </Trans>
    </p>
    <div className={p.classes.controls}>
      <Button
        className={p.classes.button}
        onClick={() => {
          normalAuthentication(p.submissionId, p.abortAction);
        }}
        type="primary"
      >
        <Trans>Logg inn</Trans>
      </Button>
      <Button
        className={p.classes.button}
        onClick={p.abortAction}
        type="secondary"
      >
        <Trans>Avbryt</Trans>
      </Button>
    </div>
  </>
);

const TokenRequestContent = (p: {
  classes: Object,
  abortAction: () => void,
  invitationId: ?number,
  submissionId: ?number,
  dispatch: Object => void,
}) => {
  return (
    <>
      <p>
        <Trans>
          Du er blitt logget ut. Logg inn på nytt for å fortsette med det du
          holdt på med.
        </Trans>{' '}
        <Trans>
          Trykk på knappen under for å få tilsendt engangskode på e-post.
        </Trans>
      </p>
      <div className={p.classes.controls}>
        <Button
          className={p.classes.button}
          type="primary"
          onClick={() => {
            tokenRequest(p.dispatch);
          }}
        >
          <Trans>Send engangskode</Trans>
        </Button>
        <Button
          className={p.classes.button}
          type="secondary"
          onClick={p.abortAction}
        >
          <Trans>Avbryt</Trans>
        </Button>
      </div>
    </>
  );
};

const TokenValidationContent = (p: {
  classes: Object,
  abortAction: () => void,
  invitationId: ?number,
  submissionId: ?number,
  dispatch: Object => void,
}) => {
  const [token, setToken] = useState('');
  return (
    <>
      <p>
        <Trans>
          Du skal ha fått tilsendt engangskode på e-post. Vennligst fyll dette
          inn i feltet under og trykk «Logg inn»
        </Trans>
      </p>
      <Input onChange={e => setToken(e.target.value)} />
      <div className={p.classes.controls}>
        <Button
          className={p.classes.button}
          type="primary"
          onClick={() => {
            tokenAuthentication(p.dispatch, token);
          }}
        >
          <Trans>Logg inn</Trans>
        </Button>
        <Button
          className={p.classes.button}
          type="secondary"
          onClick={p.abortAction}
        >
          <Trans>Avbryt</Trans>
        </Button>
      </div>
    </>
  );
};

const AuthenticationFailedContent = (p: {
  classes: Object,
  abortAction: () => void,
  dispatch: Object => void,
}) => {
  const authenticationType = getSettings().isTokenAuthenticated
    ? 'token'
    : 'normal';
  return (
    <>
      <p>
        <Trans>Autentisering feilet. Ønsker du å prøve igjen?</Trans>
      </p>
      <div className={p.classes.controls}>
        <Button
          className={p.classes.button}
          type="primary"
          onClick={() =>
            p.dispatch({
              type: 'SHOW_MODAL',
              modalType: 'reauthentication',
              modalProps: { authenticationType: authenticationType },
            })
          }
        >
          <Trans>Prøv igjen</Trans>
        </Button>
        <Button
          className={p.classes.button}
          type="secondary"
          onClick={p.abortAction}
        >
          <Trans>Avbryt</Trans>
        </Button>
      </div>
    </>
  );
};

export const ReauthenticationModal = injectSheet(style)(_ReauthenticationModal);

// @flow

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
import { Button } from 'react-usit-ui';

import { downloadAttachmentUrl } from '../backend/interface';
import getSettings from '../backend/settings';
import * as colors from '../design/colors';
import {
  focusClassNameFromStringId,
  focusFrameInsetButton,
} from '../design/focusFrame';
import FontAwesomeIcon from '../design/icons';

const style = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& input': {
      position: 'absolute',
      left: -9999,
    },
  },
  fileUploadButton: {
    zIndex: 1,
    minWidth: '96px',
    borderColor: colors.primary.dark,
  },
  fileNameBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    height: 45,
    backgroundColor: colors.primary.light,
    border: `solid 2px ${colors.primary.lighter}`,
    borderRadius: 4,
    marginLeft: -5,
  },
  fileName: {
    marginRight: 48,
  },
  deselectFile: {
    width: '41px',
    fontSize: 16,
    paddingRight: 8,
    color: colors.primary.dark,
    border: 'none',
    borderRadius: '0 4px 4px 0',
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
    ...focusFrameInsetButton,
    backgroundColor: 'transparent',
  },
  sizeLimit: {
    marginTop: 8,
    fontSize: 16,
    color: colors.ns.black,
  },
  hidden: { fontSize: 0, height: 1, overflow: 'hidden', display: 'block' },
  imagePreviewContainer: {
    maxWidth: 250,
    maxHeight: 250,
  },
  imagePreview: {
    maxWidth: 250,
    maxHeight: 250,
    borderRadius: 4,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
  },
};

type Classes = { [$Keys<typeof style>]: string };

const Container = injectSheet(style)(({ classes, children }) => (
  <div className={classes.root}>{children}</div>
));

const FileNameBox = injectSheet(style)(
  ({ classes, fileUrl, onDeselectFile, name }) => (
    <div className={classes.fileNameBox}>
      <span className={classes.fileName}>
        {fileUrl == null ? (
          name || <Trans>Ingen fil valgt</Trans>
        ) : (
          <a href={fileUrl} download>
            {name}
          </a>
        )}
      </span>
      {onDeselectFile != null && (
        <button
          className={classes.deselectFile}
          type="button"
          onClick={onDeselectFile}
        >
          <FontAwesomeIcon icon={'times'} />
          <span className={classes.hidden}>
            <Trans>Slett</Trans>
          </span>
        </button>
      )}
    </div>
  ),
);

const getCanPreviewMime = (fileType: string) =>
  ['image/jpeg', 'image/png', 'image/gif'].includes(fileType);

const getCanPreviewExtension = (fileName: string) =>
  ['.jpg', '.jpeg', '.png', '.gif'].some(extension =>
    fileName.endsWith(extension),
  );

const PreviewBox = injectSheet(style)(({ classes, previewURL }) => (
  <I18n>
    {({ i18n }) => (
      <div className={classes.imagePreviewContainer}>
        <img
          src={previewURL}
          alt={i18n._(t`Forhåndsvisning av opplastet bilde`)}
          className={classes.imagePreview}
        />
      </div>
    )}
  </I18n>
));

const SizeLimit = injectSheet(style)(({ classes }) => {
  const maxFileSize = getSettings().MAX_UPLOAD_FILE_SIZE_MB;
  return (
    <div className={classes.sizeLimit}>
      <Trans>Maks {maxFileSize} MB</Trans>
    </div>
  );
});

const _FileUpload = (p: {
  id: string,
  selectedFileName: ?string,
  onSelectedFile: (file: ?File) => void,
  mandatory: boolean,
  disabled?: boolean,
  attachmentId?: number,
  classes: Classes,
  selectedFile: ?File,
}) => {
  // $FlowIgnore
  const inputRef = React.useRef();
  // $FlowIgnore
  const [previewURL, setPreviewURL] = React.useState(null);

  const revokePreviousPreview = () => {
    if (previewURL) {
      URL.revokeObjectURL(previewURL);
      setPreviewURL(null);
    }
  };

  // $FlowIgnore
  React.useEffect(() => {
    revokePreviousPreview();

    if (p.selectedFile && getCanPreviewMime(p.selectedFile.type)) {
      setPreviewURL(URL.createObjectURL(p.selectedFile));
    } else if (
      p.attachmentId &&
      p.selectedFileName &&
      getCanPreviewExtension(p.selectedFileName)
    ) {
      setPreviewURL(downloadAttachmentUrl(p.attachmentId));
    }

    return () => revokePreviousPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.selectedFile, p.attachmentId, p.selectedFileName]);

  return (
    <div>
      <Container>
        {previewURL && <PreviewBox previewURL={previewURL} />}
        <div>
          <div className={p.classes.inputContainer}>
            <input
              aria-required={p.mandatory.toString()}
              ref={inputRef}
              id={p.id}
              type="file"
              tabIndex={-1}
              disabled={p.disabled}
              onChange={event => {
                const file = event.target.files[0];
                p.onSelectedFile(file);
              }}
            />
            {!p.disabled && (
              <Button
                type="secondary"
                className={classNames(
                  p.classes.fileUploadButton,
                  focusClassNameFromStringId(p.id),
                )}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.click();
                  } else {
                    throw new Error(
                      'Input element ref was null when button clicked. This should not be possible.',
                    );
                  }
                }}
              >
                <Trans>Velg fil</Trans>
              </Button>
            )}
            {(p.selectedFileName || p.disabled) && (
              <FileNameBox
                name={p.selectedFileName}
                fileUrl={
                  p.attachmentId != null
                    ? downloadAttachmentUrl(p.attachmentId)
                    : null
                }
                onDeselectFile={
                  p.disabled
                    ? null
                    : event => {
                        event.preventDefault();
                        if (inputRef.current == null) {
                          throw new Error('Input ref not set');
                        }
                        inputRef.current.value = '';
                        p.onSelectedFile(null);
                      }
                }
              />
            )}
          </div>
          {!p.disabled && <SizeLimit />}
        </div>
      </Container>
    </div>
  );
};

export const FileUpload = injectSheet(style)(_FileUpload);

export default FileUpload;

// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';

import * as Backend from '../../backend/legacyJsonBackend';
import * as colors from '../../design/colors';
import { makeFocusClassName } from '../../design/focusFrame';
import type {
  FormInput,
  MultipleOptionsInput,
  SingleOptionInput,
  UnprovidedInput,
} from '../../inputs/types';
import { ValidationErrors } from '../../submission-validation/ValidationErrorUI';
import * as descriptives from '../descriptives';
import { QuestionHeading, questionIdLabel } from '../labels';
import * as ScreenReaderDescription from '../ScreenReaderDescription';
import {
  a11yLabel,
  checkboxMatrixOptionProps,
  createIsIdOfFirstOption,
  inputMatchingQuestion,
  makeSelectElement,
  radioMatrixOptionProps,
  validationErrorsForQuestion,
} from './matrix';
import {
  OptionElementContainer,
  StyledInputElement,
  answerIdString,
  style as multipleOptionsStyle,
} from './multipleOptions';
import { getOnKeyDown } from './RadioButtons';

const style = {
  questionHeading: {
    color: colors.ns.black,
    fontSize: 18,
    fontWeight: 'normal',
    marginBottom: 13,
    marginTop: 10,
  },
  question: {
    borderBottom: `2px solid ${colors.ns.greyLight}`,
    paddingBottom: 20,
    marginBottom: 20,
  },
  lastQuestion: {
    borderBottom: 'none',
  },
};
type Classes = { [$Keys<typeof style>]: string };

export const CheckboxMatrix = (p: {
  title?: string,
  description?: string,
  answerOptions: Backend.AnswerOption[],
  inputs: FormInput[],
  questions: Backend.Question[],
  onInputValue: (questionId: number, value: MultipleOptionsInput) => void,
}) => {
  const { onInputValue, ...matrixProps } = p;
  const renderInput = ({
    questionId,
    input,
    answerOption,
    row,
    column,
    inInvalidQuestion,
  }) => {
    return (
      <MatrixOption
        {...checkboxMatrixOptionProps(
          input,
          answerOption.answerOptionId,
          questionId,
          onInputValue,
        )}
        answerOption={answerOption}
        row={row}
        column={column}
        inInvalidQuestion={inInvalidQuestion}
        focusClass={makeFocusClassName(questionId)}
        focusElement={
          answerOption.answerOptionId === p.answerOptions[0].answerOptionId
        }
      />
    );
  };
  return <StackedMatrix renderInput={renderInput} {...matrixProps} />;
};

export const RadioMatrix = (p: {
  title?: string,
  description?: string,
  answerOptions: Backend.AnswerOption[],
  inputs: FormInput[],
  questions: Backend.Question[],
  onInputValue: (
    questionId: number,
    value: SingleOptionInput | UnprovidedInput,
  ) => void,
}) => {
  const { onInputValue, ...matrixProps } = p;
  const renderInput = ({
    questionId,
    input,
    answerOption,
    row,
    column,
    inInvalidQuestion,
    mandatory,
    onKeyDown,
    isIdOfFirstOption,
  }) => {
    return (
      <MatrixOption
        {...radioMatrixOptionProps(
          input,
          answerOption.answerOptionId,
          questionId,
          onInputValue,
          mandatory,
        )}
        onKeyDown={onKeyDown}
        answerOption={answerOption}
        row={row}
        column={column}
        inInvalidQuestion={inInvalidQuestion}
        isIdOfFirstOption={isIdOfFirstOption}
        focusClass={makeFocusClassName(questionId)}
        focusElement={
          answerOption.answerOptionId === p.answerOptions[0].answerOptionId
        }
      />
    );
  };
  return (
    <StackedMatrix
      renderInput={renderInput}
      rowRole="radiogroup"
      onInputValue={onInputValue}
      {...matrixProps}
    />
  );
};

const StackedMatrix = injectSheet(style)(
  (p: {
    title?: string,
    description?: string,
    answerOptions: Backend.AnswerOption[],
    inputs: FormInput[],
    questions: Backend.Question[],
    rowRole?: string,
    onInputValue?: (
      questionId: number,
      value: SingleOptionInput | UnprovidedInput,
    ) => void,
    renderInput: ({
      questionId: number,
      input: ?FormInput,
      answerOption: Backend.AnswerOption,
    }) => React.Node,
    classes: Classes,
  }) => (
    <div>
      {p.title && <QuestionHeading text={p.title} />}
      {p.description && <descriptives.DescriptionField text={p.description} />}
      {p.questions.map((question, questionIdx) => {
        return (
          <SingleMatrixQuestion
            key={question.questionId}
            questions={p.questions}
            question={question}
            questionIdx={questionIdx}
            onInputValue={p.onInputValue}
            answerOptions={p.answerOptions}
            renderInput={p.renderInput}
            inputs={p.inputs}
            rowRole={p.rowRole}
            classes={p.classes}
          />
        );
      })}
    </div>
  ),
);

// $FlowIgnore Flow 0.78 mangler type React.memo
const SingleMatrixQuestion = React.memo(
  (p: {
    questions: Backend.Question[],
    question: Backend.Question,
    questionIdx: number,
    inputs: FormInput[],
    onInputValue?: (
      questionId: number,
      value: SingleOptionInput | UnprovidedInput,
    ) => void,
    renderInput: ({
      questionId: number,
      input: ?FormInput,
      answerOption: Backend.AnswerOption,
    }) => React.Node,
    answerOptions: Backend.AnswerOption[],
    rowRole: string,
    classes: Classes,
  }) => {
    const { question, questionIdx } = p;
    const validationErrors = validationErrorsForQuestion(question, p.inputs);
    const isIdOfFirstOption = createIsIdOfFirstOption(
      p.inputs,
      p.answerOptions,
      question,
    );
    const onKeyDown =
      p.rowRole === 'radiogroup' && p.onInputValue
        ? getOnKeyDown(
            p.answerOptions,
            makeSelectElement(question.questionId, p.onInputValue),
            `question-${question.questionId}-answer-`,
          )
        : undefined;
    const invalid = validationErrors.length > 0;
    return (
      <div
        className={classNames(
          [p.classes.question],
          questionIdx === p.questions.length - 1 && p.classes.lastQuestion,
        )}
        role={p.rowRole}
        key={question.questionId}
        aria-describedby={
          p.rowRole === 'radiogroup' || !question.mandatory
            ? undefined
            : ScreenReaderDescription.descriptionOf(
                question.questionId.toString(),
              )
        }
        aria-required={
          p.rowRole === 'radiogroup' ? question.mandatory.toString() : undefined
        }
      >
        {p.rowRole !== 'radiogroup' && question.mandatory && (
          <ScreenReaderDescription.InvisibleComponent
            forElementId={question.questionId.toString()}
          >
            <Trans>Du må velge minst ett svaralternativ i denne raden.</Trans>
          </ScreenReaderDescription.InvisibleComponent>
        )}
        <div>
          <QuestionHeading
            id={questionIdLabel(question.questionId)}
            text={question.text}
            small
            mandatory={question.mandatory}
          />
          {invalid && (
            <ValidationErrors
              errors={validationErrors}
              formElementId={question.questionId.toString()}
            />
          )}
          {p.answerOptions.map(option => (
            <div key={option.answerOptionId}>
              {p.renderInput({
                questionId: question.questionId,
                input: inputMatchingQuestion(p.inputs, question),
                answerOption: option,
                row: question.text,
                column: option.text,
                inInvalidQuestion: invalid,
                mandatory: question.mandatory,
                onKeyDown,
                isIdOfFirstOption,
              })}
            </div>
          ))}
        </div>
      </div>
    );
  },
  (prev, next) => {
    let index = null;
    prev.inputs.some((input, idx) => {
      if (input.questionId === prev.question.questionId) {
        index = idx;
        return true;
      }
    });
    return prev.inputs[index] === next.inputs[index];
  },
);
SingleMatrixQuestion.displayName = 'SingleMatrixQuestion';

const MatrixOption = (p: {
  checked: boolean,
  type: 'checkbox' | 'radio',
  onSelectElement: () => void,
  id: string,
  answerOption: Backend.AnswerOption,
  row: string,
  column: string,
  inInvalidQuestion: boolean,
  onKeyDown?: (SyntheticKeyboardEvent<HTMLElement>) => void,
  isIdOfFirstOption?: string => boolean,
  focusElement?: boolean,
  focusClass?: string,
}) => (
  <StackedMatrixOptionElement
    answerOption={p.answerOption}
    checked={p.checked}
    type={p.type}
    id={p.id}
    onSelect={p.onSelectElement}
    onChange={p.onSelectElement}
    ariaLabel={a11yLabel(p.row, p.column)}
    inInvalidQuestion={p.inInvalidQuestion}
    onKeyDown={p.onKeyDown}
    first={p.isIdOfFirstOption && p.isIdOfFirstOption(p.id)}
    focusClass={p.focusClass}
    focusElement={p.focusElement}
  />
);

const StackedMatrixOptionElement = injectSheet(multipleOptionsStyle)(
  (p: {
    answerOption: Backend.AnswerOption,
    checked: boolean,
    onSelect: () => void,
    onChange: (event: SyntheticInputEvent<HTMLElement>) => void,
    ariaLabel?: string,
    type: 'radio' | 'checkbox',
    inInvalidQuestion?: boolean,
    horizontal: boolean,
    id?: string,
    classes: { [$Keys<typeof multipleOptionsStyle>]: string },
    onKeyDown?: (SyntheticKeyboardEvent<HTMLElement>) => void,
    first?: boolean,
    focusElement?: boolean,
    focusClass?: string,
  }) => {
    const { answerOptionId: id, text } = p.answerOption;
    return (
      <OptionElementContainer
        horizontal={p.horizontal}
        checked={p.checked}
        onSelect={p.onSelect}
        type={p.type}
        hasImage={false}
        inInvalidQuestion={p.inInvalidQuestion}
        noBackground
      >
        <div className={p.classes.optionLabel} htmlFor={answerIdString(id)}>
          {/* eslint-disable jsx-a11y/no-static-element-interactions,
          jsx-a11y/no-noninteractive-tabindex */}
          <div
            className={classNames([
              p.classes.separateOptionInput,
              p.checked && p.classes.selectedOption,
              p.checked && p.classes.separateOptionInputSelected,
              p.inInvalidQuestion && p.classes.separateOptionInputInvalid,
              p.inInvalidQuestion && p.classes.invalidOption,
              p.focusElement && p.focusClass,
            ])}
            id={p.id}
            tabIndex={
              p.first ? 0 : !p.checked && p.type !== 'checkbox' ? -1 : 0
            }
            onKeyDown={p.onKeyDown}
          >
            <StyledInputElement
              type={p.type}
              checked={p.checked}
              id={p.id}
              inInvalidQuestion={p.inInvalidQuestion}
              ariaLabel={p.ariaLabel}
              onClick={p.onSelect}
            />
          </div>
          {/* eslint-disable jsx-a11y/no-static-element-interactions,
          jsx-a11y/no-noninteractive-tabindex */}
          {/* eslint-disable-next-line */}
          <label
            htmlFor={p.id}
            className={classNames(p.classes.separateOptionText)}
          >
            {ReactHtmlParser(text)}
          </label>
        </div>
      </OptionElementContainer>
    );
  },
);

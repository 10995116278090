// @flow

import type {
  Element,
  ElementType,
  Question,
} from '../backend/legacyJsonBackend';
import type { InputValue } from '../inputs/types';
import { isEmpty, structure } from '../inputs/types';
import validCheckboxes from './validateCheckboxes';
import {
  validateDate,
  validateDateTime,
  validateTime,
} from './validateDateTime';
import validEmail from './validateEmail';
import validateFile from './validateFile';
import validateNumber from './validateNumber';
import validateNationalIdNumber, {
  validateNorwegianIDNumber,
} from './validatePersonId';
import validatePhone from './validatePhone';
import validRadioSelection from './validateRadioSelection';
import { validateMaxNumberOfCharacters } from './validateText';
import ValidationSequence from './ValidationSequence';
import type { ValidationSequenceType } from './ValidationSequence';

function getErrorKeyForMandatoryQuestion(element: Element) {
  switch (element.elementType) {
    case 'NATIONAL_ID_NUMBER':
      if (element.nationalIdNumberType === 'NORWEGIAN_ID_NUMBER') {
        return 'mandatory_norwegian_id_number';
      } else if (element.nationalIdNumberType === 'ONLY_NUMBERS') {
        return 'mandatory_only_numbers';
      } else {
        return 'mandatory_custom_personal_id';
      }

    case 'NAME':
      return 'mandatory_name';
    case 'USERNAME':
      return 'mandatory_username';
    case 'EMAIL':
      return 'mandatory_email';
    case 'PHONE':
      return 'mandatory_phone';
    case 'ATTACHMENT':
      return 'mandatory_attachment';
    case 'DATE':
      if (element.dateFormat === 'DATE') {
        return 'mandatory_date';
      }
      if (element.dateFormat === 'TIME') {
        return 'mandatory_time';
      } else {
        return 'mandatory_date_time';
      }
    case 'NUMBER':
      return 'mandatory_number';
    case 'LINEAR_SCALE':
      return 'mandatory_linear_scale';
    case 'RADIO':
      return 'mandatory_radio_select';
    case 'SELECT':
      return 'mandatory_radio_select';
    case 'CHECKBOX':
      return 'mandatory_checkbox';
    case 'MATRIX_RADIO':
      return 'mandatory_row';
    case 'MATRIX_CHECKBOX':
      return 'mandatory_row';
    default:
      return 'mandatory_question';
  }
}

const fieldValidation = (
  value: InputValue,
  element: Element,
  question: Question,
) =>
  ValidationSequence()
    .when(question.mandatory, {
      alsoPerform: () =>
        ValidationSequence().when(isEmpty(value), {
          includeError: getErrorKeyForMandatoryQuestion(element),
        }),
    })
    .when(!question.mandatory, {
      alsoPerform: () =>
        ValidationSequence().when(isEmpty(value), {
          alwaysPass: true,
        }),
    })
    .when(!notProvided(value), {
      alsoPerform: () =>
        specificValidationSequenceFor(value, element, question),
    });

function specificValidationSequenceFor(
  value,
  element,
  question,
): ValidationSequenceType {
  const { elementType } = element;
  if (textInput(value)) {
    return validateTextField(value, element, question);
  }
  if (multipleOptions(value, elementType)) {
    return validCheckboxes(value.given, {
      maxNumberOfSelected: element.maxSelectedAnswerOptions,
    });
  }
  if (singleOption(value, elementType)) {
    return validRadioSelection(value.given);
  }
  if (attachment(value, elementType)) {
    return validateFile(value.given);
  }
  if (storedAttachment(value, elementType)) {
    return ValidationSequence();
  }
  if (date(value, elementType)) {
    return validateDate(value.given);
  }
  if (dateTime(value, elementType)) {
    return validateDateTime(value.givenDate, value.givenTime);
  }
  if (time(value, elementType)) {
    return validateTime(value.given);
  }
  throw new MissingValidator(element.elementType, value);
}

function validateTextField(value, element, question): ValidationSequenceType {
  const type = element.elementType;
  if (
    ['QUESTION', 'QUESTION_MULTILINE'].includes(type) &&
    !element.validationScript &&
    typeof element.maxNumberOfCharacters === 'number'
  ) {
    return validateMaxNumberOfCharacters(value, element);
  }
  if (
    ['QUESTION', 'QUESTION_MULTILINE', 'USERNAME', 'NAME'].includes(type) &&
    !element.validationScript
  ) {
    return ValidationSequence();
  }
  if (type === 'NATIONAL_ID_NUMBER') {
    return validateNationalIdNumber(value, element);
  }
  if (type === 'IDPORTEN_NATIONAL_ID') {
    return validateNorwegianIDNumber(value.given);
  }
  if (type === 'EMAIL') {
    return validEmail(value.given);
  }
  if (type === 'PHONE') {
    return validatePhone(value.given);
  }
  if (type === 'NUMBER') {
    const { minimumValue, maximumValue, numberOfDecimals } = question;
    return validateNumber(value.given, {
      minimumValue,
      maximumValue,
      numberOfDecimals,
    });
  }
  if (type === 'LINEAR_SCALE') {
    const { minimumValue, maximumValue, numberOfDecimals } = question;
    return validateNumber(value.given, {
      minimumValue,
      maximumValue,
      numberOfDecimals,
    });
  }
  if (type === 'SUBMISSION_REFERENCE') {
    return ValidationSequence();
  }
  if (element.validationScript) {
    // eslint-disable-next-line
    class ValidationError extends Error {}
    let error = '';
    const script = element.validationScript;
    // eslint-disable-next-line
    const valid = value => {
      try {
        eval(`function validate() {${script}}; validate();`);
        return true;
      } catch (exception) {
        error = exception.message;
        return false;
      }
    };
    return ValidationSequence().when(!valid(value.given.toString()), {
      customError: error,
    });
  }
  throw new MissingValidator(element.elementType, value);
}

class MissingValidator extends Error {
  constructor(elementType: ElementType, value: InputValue) {
    super(
      `Unhandled combination of element type ${elementType} and value type ${value.type}`,
    );
  }
}

function multipleOptions(value: InputValue, elementType: ElementType): %checks {
  return (
    value.type === structure.multipleOptions.type &&
    ['CHECKBOX', 'MATRIX_CHECKBOX'].includes(elementType)
  );
}

function singleOption(value: InputValue, elementType: ElementType): %checks {
  return (
    value.type === structure.singleOption.type &&
    ['RADIO', 'MATRIX_RADIO', 'SELECT'].includes(elementType)
  );
}

function textInput(value: InputValue): %checks {
  return value.type === structure.text.type;
}

function attachment(value: InputValue, elementType: ElementType): %checks {
  return value.type === structure.file.type && elementType === 'ATTACHMENT';
}

function storedAttachment(
  value: InputValue,
  elementType: ElementType,
): %checks {
  return (
    value.type === structure.storedFile.type && elementType === 'ATTACHMENT'
  );
}

function date(value: InputValue, elementType: ElementType): %checks {
  return value.type === structure.date.type && elementType === 'DATE';
}

function dateTime(value: InputValue, elementType: ElementType): %checks {
  return value.type === structure.dateTime.type && elementType === 'DATE';
}

function time(value: InputValue, elementType: ElementType): %checks {
  return value.type === structure.time.type && elementType === 'DATE';
}

function notProvided(value): %checks {
  return value.type === structure.notProvided.type;
}

export default fieldValidation;

// @flow

import ValidationSequence from './ValidationSequence';

const numberValidation = (
  value: string,
  args: {
    minimumValue?: ?number,
    maximumValue?: ?number,
    numberOfDecimals?: ?number,
  },
) =>
  ValidationSequence()
    .when(!wellFormedNumber(value), {
      includeError: 'invalid_number',
    })
    .when(args.numberOfDecimals === 0 && containsDecimalChar(value), {
      includeError: 'integers_only',
    })
    .when(
      args.numberOfDecimals != null &&
        args.numberOfDecimals !== 0 &&
        tooManyDecimals(value, args.numberOfDecimals),
      {
        includeError: [
          'decimals_allowed',
          ({ max: args.numberOfDecimals }: $FlowIgnore),
        ],
      },
    )
    .when(
      args.minimumValue != null &&
        args.maximumValue != null &&
        numberNotInRange(value, args.minimumValue, args.maximumValue),
      {
        includeError: [
          'number_between',
          ({ min: args.minimumValue, max: args.maximumValue }: $FlowIgnore),
        ],
      },
    )
    .when(
      args.minimumValue == null &&
        args.maximumValue != null &&
        numberGreaterThan(value, args.maximumValue),
      {
        includeError: [
          'number_not_greater_than',
          ({ max: args.maximumValue }: $FlowIgnore),
        ],
      },
    )
    .when(
      args.minimumValue != null &&
        args.maximumValue == null &&
        numberLessThan(value, args.minimumValue),
      {
        includeError: [
          'number_not_less_than',
          ({ min: args.minimumValue }: $FlowIgnore),
        ],
      },
    );

const containsDecimalChar = value => value.includes(',') || value.includes('.');

const getDecimals = value => value.split(/[.,]/)[1];

const wellFormedNumber = value => /^-?\d+([.,]\d+)?$/.test(value);

const hasDecimals = value => getDecimals(value) != null;

const tooManyDecimals = (value, limit) =>
  hasDecimals(value) && getDecimals(value).length > limit;

const numberNotInRange = (value, min, max) =>
  compareNumbersExact(value, min) === -1 ||
  compareNumbersExact(value, max) === +1;

const numberGreaterThan = (value, limit) =>
  compareNumbersExact(value, limit) === +1;
const numberLessThan = (value, limit) =>
  compareNumbersExact(value, limit) === -1;

// Compare large decimal numbers without using JavaScript's limited precision numbers
function compareNumbersExact(a, b) {
  // a == b returns  0
  // a > b  returns +1
  // a < b  returns -1

  a = a.toString();
  b = b.toString();

  var adecimals = a.split(/[.,]/)[1];
  var bdecimals = b.split(/[.,]/)[1];

  // remove decimals, leading zeros, and unary plus
  var re = /^\+|^0+(?!$)|[.,]\d+$/g;
  a = a.replace(re, '');
  b = b.replace(re, '');

  // Add one 0 if a and/or b were 0 at first
  if (a.length === 0) a = '0';
  if (b.length === 0) b = '0';

  var aneg = a[0] === '-',
    bneg = b[0] === '-';

  // check for opposite signs
  if (aneg && !bneg) return -1;
  if (bneg && !aneg) return +1;

  // remove sign to simplify later
  if (aneg) a = a.substring(1);
  if (bneg) b = b.substring(1);

  var al = a.length,
    bl = b.length;

  // check for different lengths
  if (al > bl) return aneg ? -1 : +1;
  if (bl > al) return aneg ? +1 : -1;

  // check each digit from left to right
  for (var i = 0; i < al; i++) {
    var ad = a[i],
      bd = b[i];

    if (ad > bd) return aneg ? -1 : +1;
    if (bd > ad) return aneg ? +1 : -1;
  }

  // Decimal check if the two numbers are equal before the decimal character
  if (adecimals) {
    adecimals = adecimals.replace(/0+$/g, '');
  }

  if (bdecimals) {
    bdecimals = bdecimals.replace(/0+$/g, '');
  }

  if (adecimals && bdecimals) {
    for (var j = 0; j < adecimals.length; j++) {
      var adec = adecimals[j],
        bdec = bdecimals[j];
      if (!bdec) {
        bdec = '0';
      }

      var diff = parseInt(bdec, 10) - parseInt(adec, 10);

      if (diff > 0) return aneg ? +1 : -1;
      if (diff < 0) return aneg ? -1 : +1;
    }

    if (bdecimals.length > adecimals.length) {
      return aneg ? +1 : -1;
    }
  } else if (adecimals) {
    return aneg ? -1 : +1;
  } else if (bdecimals) {
    return aneg ? +1 : -1;
  }

  // same sign, same length, every digit is the same
  return 0;
}

export default numberValidation;

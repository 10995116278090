// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
// $FlowIgnore
import { useState } from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { responsive } from 'react-usit-ui';

import { ns, warningMessage } from '../design/colors/index';
import { underlineButtonLink } from '../design/focusFrame';
import alert from './alert-red.svg';
import collapse from './collapse-red.svg';
import expand from './expand-red.svg';

const style = {
  warningMessageWrapper: {
    backgroundColor: warningMessage.background,
    color: ns.black,
    border: '2px solid',
    borderColor: warningMessage.border,
    borderRadius: 4,
    padding: [20, 25],
    marginBottom: 40,
    lineHeight: '28px',

    '&.showAtBottom': {
      marginBottom: 0,
      marginTop: 10,
    },
  },
  warningMessageContainer: {
    background: `url(${alert}) 0 0 no-repeat`,
    backgroundSize: 'initial',
    margin: 'auto',
    maxWidth: 970,
    paddingLeft: 50,
  },
  warningMessageShort: {
    paddingTop: 5,
  },
  warningMessageShortText: {
    color: ns.black,
    fontSize: 18,
    marginRight: 5,
  },
  expandCollapse: {
    background: `url(${expand}) no-repeat`,
    backgroundPosition: '100% 50%',
    paddingRight: 15,
    cursor: 'pointer',
    color: warningMessage.link,
    border: 'none',
    fontSize: 18,
    fontWeight: 'bold',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    lineHeight: '28px',
    ...underlineButtonLink,
  },
  collapse: {
    background: `url(${collapse}) no-repeat`,
    backgroundPosition: '100% 50%',
  },
  warningMessageExpanded: {
    fontSize: 16,
    color: ns.black,
    marginTop: 10,
  },
  warningLink: {
    color: warningMessage.link,
    fontWeight: 'bold',
    textDecoration: 'underline',
    '& a': {
      color: warningMessage.link,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  warningMessageList: {
    listStyleType: 'square',
    marginLeft: 18,
  },
  warningMessageListElement: {
    marginBottom: 10,
  },
  [responsive.media.max640]: {
    warningMessageWrapper: {
      maxWidth: '100%',
      padding: [10, 10],
    },
    warningMessageContainer: {
      background: `url(${alert}) 0 6px no-repeat`,
      backgroundSize: 25,
      paddingLeft: 40,
    },
    warningMessageShortText: {
      fontSize: 16,
      hyphens: 'auto',
      '-webkit-hyphens': 'auto',
      '-ms-hyphens': 'auto',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    expandCollapse: {
      fontSize: 16,
    },
    warningMessageExpanded: {
      fontSize: 15,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const _BrowserNotSupportedWarningContainer = (p: {
  classes: Classes,
  showAtBottom?: boolean,
}) => {
  const [warningExpanded, setWarningExpanded] = useState(false);

  return (
    <div
      className={classNames([
        p.classes.warningMessageWrapper,
        p.showAtBottom && 'showAtBottom',
      ])}
    >
      <div className={p.classes.warningMessageContainer}>
        <div className={p.classes.warningMessageShort}>
          <span className={p.classes.warningMessageShortText}>
            <Trans>
              Det er ikke sikkert din nettleser støtter digital signering.
            </Trans>
          </span>

          <button
            className={classNames([
              p.classes.expandCollapse,
              warningExpanded && p.classes.collapse,
            ])}
            onClick={() => setWarningExpanded(!warningExpanded)}
          >
            {warningExpanded ? <Trans> Lukk</Trans> : <Trans> Vis mer</Trans>}
          </button>
        </div>
        {warningExpanded && (
          <div className={p.classes.warningMessageExpanded}>
            <span>
              <Trans>
                Dette kan du gjøre for å unngå problemer med digital signering:
              </Trans>
            </span>
            <ul className={p.classes.warningMessageList}>
              <li className={p.classes.warningMessageListElement}>
                <Trans>Oppdatere nettleseren din</Trans>
              </li>
              <li className={p.classes.warningMessageListElement}>
                <Trans>Eller bytt til Edge, Chrome eller Firefox</Trans>
              </li>
            </ul>
            <span>
              <Trans>
                Denne advarselen forsvinner når du bytter til en godkjent
                nettleser.
              </Trans>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const BrowserNotSupportedWarningContainer = injectSheet(style)(
  _BrowserNotSupportedWarningContainer,
);

// @flow

import * as React from 'react';

import * as Backend from '../../backend/legacyJsonBackend';
import { makeFocusClassName } from '../../design/focusFrame';
import { MultipleOptions, OptionElement } from './multipleOptions';

const appendUnique = (array, newElement) => [
  ...new Set([...array, newElement]),
];

const Checkboxes = (p: {
  answerOptions: Backend.AnswerOption[],
  horizontal: boolean,
  onInputValue: (value: number[]) => void,
  selectedOptionIds: ?(number[]),
  mandatory: boolean,
  labelledBy: string,
  responsive: boolean,
  invalid?: boolean,
  disabled?: boolean,
  questionId: number,
}) => {
  const {
    answerOptions,
    selectedOptionIds,
    onInputValue,
    horizontal,
    mandatory,
    labelledBy,
    responsive,
  } = p;
  const selected = selectedOptionIds || [];
  return (
    <MultipleOptions
      answerOptions={answerOptions}
      horizontal={horizontal}
      mandatoryMark={{ mandatory, useAriaRequired: false }}
      labelledBy={labelledBy}
      responsive={responsive}
      disabled={p.disabled}
    >
      {answerOptions.map((answerOption, index) => {
        const checked = selected.includes(answerOption.answerOptionId);
        const toggleCheckbox = () => {
          if (!checked) {
            p.onInputValue(appendUnique(selected, answerOption.answerOptionId));
          } else {
            onInputValue(
              selected.filter(opt => opt !== answerOption.answerOptionId),
            );
          }
        };
        return (
          <OptionElement
            key={`optionElement-${answerOption.answerOptionId}`}
            last={index === answerOptions.length - 1}
            focusElement={
              answerOption.answerOptionId === answerOptions[0].answerOptionId
            }
            focusClass={makeFocusClassName(p.questionId)}
            horizontal={p.horizontal}
            checked={checked}
            disabled={p.disabled}
            inInvalidQuestion={p.invalid}
            onSelect={toggleCheckbox}
            answerOption={answerOption}
            type="checkbox"
          />
        );
      })}
    </MultipleOptions>
  );
};

export default Checkboxes;

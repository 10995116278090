//@flow

import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from '../../pages/answer/store';
import type { ModalState } from './reducer';

type ModalProps<T> = {
  modalType: ?T,
  modalProps: Object,
  onKeyDown: (SyntheticKeyboardEvent<HTMLElement>) => void,
};

export const focusableContainerId = 'modal-container';

class _ModalRoot<T> extends React.Component<
  ModalProps<T> & {
    components: { [key: T]: React.ComponentType<ModalProps<T>> },
    disableFocusTrap?: boolean,
  },
  { focusTrapFailed: boolean },
> {
  constructor() {
    super();
    this.state = { focusTrapFailed: false };
  }
  componentDidCatch(error) {
    if (error.message.includes('focus-trap')) {
      this.setState({ focusTrapFailed: true });
    }
  }
  render() {
    const { components, ...modalProps } = this.props;
    if (!modalProps.modalType) return null;
    const ModalComponent = components[modalProps.modalType];
    const modalElement = <ModalComponent {...modalProps} />;
    if (
      this.state.focusTrapFailed ||
      modalProps.modalType === 'deleteApiUser' ||
      this.props.disableFocusTrap
    ) {
      return modalElement;
    } else {
      const trappedElement = (
        <FocusTrap
          focusTrapOptions={{ initialFocus: '#' + focusableContainerId }}
          onKeyDown={modalProps.onKeyDown}
        >
          {modalElement}
        </FocusTrap>
      );
      return trappedElement;
    }
  }
}
export const ModalRoot = connect(
  (state: { modal: ModalState }, ownProps) => ({
    ...state.modal,
    components: ownProps.components,
  }),
  (dispatch: Dispatch) => ({
    onKeyDown: (e: SyntheticKeyboardEvent<HTMLElement>) => {
      if (e.key === 'Escape') {
        dispatch({ type: 'HIDE_MODAL' });
      }
    },
  }),
)(_ModalRoot);

export default ModalRoot;

import { call } from 'redux-saga/effects';

import { copyFormSaga, fetchDataUsingApi } from '../backendInterface';
import { formatCopyFormData } from './backendFormatting';

export function* copyFormWithoutDialog(action: {
  payload: { formId: number };
}) {
  const formToCopy = yield call(fetchDataUsingApi, action.payload.formId);

  const formattedFormToCopy = yield call(formatCopyFormData, {
    formToCopy: formToCopy,
  });

  yield call(copyFormSaga, formattedFormToCopy);
}

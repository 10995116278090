// @flow

import { Trans } from '@lingui/macro';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { Row } from 'react-usit-ui';

import { StaticImage } from '../backend/resources';
import FormTitle from './Title';

const LoadMessage = ({ loadingStarted }) => (
  <Row>
    <Trans>Laster inn skjema</Trans>
    {loadingStarted && (
      <StaticImage
        alt={<Trans>Fremdriftsindikator</Trans>}
        fileName="indicator.gif"
      />
    )}
  </Row>
);

export const PreparingLoad = () => <LoadMessage loadingStarted={false} />;
export const LoadingForm = () => <LoadMessage loadingStarted={true} />;
const LoadingFailedMessage = (p: { message: string }) => (
  <Row>
    <FormTitle />
    {ReactHtmlParser(p.message)}
  </Row>
);

export const Failed = connect(state => ({
  message: state.answer.formLoadingError,
}))(LoadingFailedMessage);

export type FormLoadingStatus = 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'FAILED';

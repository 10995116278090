// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import * as Backend from '../backend/legacyJsonBackend';
import type { FormInput } from '../inputs/types';
import { ConnectedElement } from '../question-elements/FormElement';
import { elementShouldBeHidden } from './hiddenElements';

const AllPages = (p: {
  pages: Backend.Page[],
  inputs: FormInput[],
  disabled?: boolean,
}) =>
  p.pages.map((page, idx) => (
    <React.Fragment key={`form-page-${idx}`}>
      {page.elements.map(
        element =>
          !elementShouldBeHidden(element, p.inputs, p.pages) && (
            <ConnectedElement
              key={element.elementId}
              element={element}
              disabled={p.disabled}
            />
          ),
      )}
    </React.Fragment>
  ));

export const ConnectedAllPages = connect((state, ownProps) => {
  if (state.answer.form == null) {
    throw new Error('Expected form to be loaded when this component is used');
  }
  return {
    pages: state.answer.form.pages,
    inputs: state.answer.inputs,
    ...ownProps,
  };
})(AllPages);

import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { apiDelete, apiGet, apiPost } from '../../../backend/apiBackend';
import {
  ApiFormMetaInformationResponse,
  ApiFormResponse,
  ApiNetgroupResponse,
  ApiPersonResponse,
} from '../../../backend/apiResponseTypes';
import { post } from '../../../backend/oldBackend';
import { copyFormWithoutDialog } from '../../../copy-form/without-dialog/saga';
import { moment } from '../../../locale';
import { Form, Nettskjema } from '../../../nettskjema';
import * as formActions from './actions/formActions';
import * as personActions from './actions/personActions';
import * as uiActions from './actions/uiActions';
import * as modal from './components/modals/actions';
import { getForm } from './selectors';
import selfServiceSaga from './self-service/sagas';
import {
  AnswerOption,
  Element,
  ElementType,
  Elements,
  FieldError,
  FormMetaInformation,
  FormSettings,
  FormSettingsKeys,
  Netgroups,
  Persons,
} from './types';

function* initialPageLoad() {
  yield put(
    formActions.updateFormFromGlobalObject(
      Form.getMetaInformation(),
      Form.getFormSettings(),
    ),
  );
  yield call(getFormSettings, true);
}

/* TODO: merge this or replace with get formsettings */
function* getFormSettingsFromApi() {
  try {
    yield put(formActions.fetchFormSettings(false));
    const formId = Nettskjema.getFormId();
    const canEditForm = !!Nettskjema.canEditForm();

    const endpoint = `/api/v2/forms/${formId}`;
    const result = yield call(apiGet, endpoint);
    const formMetaInformationResult = yield call(
      apiGet,
      endpoint + '/meta-information',
    );

    if (Nettskjema.shouldShowPersonalInfoWarningPopUp()) {
      yield put(modal.show('personalData'));
    }

    const authenticatedInfo = Nettskjema.authenticatedInfo();
    yield put(personActions.updateCurrentPerson(authenticatedInfo));

    const editorsThroughApiUsers = Nettskjema.editorsThroughApiUsers();
    yield put(
      formActions.editorsThroughApiUsers(
        editorsThroughApiUsers ? editorsThroughApiUsers : [],
      ),
    );

    const form = mapFormSettingsFromResult(result);

    const metaInformation = mapMetaInformationFromResult(
      result,
      formMetaInformationResult,
    );

    metaInformation.canEditForm = canEditForm;

    yield put(formActions.fetchFormSettingsSuccess(form, metaInformation));
  } catch (e) {
    yield put(formActions.fetchFormSettingsFailed(e.message));
  }
}

function* getFormSettings(showLoadingIcon: boolean) {
  try {
    yield put(formActions.fetchFormSettings(showLoadingIcon));
    const formId = Nettskjema.getFormId();
    const canEditForm = !!Nettskjema.canEditForm();

    const endpoint = `/api/v2/forms/${formId}`;
    const result = yield call(apiGet, endpoint);

    const isTsd = result.formType === 'HIGH_SECURITY';

    const formMetaInformationResult =
      Form.getMetaInformation().readyForHighSecurityServiceActivation || isTsd
        ? yield call(apiGet, endpoint + '/meta-information')
        : {
            formId: Form.getMetaInformation().formId,
            shouldGetRespondentInfoFromPerson: Form.getMetaInformation()
              .shouldGetRespondentInfoFromPerson,
            modifiedDate: Form.getMetaInformation().modifiedDate,
            modifiedBy: Form.getMetaInformation().modifiedBy,
            readyForHighSecurityServiceActivation: Form.getMetaInformation()
              .readyForHighSecurityServiceActivation,
            codebookValuesComplete: Form.getMetaInformation()
              .codebookValuesComplete,
            hasValidExternalIdsForTsd: Form.getMetaInformation()
              .hasValidExternalIdsForTsd,
            sendingSubmissionInEmailToEditor: Form.getMetaInformation()
              .sendingSubmissionInEmailToEditor,
            hasSubmissions: Form.getMetaInformation().hasSubmissions,
            settingsLocked: Form.getMetaInformation().settingsLocked,
            canEditForm: Form.getMetaInformation().canEditForm,
          };

    if (Nettskjema.shouldShowPersonalInfoWarningPopUp()) {
      yield put(modal.show('personalData'));
    }

    const authenticatedInfo = Nettskjema.authenticatedInfo();
    yield put(personActions.updateCurrentPerson(authenticatedInfo));

    const editorsThroughApiUsers = Nettskjema.editorsThroughApiUsers();
    yield put(
      formActions.editorsThroughApiUsers(
        editorsThroughApiUsers ? editorsThroughApiUsers : [],
      ),
    );

    const form = mapFormSettingsFromResult(result);

    const metaInformation = mapMetaInformationFromResult(
      result,
      formMetaInformationResult,
    );

    metaInformation.canEditForm = canEditForm;

    yield put(formActions.fetchFormSettingsSuccess(form, metaInformation));
  } catch (e) {
    yield put(formActions.fetchFormSettingsFailed(e.message));
  }
}

const mapMetaInformationFromResult = (
  result: ApiFormResponse,
  formMetaInformationResult: ApiFormMetaInformationResponse,
): FormMetaInformation => {
  return {
    formId: result.formId,
    shouldGetRespondentInfoFromPerson: result.shouldGetRespondentInfoFromPerson,
    modifiedDate: result.modifiedDate,
    modifiedBy: result.modifiedBy,
    readyForHighSecurityServiceActivation:
      formMetaInformationResult.readyForHighSecurityServiceActivation,
    codebookValuesComplete: formMetaInformationResult.codebookValuesComplete,
    hasValidExternalIdsForTsd:
      formMetaInformationResult.hasValidExternalIdsForTsd,
    sendingSubmissionInEmailToEditor:
      formMetaInformationResult.sendingSubmissionInEmailToEditor,
    hasSubmissions: formMetaInformationResult.hasSubmissions,
    settingsLocked: formMetaInformationResult.settingsLocked,
    canEditForm: formMetaInformationResult.canEditForm,
    tsdProject: formMetaInformationResult.tsdProject,
  };
};

const mapFormSettingsFromResult = (result: ApiFormResponse): FormSettings => {
  return {
    title: result.title,
    formType: result.formType,
    titleShort: result.titleShort,
    openFrom: result.openFrom ? moment(result.openFrom) : null,
    openTo: result.openTo ? moment(result.openTo) : null,
    editors: convertPersonArrayToObject(result.editors),
    netgroupsEditor: convertNetgroupArrayToObject(result.netgroupsEditor),
    personsWithCopyPermission: convertPersonArrayToObject(
      result.personsWithCopyPermission,
    ),
    netgroupsWithCopyPermission: convertNetgroupArrayToObject(
      result.netgroupsWithCopyPermission,
    ),
    respondentGroup: result.respondentGroup,
    theme: result.theme,
    codebookActivated: result.codebookActivated,
    shouldGetRespondentInfoFromPerson: result.shouldGetRespondentInfoFromPerson,
    modifiedDate: result.modifiedDate,
    modifiedBy: result.modifiedBy,
    shouldPreventDataManipulation: result.shouldPreventDataManipulation,
    elements: convertElementArrayToObject(result.elements),
    open: result.open,
    possibleToHaveInvitations: result.possibleToHaveInvitations,
    markedForDeletionDate: result.markedForDeletionDate,
  };
};

const convertPersonArrayToObject = (personArray?: ApiPersonResponse[]) => {
  return personArray
    ? personArray.reduce((persons: Persons, person: ApiPersonResponse) => {
        persons[person.username] = person;
        return persons;
      }, {})
    : {};
};

const convertNetgroupArrayToObject = (
  netgroupArray?: ApiNetgroupResponse[],
) => {
  return netgroupArray
    ? netgroupArray.reduce(
        (netgroups: Netgroups, netgroup: ApiNetgroupResponse) => {
          netgroups[netgroup.name] = netgroup;
          return netgroups;
        },
        {},
      )
    : {};
};

const convertElementArrayToObject = (elementArray: Element[]) => {
  let pageIndex = 1;
  return elementArray
    ? elementArray.reduce((elements: Elements, element: any) => {
        if (element.elementType === 'PAGE_BREAK') {
          pageIndex++;
        }
        elements[element.sequence] = mapElementFromBackend(element, pageIndex);
        return elements;
      }, {})
    : {};
};

const mapElementFromBackend = (element: any, pageIndex: number): Element => {
  const defaultElement = {
    elementType: element.elementType,
    sequence: element.sequence,
    description: element.description,
    pageIndex: pageIndex,
  };

  if (
    isSingleQuestionElement(element.elementType) ||
    isMultipleQuestionElement(element.elementType)
  ) {
    const question = element.questions[0];
    return {
      ...defaultElement,
      questionId: question.questionId,
      title: question.text,
      externalId: question.externalQuestionId
        ? question.externalQuestionId
        : '',
      description: question.description ? question.description : '',
      answerOptions: question.answerOptions
        ? question.answerOptions.reduce(
            (answerOptions: any, answerOption: any) => {
              answerOptions[answerOption.sequence] = mapAnswerOptionFromBackend(
                answerOption,
              );
              return answerOptions;
            },
            {},
          )
        : {},
    };
  } else if (isMatrixElement(element.elementType)) {
    return {
      ...defaultElement,
      title: element.title ? element.title : '',
      externalId: '',
      answerOptions: element.answerOptions
        ? element.answerOptions.reduce(
            (answerOptions: any, answerOption: any) => {
              answerOptions[answerOption.sequence] = mapAnswerOptionFromBackend(
                answerOption,
              );
              return answerOptions;
            },
            {},
          )
        : {},
      elements: element.questions
        ? element.questions.reduce((elements: Elements, question: any) => {
            elements[question.sequence] = {
              elementType: question.elementType,
              questionId: question.questionId,
              sequence: question.sequence,
              title: question.text,
              externalId: question.externalQuestionId
                ? question.externalQuestionId
                : '',
              description: '',
              pageIndex: pageIndex,
            };
            return elements;
          }, {})
        : {},
    };
  } else {
    return {
      ...defaultElement,
      title: element.title,
      externalId: '',
      answerOptions: element.answerOptions,
    };
  }
};

const mapAnswerOptionFromBackend = (answerOption: any): AnswerOption => {
  return {
    answerOptionId: answerOption.answerOptionId,
    sequence: answerOption.sequence,
    title: answerOption.text,
    externalId: answerOption.externalAnswerOptionId
      ? answerOption.externalAnswerOptionId
      : '',
  };
};

export const isSingleQuestionElement = (elementType: ElementType): boolean => {
  switch (elementType) {
    case 'QUESTION':
    case 'QUESTION_MULTILINE':
    case 'LINEAR_SCALE':
    case 'NATIONAL_ID_NUMBER':
    case 'IDPORTEN_NATIONAL_ID':
    case 'NAME':
    case 'EMAIL':
    case 'USERNAME':
    case 'PHONE':
    case 'ATTACHMENT':
    case 'DATE':
    case 'NUMBER':
    case 'SUBMISSION_REFERENCE':
      return true;
    default:
      return false;
  }
};

export const isMultipleQuestionElement = (
  elementType: ElementType,
): boolean => {
  switch (elementType) {
    case 'RADIO':
    case 'CHECKBOX':
    case 'SELECT':
      return true;
    default:
      return false;
  }
};

export const isMatrixElement = (elementType: ElementType): boolean => {
  switch (elementType) {
    case 'MATRIX_CHECKBOX':
    case 'MATRIX_RADIO':
      return true;
    default:
      return false;
  }
};

export const isQuestionOrMatrixElement = (
  elementType: ElementType,
): boolean => {
  return (
    isSingleQuestionElement(elementType) ||
    isMultipleQuestionElement(elementType) ||
    isMatrixElement(elementType)
  );
};

function* saveFormSettings(action: formActions.SaveFormSettingsActionType) {
  try {
    const form = yield select(getForm);
    const endpoint = `/user/form/postFormSettings.json?id=${form.metaInformation.formId}`;
    const result = yield call(
      post,
      endpoint,
      getFormSettingsAsFormData(form.editedForm, action.data.fields),
    );
    yield call(handleSaveSettingsOldBackend, result);
  } catch (e) {
    yield put(formActions.saveSettingsUnknownError());
    yield put({ type: 'SAVE_SETTINGS_FAILED', data: { error: e.message } });
  }
}

function* savePermissionSettings(
  action: formActions.SaveFormPermissionsSettingsActionType,
) {
  try {
    const form = yield select(getForm);
    const endpoint = `/user/form/permissions.json?id=${form.metaInformation.formId}`;
    const result = yield call(
      post,
      endpoint,
      getPermissionSettingsAsFormData(form.editedForm, action.data.fields),
    );
    yield call(handleSaveSettingsOldBackend, result);
  } catch (e) {
    yield put(formActions.saveSettingsUnknownError());
    yield put({ type: 'SAVE_SETTINGS_FAILED', data: { error: e.message } });
  }
}

const getFormSettingsAsFormData = (
  settings: FormSettings,
  fields: FormSettingsKeys[],
) => {
  const formData = new FormData();

  fields.forEach(value => {
    switch (value) {
      case 'titleShort':
        formData.append(
          'titleShort',
          settings.titleShort ? settings.titleShort : '',
        );
        return;
      default:
        return;
    }
  });

  return formData;
};

const getPermissionSettingsAsFormData = (
  settings: FormSettings,
  fields: FormSettingsKeys[],
) => {
  const formData = new FormData();
  fields.forEach(value => {
    switch (value) {
      case 'title':
        formData.append('title', settings.title);
        return;
      case 'editors':
        formData.append(
          'editors',
          convertObjectKeysToCommaSeparatedString(settings.editors),
        );
        return;
      case 'netgroupsEditor':
        formData.append(
          'netgroupsEditor',
          convertObjectKeysToCommaSeparatedString(settings.netgroupsEditor),
        );
        return;
      case 'personsWithCopyPermission':
        formData.append(
          'personsWithCopyPermission',
          convertObjectKeysToCommaSeparatedString(
            settings.personsWithCopyPermission,
          ),
        );
        return;
      case 'netgroupsWithCopyPermission':
        formData.append(
          'netgroupsWithCopyPermission',
          convertObjectKeysToCommaSeparatedString(
            settings.netgroupsWithCopyPermission,
          ),
        );
        return;
      case 'respondentGroup':
        formData.append('respondentGroup', settings.respondentGroup);
        return;
      default:
        return;
    }
  });

  return formData;
};

const getCodebookAsJson = (elements: Elements) => {
  const externalQuestionIds: { [index: string]: string } = {};
  const externalAnswerOptionIds: { [index: string]: string } = {};

  Object.values(elements).forEach((element: Element) => {
    if (isSingleQuestionElement(element.elementType) && element.questionId) {
      externalQuestionIds[element.questionId] = element.externalId;
    } else if (
      isMultipleQuestionElement(element.elementType) &&
      element.questionId
    ) {
      externalQuestionIds[element.questionId] = element.externalId;
      if (element.answerOptions) {
        Object.values(element.answerOptions).forEach(
          (answerOption: AnswerOption) => {
            externalAnswerOptionIds[answerOption.answerOptionId] =
              answerOption.externalId;
          },
        );
      }
    } else if (isMatrixElement(element.elementType) && element.elements) {
      Object.values(element.elements).forEach((element: Element) => {
        if (element.questionId) {
          externalQuestionIds[element.questionId] = element.externalId;
        }
      });
      if (element.answerOptions) {
        Object.values(element.answerOptions).forEach(
          (answerOption: AnswerOption) => {
            externalAnswerOptionIds[answerOption.answerOptionId] =
              answerOption.externalId;
          },
        );
      }
    }
  });
  return { externalQuestionIds, externalAnswerOptionIds };
};

function* saveCodebookValues() {
  const { elements, formId } = yield select((state: any) => ({
    elements: state.form.editedForm.elements,
    formId: state.form.metaInformation.formId,
  }));
  const json = yield call(getCodebookAsJson, elements);
  const endpoint = `/api/v2/forms/${formId}/codebook`;
  try {
    yield call(apiPost, endpoint, JSON.stringify(json));
    yield put(formActions.saveSettingsSuccess());
    yield put(modal.hide());
    yield put(uiActions.scrollToTop());
    yield put(formActions.showSuccessMessage());
    yield call(getFormSettings, false);
  } catch (errors) {
    if (errors.status === 422) {
      const responseBody = yield errors.json();
      yield put(
        formActions.saveSettingsValidationNestedErrors(
          responseBody.nestedErrors,
        ),
      );
    } else {
      yield put(formActions.saveSettingsUnknownError());
    }
    yield put(uiActions.scrollToTop());
  }
}

const convertObjectKeysToCommaSeparatedString = (setting: any) => {
  return setting ? Object.keys(setting).join(',') : '';
};

function* openForm() {
  try {
    const form = yield select(getForm);

    const formData = new FormData();
    formData.append('id', form.metaInformation.formId);

    const endpoint = `/user/form/open.json`;
    const result = yield call(post, endpoint, formData);

    yield call(handleSaveSettingsOldBackend, result);
  } catch (e) {
    yield put(formActions.saveSettingsUnknownError());
  }
}

function* closeForm() {
  try {
    const form = yield select(getForm);

    const formData = new FormData();
    formData.append('id', form.metaInformation.formId);

    const endpoint = `/user/form/close.json`;
    const result = yield call(post, endpoint, formData);

    yield call(handleSaveSettingsOldBackend, result);
  } catch (e) {
    yield put(formActions.saveSettingsUnknownError());
  }
}

export function* deleteForm(action: formActions.DeleteForm) {
  try {
    const apiEndpoint = '/api/v2/forms/' + action.data.formId;

    const result = yield call(apiDelete, apiEndpoint);

    window.location.href = `${Nettskjema.getContextPath()}/user/form/list.html?deletedFormTitle=${
      result.title
    }`;
  } catch (e) {
    // We throw a response object. It's possible to check the various status codes here.
    yield put(formActions.deleteFormFailed());
    yield put(modal.hide());
  }
}

function* copyForm(action: formActions.CopyFormWithoutDialog) {
  try {
    yield put(uiActions.countAction('copy_form.without_dialog_dropdown'));
    yield call(copyFormWithoutDialog, action);
  } catch {
    yield put(formActions.saveSettingsUnknownError());
    yield put(modal.hide());
  }
}

function* navigateToPage(action: formActions.NavigateToPage) {
  yield put(uiActions.toggleDialog('NONE'));
  yield put(modal.hide());
  window.location.href = Nettskjema.getContextPath() + action.data.page;
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

function* handleSaveSettingsOldBackend(result: any) {
  if (result.status == 'failure') {
    const errors: FieldError[] = Object.keys(result.errors).map(key => ({
      field: key,
      message: result.errors[key],
    }));
    yield put(formActions.saveSettingsValidationFailed(errors));
  } else {
    yield put(formActions.saveSettingsSuccess());
    yield put(modal.hide());
    yield call(getFormSettings, false);
  }
}

function* countDialog(action: modal.show) {
  if (action.modalType === 'copy') {
    yield put(uiActions.countAction('copy_form.open_dialog_dropdown'));
  }
}

function* countAction(action: uiActions.CountAction) {
  const apiEndpoint = '/stats/count';
  try {
    yield call(
      apiPost,
      apiEndpoint,
      JSON.stringify({ [action.data.action]: '1' }),
    );
  } catch (e) {
    // We don't really want to do something if this fails, but it shouldn't crash the app.
  }
}

export function* rootSaga(): SagaIterator {
  yield call(initialPageLoad);
  yield fork(selfServiceSaga);
  yield takeEvery('SAVE_FORM_SETTINGS', saveFormSettings);
  yield takeEvery('SAVE_FORM_PERMISSIONS_SETTINGS', savePermissionSettings);
  yield takeEvery('OPEN_FORM', openForm);
  yield takeEvery('CLOSE_FORM', closeForm);
  yield takeEvery('COPY_FORM_WITHOUT_DIALOG' as any, copyForm);
  yield takeEvery('DELETE_FORM', deleteForm);
  yield takeEvery('NAVIGATE_TO_PAGE', navigateToPage);
  yield takeEvery('SAVE_CODEBOOK', saveCodebookValues);
  yield takeEvery('AUTOFILL_CODEBOOK', saveCodebookValues);
  yield takeEvery('COUNT_ACTION', countAction);
  yield takeEvery('TOGGLE_DIALOG', countDialog);
  yield takeEvery('SHOW_MODAL', countDialog);
  yield takeEvery('SCROLL_TO_TOP', scrollToTop);
  yield takeEvery('RELOAD_FORM_SETTINGS', getFormSettings, false);
  yield takeEvery('RELOAD_FORM_SETTINGS_FROM_API', getFormSettingsFromApi);
}

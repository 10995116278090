import { Credentials } from './types';

export type SelfServiceAction =
  | AddChange
  | LoginSuccessful
  | LoginFailed
  | ActivateForm
  | ActivateFormSuccessful
  | nextPage
  | ActivateFormFailed
  | setRespondentGroupSuccess
  | setRespondentGroupFailed
  | setRespondentGroup;

export type AddChange = {
  type: 'ADD_CHANGE';
  data: { fieldName: string; fieldValue: any };
};

export const addChange = (fieldName: string, fieldValue: any): AddChange => ({
  type: 'ADD_CHANGE',
  data: { fieldName: fieldName, fieldValue: fieldValue },
});

export type TsdLogin = {
  type: 'TSD_LOGIN';
  data: { credentials: Credentials };
};

export type setRespondentGroup = {
  type: 'SELF_SERVICE_SET_RESPONDENT_GROUP';
  data: string;
};

export const setRespondentGroup = (resondentGroup: any) => ({
  type: 'SELF_SERVICE_SET_RESPONDENT_GROUP',
  data: resondentGroup,
});

type setRespondentGroupSuccess = {
  type: 'SET_RESPONDENT_GROUP_SUCCESS';
};
export const setRespondentGroupSuccess = () => ({
  type: 'SET_RESPONDENT_GROUP_SUCCESS',
});

type setRespondentGroupFailed = { type: 'SET_RESPONDENT_GROUP_FAILED' };
export const setRespondentGroupFailed = (error?: string) => ({
  type: 'SET_RESPONDENT_GROUP_FAILED',
  data: { error },
});

export const tsdLogin = (credentials: Credentials): TsdLogin => ({
  type: 'TSD_LOGIN',
  data: { credentials: credentials },
});

export type LoginSuccessful = {
  type: 'LOGIN_SUCCESSFUL';
  data: { jwt: string; userinfo: any };
};

export const loginSuccessful = (
  jwt: string,
  userinfo: any,
): LoginSuccessful => ({
  type: 'LOGIN_SUCCESSFUL',
  data: { jwt: jwt, userinfo: userinfo },
});

export type LoginFailed = {
  type: 'LOGIN_FAILED';
  data: { error: string };
};

export const loginFailed = (error: string): LoginFailed => ({
  type: 'LOGIN_FAILED',
  data: { error: error },
});

export type ActivateForm = {
  type: 'ACTIVATE_FORM';
  data: Credentials;
};

export const activateForm = (credentials: Credentials): ActivateForm => ({
  type: 'ACTIVATE_FORM',
  data: credentials,
});

export type ActivateFormSuccessful = {
  type: 'ACTIVATE_FORM_SUCCESSFUL';
  data: { deliveryDestionation: 'TSD'; tsdProject: string };
};

export const activateFormSuccessful = (data: {
  deliveryDestionation: 'TSD';
  tsdPorject: string;
}) => ({
  type: 'ACTIVATE_FORM_SUCCESSFUL',
  data: data,
});

export type ActivateFormFailed = {
  type: 'ACTIVATE_FORM_FAILED';
  data: { error: string };
};

export const activateFormFailed = (error: string): ActivateFormFailed => ({
  type: 'ACTIVATE_FORM_FAILED',
  data: { error: error },
});

export type nextPage = {
  type: '@@selfService/SET_NEXT_PAGE';
};

export const nextPage = {
  type: '@@selfService/SET_NEXT_PAGE',
};

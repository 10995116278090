// @flow

import { focus, warning } from './colors';

export const focusFrame = {
  outline: 0,
  boxShadow: `0 0 0 4px ${focus.frameBlue}`,
};

export const errorFocusFrame = {
  outline: 0,
  boxShadow: `0 0 0 4px ${warning.dark}`,
};

export const errorFocusMobileMatrix = {
  outline: 0,
  boxShadow: `0 0 0 3px ${warning.dark}`,
  borderColor: warning.lighter,
};

export const focusFrameNoBorder = {
  outline: 0,
  borderColor: 'transparent',
  '-webkit-appearance': 'none',
  boxShadow: `0 0 0 4px ${focus.frameBlue}`,
};

export const errorFocusFrameNoBorder = {
  outline: 0,
  borderColor: 'transparent',
  boxShadow: `0 0 0 4px ${warning.dark}`,
};

export const errorMessageButtonFrame = {
  '&:focus': {
    outline: '1px dotted black',
  },
  '&::-moz-focus-inner': {
    border: 0,
  },
};

export const focusFrameButton = {
  ...focusFrame,
  '&::-moz-focus-inner': {
    border: 0,
  },
};

export const focusFrameInsetButton = {
  '&:focus': {
    boxShadow: `inset 0 0 0 4px ${focus.frameBlue}`,
    borderRadius: '4px',
  },
  '&::-moz-focus-inner': {
    border: 0,
  },
};

export const underlineButtonLink = {
  '&:focus, &:hover': {
    textDecoration: 'underline',
  },
  '&::-moz-focus-inner': {
    border: 0,
  },
};

export const focusFrameDate = {
  '&:focus': {
    outline: 0,
    boxShadow: `inset 0 0 0 4px ${focus.frameBlue}`,
    backgroundColor: '#e0effd',
    color: '#444',
  },
};

export const makeFocusClassName = (questionId: number) =>
  'question-' + questionId + '-focus';

export const focusClassNameFromStringId = (questionIdString: string) =>
  questionIdString + '-focus';

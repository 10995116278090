// @flow

import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import { responsive } from 'react-usit-ui';

import type { ElementType, Question } from '../backend/legacyJsonBackend';
import type { FormInput } from '../inputs/types';
import type { Validation } from '../submission-validation/types';
import { invalid } from '../submission-validation/types';
import { AccessibleInput, AccessibleTextarea } from './accessibleInputs';
import * as labels from './labels.js';

type TextInputProps = {
  id: string,
  onInputValue: (value: string) => void,
  value: string,
  validation: Validation,
  mandatory?: boolean,
  disabled?: boolean,
  maxNumberOfCharacters?: number,
  mutationOnBlur?: ?(string) => string,
  isAnswerHashed?: ?boolean,
  elementType: ElementType,
};

const commonProps = (p: TextInputProps) => ({
  id: p.id,
  defaultValue: p.value,
  onChange: e => {
    if (invalid(p.validation)) {
      p.onInputValue(e.target.value);
    }
  },
  onBlur: e => {
    if (p.mutationOnBlur) {
      e.target.value = p.mutationOnBlur(e.target.value);
    }
    p.onInputValue(e.target.value);
  },
  validation: p.validation,
  formElementId: p.id,
  'aria-required': !!p.mandatory,
  disabled: p.disabled,
  maxNumberOfCharacters: p.maxNumberOfCharacters,
  isAnswerHashed: p.isAnswerHashed,
  elementType: p.elementType,
});

export const TextInput = (props: TextInputProps) => (
  <AccessibleInput type="text" focusElement {...commonProps(props)} />
);

export const RepeatedTextInput = (p: {
  id: string,
  onInputValue: ({ newMain?: string, newRepeated?: string }) => void,
  mainValue: string,
  repeatedValue: string,
  validation: Validation,
  question: Question,
  input: FormInput,
  postHeading?: React.Node,
  isAnswerHashed?: ?boolean,
  elementType: ElementType,
}) => (
  <I18n>
    {({ i18n }) => (
      <div>
        <labels.LabeledQuestion
          description={p.question.description}
          text={p.question.text}
          mandatory={p.question.mandatory}
          htmlFor={p.id}
          validation={p.input.validation}
          postHeading={p.postHeading}
        >
          <TextInput
            id={p.id}
            onInputValue={value => {
              p.onInputValue({ newMain: value });
            }}
            value={p.mainValue}
            validation={p.validation}
            elementType={p.elementType}
          />
        </labels.LabeledQuestion>
        <labels.LabeledQuestion
          text={i18n._(t`Gjenta:`)}
          mandatory={false}
          htmlFor={`${p.id}-repeat`}
          validation={p.input.validation}
          repeatedInput={true}
        >
          <TextInput
            id={`${p.id}-repeat`}
            onInputValue={value => {
              p.onInputValue({ newRepeated: value });
            }}
            value={p.repeatedValue}
            validation={p.validation}
            isAnswerHashed={p.isAnswerHashed}
            elementType={p.elementType}
          />
        </labels.LabeledQuestion>
      </div>
    )}
  </I18n>
);

export const TextAreaInput = responsive.HOC(
  responsive.media.max640,
)((props: TextInputProps & { responsive: boolean }) => (
  <AccessibleTextarea
    cols={props.responsive ? 30 : 60}
    rows="5"
    {...commonProps(props)}
  />
));

// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { connect, useSelector } from 'react-redux';
import { Button, responsive } from 'react-usit-ui';

import * as Backend from '../../backend/legacyJsonBackend';
import getSettings from '../../backend/settings';
import { triggerDeleteModal } from '../../modes/editSubmission/ConfirmDeleteModal';
import type { AnswerState } from '../../reducer';
import type { Dispatch } from '../../store';
import { indexOfNextPageWithVisibleQuestions } from '../hiddenElements';
import loadingBars from './indicator-bars.gif';
import shouldDeliverToNewApi from './shouldDeliverToNewApi';
import willBeForwarded from './willBeForwarded';

const style = {
  row: {
    display: 'flex',
    height: '50px',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    '& :last-child': {
      marginRight: 0,
    },
  },
  responsiveButton: {
    height: '50px',
    minWidth: '155px',
    marginRight: 39,
  },
  textButton: {
    padding: 0,
    minWidth: '182px',
    fontWeight: 'bold',
  },
  inlineLoadingBars: {
    marginLeft: 10,
  },
  [responsive.media.max640]: {
    responsiveButton: {
      display: 'block',
      width: '100%',
      height: '70px',
      marginBottom: '20px',
      marginRight: 0,
    },
    textButton: {
      textAlign: 'center',
    },
  },
};
type Classes = { [$Keys<typeof style>]: string };

const SecondaryButton = injectSheet(style)(
  (p: {
    classes: Classes,
    onClick: () => void,
    children: React.Node,
    id?: string,
    disabled?: boolean,
  }) => (
    <Button
      type="secondary"
      border
      id={p.id}
      disabled={p.disabled}
      onClick={p.onClick}
      className={classNames(p.classes.responsiveButton)}
    >
      {p.children}
    </Button>
  ),
);
const PrimaryButton = injectSheet(style)(
  (p: {
    classes: Classes,
    onClick: () => void,
    children: React.Node,
    id?: string,
    disabled?: boolean,
  }) => (
    <Button
      type="primary"
      border
      id={p.id}
      onClick={p.onClick}
      disabled={p.disabled}
      className={classNames(p.classes.responsiveButton)}
    >
      {p.children}
    </Button>
  ),
);

const TextButton = injectSheet(style)(
  (p: {
    classes: Classes,
    onClick: () => void,
    children: React.Node,
    id: string,
  }) => (
    <Button
      type="text"
      id={p.id}
      onClick={p.onClick}
      className={classNames(p.classes.responsiveButton, p.classes.textButton)}
    >
      {p.children}
    </Button>
  ),
);

const AbortButton = (p: {
  previousPageExists: boolean,
  onAbort?: () => void,
  abortText?: string,
}) =>
  !p.previousPageExists &&
  p.onAbort != null && (
    <SecondaryButton onClick={p.onAbort}>
      {p.abortText ? p.abortText : <Trans>Avbryt</Trans>}
    </SecondaryButton>
  );

const ConnectedAbortButton = connect(
  state => ({
    previousPageExists: state.answer.currentPageIndex > 0,
  }),
  (dispatch: Dispatch, ownProps: { onAbort?: () => void }) => ({
    onAbort: ownProps.onAbort,
  }),
)(AbortButton);

const PreviousButton = (p: {
  previousPageExists: boolean,
  onClick: () => void,
  classes: Classes,
}) =>
  p.previousPageExists && (
    <SecondaryButton id="previous-page" onClick={p.onClick}>
      <Trans>Forrige side</Trans>
    </SecondaryButton>
  );

const ConnectedPreviousButton = connect(
  state => ({
    previousPageExists: state.answer.currentPageIndex > 0,
  }),
  (dispatch: Dispatch) => ({
    onClick: () => {
      dispatch({ type: 'PREVIOUS_PAGE_REQUESTED' });
    },
  }),
)(injectSheet(style)(PreviousButton));

const NextButton = (p: {
  nextPageExists: boolean,
  onClick: () => void,
  classes: Classes,
}) =>
  p.nextPageExists && (
    <PrimaryButton id="next-page" onClick={p.onClick}>
      <Trans>Neste side</Trans>
    </PrimaryButton>
  );

const ConnectedNextButton = connect(
  state => ({
    nextPageExists: nextPageExists(state.answer),
    pageIndex: state.answer.currentPageIndex,
  }),
  (dispatch: Dispatch) => ({
    onClick: fromPage => {
      dispatch({ type: 'NEXT_PAGE_REQUESTED', fromPage });
    },
  }),

  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onClick: () => {
      dispatchProps.onClick(stateProps.pageIndex);
    },
  }),
)(injectSheet(style)(NextButton));

const PostponeButton = (p: {
  postponable: boolean,
  onClick: () => void,
  classes: Classes,
}) =>
  p.postponable && (
    <TextButton id="save" onClick={p.onClick}>
      <Trans>Lagre og svar senere</Trans>
    </TextButton>
  );

const ConnectedPostponeButton = connect(
  state => ({
    postponable: !!state.answer.form.meta.postponable,
  }),
  (dispatch: Dispatch) => ({
    onClick: () => {
      dispatch({
        type: 'SHOW_MODAL',
        modalType: 'postpone',
      });
    },
  }),
)(injectSheet(style)(PostponeButton));

const SubmitButton = (p: {
  nextPageExists: boolean,
  submitting: boolean,
  signingRequired?: boolean,
  formType: Backend.FormType,
  classes: Classes,
  customText: ?string,
  submitText?: string,
  willBeForwarded: boolean,
  mode: string,
  onClick: () => void,
}) =>
  !p.nextPageExists && (
    <PrimaryButton id="submit-form" onClick={p.onClick} disabled={p.submitting}>
      {p.submitText
        ? p.submitText
        : getSubmitButtonText(
            p.mode,
            p.formType,
            p.signingRequired,
            p.customText,
            p.willBeForwarded,
          )}
      {p.submitting && (
        <img
          className={p.classes.inlineLoadingBars}
          src={loadingBars}
          alt="loading"
        />
      )}
    </PrimaryButton>
  );

const getSubmitButtonText = (
  mode,
  formType,
  signingRequired,
  customText,
  willBeForwarded,
) => {
  if (mode !== 'reactEmbedded' && customText) {
    return customText;
  } else if (signingRequired) {
    return <Trans>Gå til signering</Trans>;
  } else if (formType === 'SIGN_UP') {
    return willBeForwarded ? (
      <Trans>Meld på og gå videre</Trans>
    ) : (
      <Trans>Meld på</Trans>
    );
  } else {
    return willBeForwarded ? (
      <Trans>Send og gå videre</Trans>
    ) : (
      <Trans>Send</Trans>
    );
  }
};

const ConnectedNewSubmitButton = connect(
  state => ({
    nextPageExists: nextPageExists(state.answer),
    signingRequired: state.answer.form.meta.signingRequired,
    submitting: state.answer.submitting,
    customText: getSettings().submitButtonText,
  }),
  (dispatch: Dispatch) => ({
    onClick: () => {
      dispatch({ type: 'SUBMIT_FORM_AS_JSON_REQUESTED' });
    },
  }),
)(injectSheet(style)(SubmitButton));

const ConnectedSubmitButton = connect(
  state => ({
    nextPageExists: nextPageExists(state.answer),
    signingRequired: state.answer.form.meta.signingRequired,
    submitting: state.answer.submitting,
    customText: getSettings().submitButtonText,
  }),
  (dispatch: Dispatch) => ({
    onClick: () => {
      dispatch({ type: 'SUBMIT_FORM_REQUESTED' });
    },
  }),
)(injectSheet(style)(SubmitButton));

const DeleteButton = (p: {
  onClick: () => void,
  type: Backend.FormType,
  mode: string,
}) =>
  p.mode === 'editSubmission' && (
    <TextButton id="delete" onClick={p.onClick}>
      {p.type === 'SIGN_UP' ? (
        <Trans>Slett denne påmeldingen</Trans>
      ) : (
        <Trans>Slett dette svaret</Trans>
      )}
    </TextButton>
  );

const ConnectedDeleteButton = connect(
  state => ({ type: state.answer.form.meta.type }),
  (dispatch: Dispatch) => ({
    onClick: () => triggerDeleteModal(dispatch),
  }),
)(DeleteButton);

const Buttons = (p: {
  classes: Classes,
  onAbort?: () => void,
  abortText?: string,
  submitText?: string,
  mode: string,
}) => {
  const form = useSelector(state => state.answer.form);
  const inputs = useSelector(state => state.answer.inputs);
  const newDelivery = shouldDeliverToNewApi(form);
  const forwarded = willBeForwarded(form, inputs);
  return (
    <div className={p.classes.row}>
      <div className={p.classes.left}>
        <ConnectedAbortButton abortText={p.abortText} onAbort={p.onAbort} />
        <ConnectedPreviousButton />
        <ConnectedDeleteButton mode={p.mode} />
      </div>

      <div className={p.classes.right}>
        <ConnectedPostponeButton />
        <ConnectedNextButton />

        {newDelivery ? (
          <ConnectedNewSubmitButton
            submitText={p.submitText}
            mode={p.mode}
            willBeForwarded={forwarded}
          />
        ) : (
          <ConnectedSubmitButton
            submitText={p.submitText}
            mode={p.mode}
            willBeForwarded={forwarded}
          />
        )}
      </div>
    </div>
  );
};

const MobileButtons = (p: {
  classes: Classes,
  onAbort?: () => void,
  abortText?: string,
  submitText?: string,
  mode: string,
}) => {
  const form = useSelector(state => state.answer.form);
  const inputs = useSelector(state => state.answer.inputs);
  const newDelivery = shouldDeliverToNewApi(form);
  const forwarded = willBeForwarded(form, inputs);
  return (
    <div>
      <ConnectedAbortButton abortText={p.abortText} onAbort={p.onAbort} />
      <ConnectedNextButton />
      {newDelivery ? (
        <ConnectedNewSubmitButton
          submitText={p.submitText}
          mode={p.mode}
          willBeForwarded={forwarded}
        />
      ) : (
        <ConnectedSubmitButton
          submitText={p.submitText}
          mode={p.mode}
          willBeForwarded={forwarded}
        />
      )}
      <ConnectedPreviousButton />
      <ConnectedDeleteButton mode={p.mode} />
      <ConnectedPostponeButton />
    </div>
  );
};

const AnswerControls = responsive.HOC(
  responsive.media.max640,
)(
  (p: {
    responsive: boolean,
    classes: Classes,
    onAbort?: () => void,
    abortText?: string,
    submitText?: string,
    mode: string,
  }) => (
    <div>
      {p.responsive ? (
        <MobileButtons
          abortText={p.abortText}
          submitText={p.submitText}
          mode={p.mode}
          classes={p.classes}
          onAbort={p.onAbort}
        />
      ) : (
        <Buttons
          abortText={p.abortText}
          submitText={p.submitText}
          mode={p.mode}
          classes={p.classes}
          onAbort={p.onAbort}
        />
      )}
    </div>
  ),
);

const StyledAnswerControls = injectSheet(style)(AnswerControls);

export const nextPageExists = (answerState: AnswerState) =>
  answerState.form != null &&
  answerState.currentPageIndex < answerState.form.pages.length - 1 &&
  indexOfNextPageWithVisibleQuestions(
    answerState.currentPageIndex,
    answerState.form.pages,
    answerState.inputs,
  ) > answerState.currentPageIndex;

// $FlowIgnore Flow 0.78 mangler type React.memo
export default React.memo(StyledAnswerControls);

// @flow

export default function isValidNationalIdNumber(
  nationalIdNumber: string,
): boolean {
  if (!isNaN(nationalIdNumber) && nationalIdNumber.length == 11) {
    if (!passesModulo11Test(nationalIdNumber)) {
      return false;
    }

    /* Tillate fødselsnumre fra Tenor testdata på dev og test */
    let host = window.location.host;
    let isProd = host === 'nettskjema.no';

    if (isProd) {
      // ID number with dates normalized for D and H numbers:
      var normalizedNationalIdNumber = getNormalizedNationalIdNumber(
        nationalIdNumber,
      );

      if (!passesDateTest(normalizedNationalIdNumber)) {
        return false;
      }
    }

    return true;
  }
  return false;
}

function passesModulo11Test(nationalIdNumber) {
  var WEIGHT_NUMBERS = [
    [3, 7, 6, 1, 8, 9, 4, 5, 2, 1, 0],
    [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1],
  ];
  for (var i = 0; i < WEIGHT_NUMBERS.length; i++) {
    var sum = 0;
    for (var j = 0; j < WEIGHT_NUMBERS[i].length; j++) {
      sum += parseInt(nationalIdNumber.charAt(j), 10) * WEIGHT_NUMBERS[i][j];
    }
    if (sum % 11 !== 0) {
      return false;
    }
  }
  return true;
}

function getNormalizedNationalIdNumber(nationalIdNumber) {
  // H numbers: 4 is added to the third digit, i.e. the first digit of month.
  // Hence, H numbers have months between 41 and 52
  var month = getMonthInNationalIdNumber(nationalIdNumber);
  var h = parseInt(nationalIdNumber.charAt(2), 10);
  if (month > 40 && month < 53) {
    return (
      nationalIdNumber.substring(0, 2) + (h - 4) + nationalIdNumber.substring(3)
    );
  }

  // D numbers: 4 is added to the first digit, i.e. the first digit of day.
  // Hence, D numbers have days between 41 and 71
  var day = getDayInNationallIdNumber(nationalIdNumber);
  var d = parseInt(nationalIdNumber.charAt(0), 10);
  if (day > 40 && day < 72) {
    return d - 4 + nationalIdNumber.substring(nationalIdNumber.length - 10);
  }

  return nationalIdNumber;
}

function passesDateTest(nationalIdNumber) {
  try {
    var day = getDayInNationallIdNumber(nationalIdNumber);
    var month = getMonthInNationalIdNumber(nationalIdNumber);
    var year = getYearInNationalIdNumber(nationalIdNumber);
    var currentYear = getCurrentYear();
    var daysInMonth = getDaysInMonth(month, year);

    if (year < 1900 || year > currentYear) {
      return false;
    }

    if (day < 1 || day > daysInMonth) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
}

function getCurrentYear() {
  return new Date().getFullYear();
}

function getDayInNationallIdNumber(nationalIdNumber) {
  return parseInt(nationalIdNumber.substring(0, 2), 10);
}

function getMonthInNationalIdNumber(nationalIdNumber) {
  return parseInt(nationalIdNumber.substring(2, 4), 10);
}

function getYearInNationalIdNumber(nationalIdNumber) {
  // Individual number is first three digits of the five-digit national id
  // number:
  var individualNumber = parseInt(
    nationalIdNumber.substring(6, nationalIdNumber.length - 2),
    10,
  );
  var twoDigitYear = parseInt(nationalIdNumber.substring(4, 6), 10);

  var fourDigitYear = 0;
  // Born between 1900-1999:
  if (individualNumber < 500) {
    fourDigitYear = twoDigitYear + 1900;
    // Born between 1854-1899:
  } else if (
    individualNumber >= 500 &&
    individualNumber < 750 &&
    twoDigitYear >= 54
  ) {
    fourDigitYear = twoDigitYear + 1800;
    // Born between 2000-2039:
  } else if (
    individualNumber >= 500 &&
    individualNumber <= 999 &&
    twoDigitYear < 40
  ) {
    fourDigitYear = twoDigitYear + 2000;
    // Born between 1940-1999:
  } else if (
    individualNumber >= 900 &&
    individualNumber <= 999 &&
    twoDigitYear >= 40
  ) {
    fourDigitYear = twoDigitYear + 1900;
  } else {
    throw new Error('Illegal individual number: ' + individualNumber);
  }
  return fourDigitYear;
}

function getDaysInMonth(month, year) {
  var days = 31;
  if (month === 2) {
    days = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
  } else if (month === 4 || month === 6 || month === 9 || month === 11) {
    days = 30;
  }
  return days;
}

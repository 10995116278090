// @flow

import type { Saga } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  addNoScrollToBodyGenerator,
  removeNoScrollFromBodyGenerator,
} from '../../../components/preventScrolling';
import { logError } from '../../../errorHandling';
import type { BackendInterface } from '../backend/interface';
import {
  getFormInformation,
  getInvitationInformation,
  handlePingResponse,
  postponeForm,
  submitEmail,
  submitJsonRequested,
  submitRequested,
} from '../backend/sagas';
import getSettings from '../backend/settings';
import * as EscapeHatch from '../LegacyEscapeHatch';
import { listener as routingListenerSaga } from '../routing/sagas';
import { saga as embeddingSaga } from './iframeEmbedding/saga';

export type RootSagaArgs = {
  formId?: ?number,
  invitationId?: ?number,
  mode: 'regular' | 'iframeEmbedded',
};

function* countStats(action: any): Saga<void> {
  const data = JSON.stringify(action.data);
  const url = getSettings().CONTEXT_PATH + '/stats/count';
  try {
    yield call(fetch, url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      body: data,
    });
  } catch (e) {
    logError(e);
  }
}

export function* listenForCountActions(): Saga<void> {
  yield takeEvery('COUNT', countStats);
}

export function* listenForFormActions(api: BackendInterface): Saga<void> {
  yield takeEvery('SUBMIT_FORM_REQUESTED', submitRequested, api);
  yield takeEvery('SUBMIT_FORM_AS_JSON_REQUESTED', submitJsonRequested, api);
  yield takeEvery('FORM_POSTPONE_REQUESTED', postponeForm, api);
  yield takeEvery('OLD_DESIGN_REQUESTED', EscapeHatch.onOldDesignRequested);
  yield takeEvery('OLD_DESIGN_CONFIRMED', EscapeHatch.onOldDesignConfirmed);
  yield takeEvery('SHOW_MODAL', addNoScrollToBodyGenerator);
  yield takeEvery('HIDE_MODAL', removeNoScrollFromBodyGenerator);
}

function* updateLastActiveTime(api: BackendInterface): Saga<void> {
  const fiveMinutesInMilliseconds = 300000;
  const lastActive = yield select(state => state.answer.lastActive);
  const currentTime = new Date().getTime();
  if (currentTime - lastActive >= fiveMinutesInMilliseconds) {
    yield put({
      type: 'UPDATE_LAST_ACTIVE',
      data: { lastActive: currentTime },
    });
    yield call(handlePingResponse, api);
  }
}

function* requestToken(api: BackendInterface): Saga<void> {
  const { submissionId, invitationId, expiredToken } = yield select(
    state => state.answer.form,
  );

  try {
    if (submissionId && expiredToken) {
      yield call(api.requestNewTokenForSubmission, expiredToken, submissionId);
    } else if (invitationId && expiredToken) {
      yield call(api.requestNewTokenForInvitation, expiredToken, invitationId);
    }

    yield put({
      type: 'SHOW_MODAL',
      modalType: 'reauthentication',
      modalProps: { authenticationType: 'tokenValidation' },
    });
  } catch (error) {
    yield put({
      type: 'SHOW_MODAL',
      modalType: 'reauthentication',
      modalProps: { authenticationType: 'failed' },
    });
  }
}

function* authenticateToken(api: BackendInterface, action: Object): Saga<void> {
  const { invitationId, submissionId, expiredToken } = yield select(
    state => state.answer.form,
  );
  let response;
  try {
    if (submissionId && expiredToken) {
      response = yield call(
        api.sendReauthenticationCodeForSubmission,
        expiredToken,
        action.token,
        submissionId,
      );
    } else if (invitationId && expiredToken) {
      response = yield call(
        api.sendReauthenticationCodeForInvitation,
        expiredToken,
        action.token,
        invitationId,
      );
    } else {
      throw new Error('Missing token or form has no invitation/submission ID');
    }

    if (response.ok) {
      yield put({ type: 'HIDE_MODAL' });
    } else {
      yield put({
        type: 'SHOW_MODAL',
        modalType: 'reauthentication',
        modalProps: { authenticationType: 'failed' },
      });
    }
  } catch (error) {
    yield put({
      type: 'SHOW_MODAL',
      modalType: 'reauthentication',
      modalProps: { authenticationType: 'failed' },
    });
  }
}

export function* listenForReauthenticateActions(
  api: BackendInterface,
): Saga<void> {
  yield takeEvery('*', updateLastActiveTime, api);
  yield takeEvery('REQUEST_TOKEN', requestToken, api);
  yield takeEvery('AUTHENTICATE_TOKEN', authenticateToken, api);
}

export function* defaultSaga(
  api: BackendInterface,
  args: RootSagaArgs,
): Saga<void> {
  if (args.mode === 'iframeEmbedded') {
    yield fork(embeddingSaga);
  }
  yield fork(listenForCountActions);
  yield fork(listenForReauthenticateActions, api);
  yield fork(routingListenerSaga);
  if (args.invitationId != null) {
    yield call(getInvitationInformation, api, args.invitationId);
  } else if (args.formId != null) {
    yield call(getFormInformation, api, args.formId);
  }
  yield takeEvery('SEND_EMAIL_RECEIPT_REQUESTED', submitEmail, api);

  yield call(listenForFormActions, api);
}

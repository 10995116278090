// @flow

import * as Backend from '../backend/legacyJsonBackend';
import type { TextInput } from '../inputs/types';
import ValidationSequence from './ValidationSequence';

export const validateMaxNumberOfCharacters = (
  value: TextInput,
  element: Backend.Element,
) =>
  ValidationSequence()
    .when(typeof element.maxNumberOfCharacters !== 'number', {
      doThrow: new Error(
        'Attempted to validate max number of characters, but no max number of characters was provided',
      ),
    })
    .when(value.given.length > (element.maxNumberOfCharacters ?? Infinity), {
      includeError: [
        'max_number_of_characters_exceeded',
        ({
          providedChars: value.given.length,
          maxChars: element.maxNumberOfCharacters,
        }: $FlowIgnore),
      ],
    });

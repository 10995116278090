import { elementShouldBeHidden } from '../hiddenElements';

import { flatten } from 'lodash';

const willBeForwarded = (form, inputs) => {
  if (form.meta.afterDeliveryForwardFormIds) return true;

  const forwardingAnswerOptions = flatten(
    form.pages.map(page =>
      flatten(
        page.elements
          ?.filter(
            element =>
              ['RADIO', 'SELECT'].includes(element.elementType) &&
              !elementShouldBeHidden(element, inputs, form.pages),
          )
          .map(element => element.questions[0].answerOptions) ?? [],
      ),
    ),
  )
    .filter(option => option.redirectToForm)
    .map(option => option.answerOptionId);

  return (
    inputs?.some(
      input =>
        input.value.type === 'SINGLE_OPTION' &&
        forwardingAnswerOptions.includes(input.value.given),
    ) ?? false
  );
};
export default willBeForwarded;

// @flow

import * as React from 'react';
import injectSheet from 'react-jss';
import { responsive } from 'react-usit-ui';

import { primary, white } from '../../design/colors';

const style = {
  container: {
    margin: '0 auto 50px auto',
  },
  progressBar: {
    minWidth: 280,
    marginLeft: '100px',
    marginRight: '100px',
    marginTop: '27px',
    height: '24px',
    border: 'solid 2px',
    borderColor: primary.border,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2px',
    borderRadius: '100px',
  },
  indicator: {
    minWidth: '43px',
    height: '16px',
    backgroundColor: primary.lighter,
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: '100px',
  },
  percentage: {
    width: '43px',
    height: '16px',
    backgroundColor: primary.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: white,
    fontSize: '10px',
    borderRadius: '100px',
  },
  [responsive.media.max640]: {
    progressBar: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const ProgressBar = (p: { progress: number, classes: Classes }) => (
  <div className={p.classes.container}>
    <div
      className={p.classes.progressBar}
      role="progressbar"
      aria-valuenow={p.progress}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div className={p.classes.indicator} style={{ width: `${p.progress}%` }}>
        <div className={p.classes.percentage}>{Math.round(p.progress)} %</div>
      </div>
    </div>
  </div>
);

export const shouldShowProgressIndicator = () => {
  return !window.Nettskjema.shouldHideProgressbar;
};

// $FlowIgnore Flow 0.78 mangler type React.memo
export default React.memo(injectSheet(style)(ProgressBar));

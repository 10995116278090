// @flow

import ValidationSequence from './ValidationSequence';

const validRadioSelection = (value: number | null) =>
  ValidationSequence().when(value != null && value <= 0, {
    includeError: 'invalid_radio_value',
  });

export default validRadioSelection;

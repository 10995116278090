import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Nettskjema } from '../../../../nettskjema';
import * as actions from './actions';
import { Credentials } from './types';

function post(endpoint: string, body: any) {
  return fetch(Nettskjema.getContextPath() + endpoint, {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      NETTSKJEMA_CSRF_PREVENTION: Nettskjema.getCsrfPreventionToken(),
    },
  }).then(response => response);
}

function* activateForm(action: { data: Credentials }) {
  const formId = yield select(
    (state: any) => state.form.metaInformation.formId,
  );
  try {
    const endpoint = `/api/v2/tsd/form/${formId}/activate`;
    const response = yield call(post, endpoint, action.data);

    if (response.status === 200) {
      const result = yield response.json();
      yield put(actions.activateFormSuccessful(result));
      yield put({ type: 'RELOAD_FORM_SETTINGS' });
    } else {
      const result = yield response.json();
      yield put(actions.activateFormFailed(result.message));
    }
  } catch (e) {
    yield put(actions.activateFormFailed(e.message));
  }
}

function* setRespondentGroup(action: { data: string }) {
  const formId = yield select(
    (state: any) => state.form.metaInformation.formId,
  );

  const formData = new FormData();
  formData.append('respondentGroup', action.data);

  try {
    const setRespondentGroup = yield fetch(
      `${Nettskjema.getContextPath()}/user/form/permissions.json?id=${formId}`,
      {
        credentials: 'same-origin',
        body: formData,
        method: 'POST',
        headers: {
          NETTSKJEMA_CSRF_PREVENTION: Nettskjema.getCsrfPreventionToken(),
        },
      },
    );
    if (setRespondentGroup.status === 200) {
      yield put(actions.setRespondentGroupSuccess());
    } else {
      yield put(actions.setRespondentGroupFailed());
    }
  } catch {
    yield put(actions.setRespondentGroupFailed());
  }
}

export function* rootSaga(): SagaIterator {
  yield takeEvery('ACTIVATE_FORM' as any, activateForm);
  yield takeEvery(
    'SELF_SERVICE_SET_RESPONDENT_GROUP' as any,
    setRespondentGroup,
  );
}

export default rootSaga;

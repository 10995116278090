// @flow
import * as React from 'react';
import injectSheet from 'react-jss';

const style = {
  root: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    width: 1,
    height: 1,
    overflow: 'hidden',
  },
};

type Classes = { [$Keys<typeof style>]: string };

export const descriptionOf = (id: string) => id + '-screenReaderDescription';

const ScreenReaderDescription = (p: {
  forElementId: string,
  classes: Classes,
  children: React.Node,
}) => (
  <div id={descriptionOf(p.forElementId)} className={p.classes.root}>
    {p.children}
  </div>
);

export const InvisibleComponent = injectSheet(style)(ScreenReaderDescription);

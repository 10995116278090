// @flow

import type { FormInput } from './types';
import { structure } from './types';

function getFormData(inputs: FormInput[]): FormData {
  const formData = new window.FormData();
  formData.append('refererUrlHolder', document.referrer);

  for (const input: FormInput of inputs) {
    append(input).to(formData);
  }
  return formData;
}

export function append(input: FormInput) {
  return {
    to: (formData: FormData) => {
      const { value } = input;
      if (value.type === structure.notProvided.type) {
        return;
      } else if (value.type === structure.text.type) {
        formData.append(textAnswer(input), value.given);
      } else if (value.type === structure.singleOption.type) {
        const selectedOption =
          value.given == null ? '' : value.given.toString();
        formData.append(answersAsMap(input, 'answerOption'), selectedOption);
      } else if (value.type === structure.file.type) {
        formData.append(answersAsMap(input, 'attachment.upload'), value.given);
      } else if (value.type === structure.storedFile.type) {
        formData.append(
          'retainedAttachmentQuestions[]',
          input.questionId.toString(),
        );
      } else if (value.type === structure.date.type) {
        formData.append(textAnswer(input), value.given);
      } else if (value.type === structure.dateTime.type) {
        if (value.givenDate == null || value.givenTime == null) {
          throw new MissingValidation('Validation did not catch null value');
        }
        formData.append(
          textAnswer(input),
          `${(value.givenDate: $FlowIgnore)} ${(value.givenTime: $FlowIgnore)}`,
        );
      } else if (value.type === structure.time.type) {
        formData.append(textAnswer(input), value.given);
      } else if (value.type === structure.multipleOptions.type) {
        if (value.given.length === 0) {
          formData.append(answersAsMap(input, 'answerOptions'), '');
        }
        for (const answer of value.given) {
          formData.append(
            answersAsMap(input, 'answerOptions'),
            answer.toString(),
          );
        }
      } else {
        throw new MissingDeliveryLogic(
          `inconsistent (type, value) combination: (${
            value.type
          }, ${JSON.stringify(value.given)},
        )`,
        );
      }
    },
  };
}

const answersAsMap = (input, suffix) =>
  `answersAsMap[${input.questionId.toString()}].${suffix}`;

const textAnswer = input => answersAsMap(input, 'textAnswer');

class MissingValidation extends Error {}

class MissingDeliveryLogic extends Error {}

export default getFormData;

// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux';
import type { Saga } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import { getApi } from '../backend/interface';
import type { BackendInterface } from '../backend/interface';
import { getFormInformation, submitRequested } from '../backend/sagas';
import * as Loading from '../form/loading';
import { createStore } from '../store';
import { formUIId } from './formUIId';
import { ReactEmbeddedFormContainer } from './reactEmbeddingContainer';

function* rootSaga(
  api: BackendInterface,
  formId: number | string,
  onSubmitFinished: () => void,
): Saga<void> {
  yield takeEvery('FETCH_FORM_INFORMATION_FAILED', call, onSubmitFinished);
  yield takeEvery('SUBMIT_FORM_ERROR', call, onSubmitFinished);
  yield takeEvery('SUBMIT_FORM_REQUESTED', submitRequested, api);
  yield takeEvery('SUBMIT_FINISHED', function*(): Saga<void> {
    yield call(onSubmitFinished);
  });
  yield call(getFormInformation, api, formId);
}

export const EmbeddedForm = (p: {
  formId: string | number,
  onSubmitFinished: () => void,
  onAbort: () => void,
  abortText?: string,
  submitText?: string,
}) => (
  <Provider
    store={createStore(() => rootSaga(getApi(), p.formId, p.onSubmitFinished))}
  >
    <EmbeddedFormWithLifecycle
      abortText={p.abortText}
      submitText={p.submitText}
      onAbort={p.onAbort}
    />
  </Provider>
);

const EmbeddedFormWithLifecycle = (p: {
  onAbort?: () => void,
  abortText?: string,
  submitText?: string,
}) => {
  const dispatch = useDispatch();
  dispatch({ type: 'SET_MODE', mode: 'reactEmbedding' });

  let Component: ?React.ComponentType<{
    onAbort?: () => void,
    mode?: any,
    submitText?: string,
    abortText?: string,
  }>;

  //$FlowFixMe
  const formLoadingStatus: Loading.FormLoadingStatus = useSelector(
    state => state.answer.formLoadingStatus,
  );

  if (formLoadingStatus === 'NOT_STARTED') {
    Component = Loading.PreparingLoad;
  } else if (formLoadingStatus === 'LOADING') {
    Component = Loading.LoadingForm;
  } else if (formLoadingStatus === 'FAILED') {
    Component = Loading.Failed;
  } else if (formLoadingStatus === 'LOADED') {
    Component = ReactEmbeddedFormContainer;
  }
  if (Component == null) {
    throw new Error(
      `No UI component found for prop combination ${JSON.stringify(p)}`,
    );
  }
  return (
    <div id={formUIId} tabIndex={-1} style={{ outline: 'none' }} role="form">
      <Component
        submitText={p.submitText}
        abortText={p.abortText}
        onAbort={p.onAbort}
      />
    </div>
  );
};

// @flow

import type { Validation } from '../submission-validation/types';

export const structure = {
  notProvided: { type: 'NOT_PROVIDED' },
  date: { type: 'DATE' },
  dateTime: { type: 'DATE_TIME' },
  time: { type: 'TIME' },
  singleOption: { type: 'SINGLE_OPTION' },
  multipleOptions: { type: 'MULTIPLE_OPTIONS' },
  file: { type: 'FILE' },
  storedFile: { type: 'STORED_FILE' },
  text: { type: 'TEXT' },
};

export type UnprovidedInput = typeof structure.notProvided & {
  disabled?: boolean,
};

export type DateInput = typeof structure.date & {
  given: string,
  disabled?: boolean,
};

export type DateTimeInput = typeof structure.dateTime & {
  givenDate: ?string,
  givenTime: ?string,
  disabled?: boolean,
};

export type TimeInput = typeof structure.time & {
  given: string,
  disabled?: boolean,
};

export type SingleOptionInput = typeof structure.singleOption & {
  given: number | null,
  disabled?: boolean,
};
export type MultipleOptionsInput = typeof structure.multipleOptions & {
  given: number[],
  disabled?: boolean,
};
export type FileInput = typeof structure.file & {
  given: File,
  disabled?: boolean,
};
export type StoredFileInput = typeof structure.storedFile & {
  given: { answerAttachmentId: number, filename: string },
  disabled?: boolean,
};
export type TextInput = typeof structure.text & {
  given: string,
  disabled?: boolean,
  repeated?: string,
};

export type ProvidedInputValue =
  | DateInput
  | DateTimeInput
  | TimeInput
  | SingleOptionInput
  | MultipleOptionsInput
  | FileInput
  | StoredFileInput
  | TextInput;

export type InputValue = ProvidedInputValue | UnprovidedInput;

export type FormInput = {
  value: InputValue,
  validation: Validation,
  questionId: number,
  allowsAutofill?: boolean,
};

export const create = {
  notProvided(): UnprovidedInput {
    return { ...structure.notProvided };
  },
  singleOption(given: number | null): SingleOptionInput {
    return { ...structure.singleOption, given };
  },
  multipleOptions(given: number[]): MultipleOptionsInput {
    return { ...structure.multipleOptions, given };
  },
  file(given: File): FileInput {
    return { ...structure.file, given };
  },
  date(given: string): DateInput {
    return { ...structure.date, given };
  },
  dateTime(givenDate: ?string, givenTime: ?string): DateTimeInput {
    return { ...structure.dateTime, givenDate, givenTime };
  },
  time(given: string): TimeInput {
    return { ...structure.time, given };
  },
  text(given: string): TextInput {
    return { ...structure.text, given };
  },
  repeatedText(main: string, repeated: string): TextInput {
    return { ...structure.text, given: main, repeated };
  },
};

export const isEmpty = (value: InputValue) =>
  value.type === structure.notProvided.type ||
  (value.type === structure.singleOption.type && !value.given) ||
  (value.type === structure.multipleOptions.type && value.given.length < 1) ||
  (value.type === structure.file.type && !value.given.name) ||
  (value.type === structure.date.type && isEmptyOrWhitespace(value.given)) ||
  (value.type === structure.dateTime.type &&
    isEmptyOrWhitespace(value.givenDate) &&
    isEmptyOrWhitespace(value.givenTime)) ||
  (value.type === structure.time.type && isEmptyOrWhitespace(value.given)) ||
  (value.type === structure.text.type &&
    isEmptyOrWhitespace(value.given) &&
    isEmptyOrWhitespace(value.repeated));

const isEmptyOrWhitespace = (string: ?string) =>
  string == null || /^\s*$/.test(string);

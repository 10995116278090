import { plural, t } from '@lingui/macro';

import { BACKEND_ERROR_CODE } from './validateInputs';

export default function getTranslated(i18n) {
  const translations = {
    [BACKEND_ERROR_CODE]: ({ message }) =>
      i18n._(t`Feil ved levering: ${message}`),
    missing_input: () => i18n._(t`Feltet må fylles ut`),
    checkbox_must_be_selected: () => i18n._(t`Minst ett svar må velges`),
    you_can_only_select: ({ n }) =>
      i18n._(
        plural({
          value: n,
          one: 'Du kan kun velge ett svaralternativ',
          other: 'Du kan kun velge # svaralternativer',
        }),
      ),
    invalid_email: () => i18n._(t`Ugyldig e-postadresse`),
    invalid_number: () => i18n._(t`Ugyldig tegn. Du kan kun skrive inn tall`),
    invalid_date_format: () => i18n._(t`Ugyldig datoformat. Bruk dd.mm.yyyy`),
    invalid_time_format: () => i18n._(t`Ugyldig tidsformat. Bruk hh:mm`),
    missing_date: () => i18n._(t`Dato må fylles ut`),
    missing_time: () => i18n._(t`Klokkeslett må fylles ut`),
    time_does_not_exist: () => i18n._(t`Ugyldig klokkeslett`),
    date_does_not_exist: () => i18n._(t`Ugyldig dato`),
    mandatory_norwegian_id_number: () =>
      i18n._(t`Du må skrive inn et norsk fødselsnummer (11 siffer)`),
    mandatory_only_numbers: () =>
      i18n._(t`Du må skrive inn person-ID bestående av kun tall`),
    mandatory_custom_personal_id: () =>
      i18n._(
        t`Du må skrive inn person-ID bestående av tall og/eller bokstaver`,
      ),
    mandatory_name: () => i18n._(t`Du må skrive inn et navn`),
    mandatory_username: () => i18n._(t`Du må skrive inn et brukernavn`),
    mandatory_email: () => i18n._(t`Du må skrive inn en e-postadresse`),
    mandatory_phone: () =>
      i18n._(
        t`Du må skrive inn et telefonnummer. Landskode er påkrevd for alle utenlandske numre og markeres med plusstegn, f.eks. +46.`,
      ),
    mandatory_attachment: () => i18n._(t`Du må laste opp et vedlegg`),
    mandatory_date_time: () =>
      i18n._(t`Du må skrive inn en dato og et klokkeslett`),
    mandatory_date: () => i18n._(t`Du må skrive inn en dato`),
    mandatory_time: () => i18n._(t`Du må skrive inn et klokkeslett`),
    mandatory_number: () => i18n._(t`Du må skrive inn et tall`),
    mandatory_linear_scale: () => i18n._(t`Du må velge en verdi på skalaen`),
    mandatory_question: () => i18n._(t`Du må svare på spørsmålet`),
    mandatory_row: () => i18n._(t`Du må svare på denne raden`),
    mandatory_radio_select: () => i18n._(t`Du må velge et alternativ`),
    mandatory_checkbox: () => i18n._(t`Du må velge minst ett alternativ`),
    attachment_file_size_max: ({ maxMb }) =>
      i18n._(t`Vedlegg kan maksimalt være på ${maxMb} MB.`),
    attachment_file_name_not_supported: () =>
      i18n._(
        t`Ulovlig filnavn. Et filnavn må være på formen 'navn-på-første-del.filendelse'. Det må være akkurat ett punktum mellom første del og filendelsen. Første del av filnavnet kan bare inneholde mellom 1 og 200 alfanumeriske tegn (a-z, A-Z og 1-10), særnorske og særsvenske bokstaver, mellomrom, bindestrek (-) og understrek (_). Filendelsen kan bare inneholde mellom ett og ti alfanumeriske tegn (a-z, A-Z og 1-10).`,
      ),
    attachment_file_type_not_supported: () =>
      i18n._(t`Denne filtypen støttes ikke`),
    wrong_letter_count: ({ expected }) =>
      i18n._(
        plural({
          value: expected,
          one: 'Feltet må inneholde én bokstav',
          other: 'Feltet må inneholde # bokstaver',
        }),
      ),
    wrong_digit_count: ({ expected }) =>
      i18n._(
        plural({
          value: expected,
          one: 'Feltet må inneholde ett siffer',
          other: 'Feltet må inneholde # siffer',
        }),
      ),
    contains_illegal_char: () => i18n._(t`Inneholder ulovlig tegn`),
    invalid_national_id_only_digits: () =>
      i18n._(t`Feltet kan kun inneholde siffer`),
    repeat_not_identical: () => i18n._(t`Verdien må være lik i begge feltene`),
    invalid_phone: () =>
      i18n._(
        t`Ugyldig telefonnummer. Landskode er påkrevd for alle utenlandske numre og markeres med plusstegn, f.eks. +46.`,
      ),
    invalid_national_id_number: () => i18n._(t`Ugyldig personnummer`),
    national_id_must_contain: () => i18n._(t`Person_ID skal bestå av:`),
    integers_only: () => i18n._(t`Kun heltall er tillatt`),
    decimals_allowed: ({ max }) =>
      i18n._(
        plural({
          value: max,
          one: 'Kun én desimal er tillatt',
          other: 'Kun # desimaler er tillatt',
        }),
      ),
    number_between: ({ min, max }) =>
      i18n._(t`Tallet må være mellom ${min} og ${max}`),
    number_not_greater_than: ({ max }) =>
      i18n._(t`Tallet kan ikke være større enn ${max}`),
    number_not_less_than: ({ min }) =>
      i18n._(t`Tallet kan ikke være mindre enn ${min}`),
    max_number_of_characters_exceeded: ({ providedChars, maxChars }) =>
      i18n._(
        t`Du har skrevet ${providedChars} tegn. Du kan skrive maksimalt ${maxChars} tegn.`,
      ),
  };
  return translations;
}

import { useDispatch } from 'react-redux';

import { updateFormField } from './actions/formActions';

export const getForm = (state: any) => state.form;

export const getPermissions = (state: any) => state.permissions;

export const getPerson = (state: any) => state.person;

export const getUi = (state: any) => state.ui;

export const useForm = (callback: () => void) => {
  const dispatch = useDispatch();

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange = (event: any) => {
    dispatch(updateFormField(event.target.name, event.target.value));
  };
  return { handleSubmit, handleInputChange };
};

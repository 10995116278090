// @flow

import * as React from 'react';
import { ThemeProvider } from 'react-jss';
import { Provider as ReduxProvider } from 'react-redux';

import { getApi } from './backend/interface';
import { getThemeForCurrentForm } from './design/themes';
import ErrorBoundary from './ErrorBoundary.js';
import { NettskjemaI18nProvider as I18nProvider } from './i18n';
import { ErrorHatch } from './LegacyEscapeHatch.js';
import { AppForMode } from './modes';
import { getMode, getRootSaga } from './modes/detect';
import { createStore } from './store';

const mode = getMode();
const store = createStore(() => getRootSaga(mode, getApi()));

const App = () => (
  <ErrorBoundary name="app.js" fallback={<ErrorHatch />}>
    <ReduxProvider store={store}>
      <I18nProvider>
        <ThemeProvider theme={getThemeForCurrentForm()}>
          <AppForMode mode={mode} />
        </ThemeProvider>
      </I18nProvider>
    </ReduxProvider>
  </ErrorBoundary>
);

export default App;

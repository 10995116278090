// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import { formUIId } from '../formUIId.js';

const hasPostMessage = parent && 'postMessage' in parent;

export function* saga(): Saga<void> {
  if (hasPostMessage) {
    yield call(listenForMessages);
  }
  yield takeEvery('SUBMIT_FINISHED', submitFinished);
  yield takeEvery('SUBMIT_FORM_ERROR', submitFailed);
}

function* submitFailed(): Saga<void> {
  yield call(() => parent.postMessage('submitFailed', '*'));
}

function* submitFinished(action): Saga<void> {
  if (action.data.status === 'success') {
    yield call(() => parent.postMessage('submitFinished', '*'));
  } else {
    yield call(() => parent.postMessage('submitFailed', '*'));
  }
}

function* listenForMessages(): Saga<void> {
  yield call(
    [window, window.addEventListener],
    'message',
    e => e.message === 'resize' && postHeightToParent(),
  );
  yield call([window, window.setInterval], postHeightToParent, 1000);
}

const postHeightToParent = () => {
  const formUI = window.document.querySelector(`#${formUIId}`);
  if (formUI != null) {
    const height = formUI.scrollHeight;
    // Need to add a little bit to the height for this to work nicely with external-embedding.js
    parent.postMessage(height + 50, '*');
  }
};

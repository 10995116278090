// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import * as Backend from '../../backend/legacyJsonBackend';
import DropdownRespondElement from './DropdownElement';
import DropdownQuizResult from './DropdownQuizResultElement';

type Correction = { correct: boolean };

export type DropdownProps = {
  children: Object,
  answerOptions: Backend.AnswerOption[],
  disabled: boolean,
  id: string,
  selectedOptionId: number | null,
  onSelectedOption: (value: number | null) => void,
  invalid?: boolean,
  mandatory: boolean,
  shouldRenderQuizFeedback: boolean,
  correction: ?Correction,
  correctAnswer: ?Backend.AnswerOption,
};

const Dropdown = (p: DropdownProps) => {
  if (!p.shouldRenderQuizFeedback) {
    return <DropdownRespondElement {...p} />;
  } else {
    return <DropdownQuizResult {...p} />;
  }
};

const ConnectedDropdown = connect((state, ownProps) => {
  const { submissionResponse } = state.answer;
  if (submissionResponse == null || submissionResponse.quizFeedback == null) {
    return { ...ownProps, shouldRenderQuizFeedback: false };
  }
  const { quizFeedback } = submissionResponse;
  return {
    ...ownProps,

    shouldRenderQuizFeedback: true,
    correctAnswer:
      quizFeedback.fullSolution &&
      quizFeedback.fullSolution[ownProps.questionId],
    correction: ownProps.selectedOptionId
      ? getCorrection(
          quizFeedback.correctedAnswerOptions,
          ownProps.selectedOptionId,
        )
      : undefined,
    correctButNotSelected:
      !ownProps.selectedOptionId &&
      quizFeedback.fullSolution != null &&
      quizFeedback.fullSolution[ownProps.questionId] ===
        ownProps.selectedOptionId,
  };
})(Dropdown);

const getCorrection = (
  correctedAnswerOptions?: Backend.CorrectedAnswerOptionsMap,
  answerOptionId: number,
) => {
  if (correctedAnswerOptions == null) {
    return undefined;
  }
  const correction = correctedAnswerOptions[answerOptionId.toString()];
  return { correct: correction };
};

export default ConnectedDropdown;

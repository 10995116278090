// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';

import correct from './correct.svg';
import fail from './incorrect.svg';

export type Correction = { correct: boolean };

const style = {
  quizResultIcon: { marginRight: 10 },
  quizResultText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#555555',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
};
type Classes = { [$Keys<typeof style>]: string };

export const QuizMark = injectSheet(style)(
  (p: { correct: boolean, classes: Classes }) => (
    <I18n>
      {({ i18n }) => (
        <span className={p.classes.quizResultText}>
          <img
            alt={p.correct ? i18n._(t`Grønt kryss`) : i18n._(t`Rødt kryss`)}
            className={p.classes.quizResultIcon}
            src={p.correct ? correct : fail}
          />
          {p.correct ? i18n._(t`Riktig`) : i18n._(t`Galt`)}
        </span>
      )}
    </I18n>
  ),
);

export const QuizSolution = injectSheet(style)((p: { classes: Classes }) => (
  <span className={p.classes.quizResultText}>
    <Trans>(Riktig svar)</Trans>
  </span>
));

// @flow

import * as Backend from '../backend/legacyJsonBackend';
import type { FormInput } from '../inputs/types';

export function allElements(pages: Backend.Page[]): Backend.Element[] {
  return pages.reduce((a, b) => [...a, ...b.elements], []);
}

export const allQuestionsOf: (
  Backend.Element[],
) => Backend.Question[] = elements =>
  elements.reduce((a, b) => [...a, ...b.questions], []);

export const elementForInput: (FormInput, Backend.Form) => Backend.Element = (
  input,
  form,
) => {
  const element = allElements(form.pages).find(
    element =>
      element.questions.find(
        question => question.questionId === input.questionId,
      ) != null,
  );
  if (element == null) {
    throw new Error('No question found for input ${JSON.stringify(input)}');
  }
  return element;
};

// @flow

import type { Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';

const noScrollClassName = 'noscroll';

export function* addNoScrollToBodyGenerator(): Saga<void> {
  yield call(addNoScrollToBody);
}

export function* removeNoScrollFromBodyGenerator(): Saga<void> {
  yield call(removeNoScrollFromBody);
}

export const addNoScrollToBody = () => {
  if (document.body != null) {
    document.body.classList.add(noScrollClassName);
  }
};

export const removeNoScrollFromBody = () => {
  if (document.body != null) {
    document.body.classList.remove(noScrollClassName);
  }
};

export const noScroll = {
  '@global body.noscroll': {
    overflow: 'hidden',
  },
};

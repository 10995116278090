// @flow

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import injectSheet from 'react-jss';
import * as Scroll from 'react-scroll';
import { responsive } from 'react-usit-ui';

import { moment } from '../../../../locale';
import getSettings from '../../backend/settings';
import * as colors from '../../design/colors';
import * as focus from '../../design/focusFrame';
import FontAwesomeIcon from '../../design/icons';
import type { Validation } from '../../submission-validation/types';
import { invalid } from '../../submission-validation/types';
import { AccessibleInput } from '../accessibleInputs';
import calendarIcon from './calendarIcon.svg';
import customDayPickerStyle from './customDayPickerStyle';

const parseDate = date =>
  moment(date, getSettings().DATE_FORMAT, moment.locale(), true);

const style = {
  inputs: { display: 'flex', columnGap: 8 },
  inputWithLabel: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      padding: [4, 0, 0, 4],
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
  },
  dateTimePicker: {
    display: 'flex',
  },
  timePicker: {
    minWidth: 0,
    width: 90,
  },
  dateInput: {
    minWidth: 175,
    backgroundColor: 'transparent',
  },
  dayPickerNavbar: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 22,
    padding: [24, 30, 0, 30],
    textTransform: 'capitalize',
  },
  arrowButton: {
    background: 'none',
    border: 'none',
    color: '#1c5081',
    fontSize: 18,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dateInputWithButton: {
    display: 'flex',
  },
  pickerToggleButton: {
    backgroundColor: colors.primary.light,
    margin: [2, 1, 1, -42],
    border: 'none',
    borderRadius: [0, 2, 2, 0],
    '&:hover': { cursor: 'pointer' },
    '&:focus': focus.focusFrameInsetButton,
    width: '40px',
    height: '41px',
  },

  dayPickerContainer: {
    ...customDayPickerStyle,
  },
  disabledInput: {
    backgroundColor: colors.disabledBackground,
  },
  [responsive.media.max320]: {
    dayPickerContainer: {
      '& .DayPicker-wrapper': {
        marginLeft: -18,
      },
    },
  },
};
type Classes = { [$Keys<typeof style>]: string };

type DatePickerProps = {
  id: string,
  customErrorId?: string,
  focusClass: string,
  selectedDate: ?string,
  mandatory: boolean,
  disabled: boolean,
  responsive: boolean,
  onChange: string => void,
  classes: Classes,
  validation: Validation,
  i18n: I18n,
  children?: React.Node,
};

const ArrowButton = injectSheet(style)(
  (p: {
    classes: Classes,
    direction: 'left' | 'right',
    onClick: () => void,
  }) => (
    <I18n>
      {({ i18n }) => (
        <button
          tabIndex={-1}
          onClick={() => p.onClick()}
          className={p.classes.arrowButton}
          aria-label={
            p.direction === 'left'
              ? i18n._(t`Forrige måned`)
              : i18n._(t`Neste måned`)
          }
        >
          <FontAwesomeIcon icon={`arrow-${p.direction}`} />
        </button>
      )}
    </I18n>
  ),
);

const DayPickerNavbar = injectSheet(style)(
  (p: {
    onPreviousClick: () => void,
    onNextClick: () => void,
    month: Date,
    localeUtils: { formatMonthTitle: (date: Date, locale: string) => string },
    classes: Classes,
  }) => (
    <div className={p.classes.dayPickerNavbar}>
      <ArrowButton direction="left" onClick={p.onPreviousClick} />
      <span>{p.localeUtils.formatMonthTitle(p.month, moment.locale())}</span>
      <ArrowButton direction="right" onClick={p.onNextClick} />
    </div>
  ),
);

const getPlausibleMilleniumFromYearShorthand = (
  yearShortHand: string,
  currentYear: number,
) => (parseInt(yearShortHand) + 2000 <= currentYear ? '20' : '19');

// Guesstimates the proper format of a date string, i.e:
// * 240800 -> 24.08.2000
// * 240892 -> 24.08.1992
// * 24082000 -> 24.08.2000
// * 2408 -> 24.08.2023 (could also be 02.04.2008, but this is not how it is implemented)
//
// ( this will fail after the year 2100. Should be fine? )
const guesstimateDateFormat = (dateString: string): string => {
  // Is valid-ish enough date format for the guesstimate
  if (
    (dateString.length == 10 && dateString.split('.').length === 3) ||
    dateString.length == 0
  )
    return dateString;
  const currentDate = new Date(Date.now());
  const currentYear = currentDate.getFullYear();
  let day, month, fullYear;

  if (dateString.includes('.')) {
    const dateBits = dateString.split('.');
    day = dateBits[0] || currentDate.getDate();
    month = dateBits[1] || currentDate.getMonth() + 1;
    // Determine the year of the input, defaulting to current year
    fullYear = ((yearArg: string) => {
      const yearShortHand = ((sclicedYear: string) =>
        sclicedYear.length === 1 ? '0' + sclicedYear : sclicedYear)(
        yearArg.slice(-2),
      );
      return yearArg.length > 2
        ? yearArg
        : getPlausibleMilleniumFromYearShorthand(yearShortHand, currentYear) +
            yearShortHand;
    })(dateBits[2] || currentYear.toString());
  } else {
    day = dateString.slice(0, 2);
    month = dateString.slice(2, 4);
    // assume the current year for four digit dates, e.g. 2408 -> 24.08.2023
    fullYear =
      dateString.length < 6 ? currentYear.toString() : dateString.slice(4, 8);
    // length 6 means the user provided a shorthand for the year. Prefix with 20 or 19.
    if (dateString.length == 6) {
      const yearShortHand = dateString.slice(4, 6);
      fullYear =
        getPlausibleMilleniumFromYearShorthand(yearShortHand, currentYear) +
        yearShortHand;
    }
  }
  return `${day}.${month}.${fullYear}`;
};

type DatePickerState = { showPicker: boolean };

class DatePicker_ extends React.Component<DatePickerProps, DatePickerState> {
  inputDiv: ?HTMLDivElement;
  constructor() {
    super();
    this.state = { showPicker: false };
  }
  togglePicker = () => {
    this.setState(state => ({ showPicker: !state.showPicker }));
  };
  setTextInputValue = () => {
    /* DOM-hacking this instead of controlling the `input` component
    directly, since we rely on `onBlur` to get the value of the user
    input. With a controlled component we'd have to use `onChange`,
    which would a) trigger validation errors during typing, and b)
    be noticeably slower. */
    if (this.inputDiv == null) {
      return;
    }
    const textInput: any = this.inputDiv.querySelector(`input`);
    if (textInput == null) {
      throw new Error('Input component not found');
    }
    if ((textInput: HTMLInputElement).value !== this.props.selectedDate) {
      textInput.value = this.props.selectedDate || '';
    }
  };
  componentDidUpdate(prevProps: DatePickerProps, prevState: DatePickerState) {
    this.setTextInputValue();
    if (
      this.props.responsive &&
      this.state.showPicker &&
      !prevState.showPicker
    ) {
      Scroll.scroller.scrollTo(`container-${this.props.id}`, {
        smooth: true,
        offset: -42,
      });
    }
  }
  onFocus = event => {
    if (event.target.className === 'DayPicker-wrapper') {
      if (
        event.relatedTarget &&
        event.relatedTarget.className.includes('DayPicker-Day')
      ) {
        this.focusField();
      } else {
        this.focusDate();
      }
    }
  };
  focusDate = () => {
    const splitId = this.props.id.split('-');
    const id = splitId[splitId.length - 2] + '-' + splitId[splitId.length - 1];
    const label = document.querySelector(`label[for="${id}"]`);
    const selected = label && label.querySelector('.DayPicker-Day--selected');
    if (selected) {
      selected.focus();
    } else {
      const today = label && label.querySelector('.DayPicker-Day--today');
      today && today.focus();
    }
  };
  focusField = () => {
    const splitId = this.props.id.split('-');
    if (splitId[0] === 'date') {
      const id = `time-${splitId[1]}-${splitId[2]}`;
      const timeField = document.getElementById(id);
      timeField && timeField.focus();
    } else {
      const label = document.querySelector(`label[for="${this.props.id}"]`);
      const toggleButton = label && label.querySelector('button');
      toggleButton && toggleButton.focus();
    }
  };
  render() {
    const settings = getSettings();
    const p = this.props;
    const fieldSize = settings.DATE_FORMAT.length;
    const inputDateIsValid = parseDate(p.selectedDate).isValid();
    const selectedDays = inputDateIsValid
      ? [parseDate(p.selectedDate).toDate()]
      : [];
    const containerName = `container-${p.id}`;
    const datePickerId = `${p.id}-date-picker`;
    return (
      <Scroll.Element name={containerName}>
        <div className={p.classes.inputs}>
          <div className={p.classes.inputWithLabel}>
            <label htmlFor={p.id}>
              <Trans>Dato (dd.mm.åååå)</Trans>
            </label>
            <div
              className={p.classes.dateInputWithButton}
              ref={ref => {
                this.inputDiv = ref;
              }}
            >
              <AccessibleInput
                id={p.id}
                customErrorId={p.customErrorId}
                type="text"
                disabled={p.disabled}
                defaultValue={this.props.selectedDate || ''}
                aria-required={p.mandatory.toString()}
                maxLength={fieldSize}
                size={fieldSize}
                validation={p.validation}
                onChange={event => {
                  if (
                    (invalid(p.validation) &&
                      event.target.value.length ===
                        settings.DATE_FORMAT.length) ||
                    event.target.value.length === settings.DATE_FORMAT.length
                  ) {
                    p.onChange(event.target.value);
                  }
                }}
                onBlur={event => {
                  if (event.target.value === this.props.selectedDate) return;
                  event.target.value = guesstimateDateFormat(
                    event.target.value,
                  );
                  let value = event.target.value;
                  if (
                    value.length !== settings.DATE_FORMAT.length &&
                    moment(value, 'D.M.YYYY', true).isValid()
                  ) {
                    value = moment(value, settings.DATE_FORMAT).format(
                      settings.DATE_FORMAT,
                    );
                  }
                  p.onChange(value);
                }}
                formElementId={p.id}
                className={classNames(
                  p.disabled && p.classes.disabledInput,
                  p.classes.dateInput,
                )}
              />
              {!p.disabled && (
                <I18n>
                  {({ i18n }) => (
                    <button
                      onClick={this.togglePicker}
                      className={p.classes.pickerToggleButton}
                      aria-expanded={this.state.showPicker ? 'true' : 'false'}
                      aria-controls={datePickerId}
                    >
                      <img src={calendarIcon} alt={i18n._(t`Kalender`)} />
                    </button>
                  )}
                </I18n>
              )}
            </div>
          </div>
          {p.children}
        </div>
        {this.state.showPicker && (
          <div className={p.classes.dayPickerContainer} id={datePickerId}>
            <DayPicker
              showOutsideDays
              captionElement={() => false}
              navbarElement={DayPickerNavbar}
              month={selectedDays[0]}
              selectedDays={selectedDays}
              localeUtils={MomentLocaleUtils}
              locale={moment.locale()}
              onDayClick={date => {
                const dateAsString = moment(date).format(settings.DATE_FORMAT);
                p.onChange(dateAsString);
                this.togglePicker();
              }}
              onKeyDown={event => {
                if (event.key === 'Escape') {
                  this.togglePicker();
                }
              }}
              onFocus={this.onFocus}
            />
          </div>
        )}
      </Scroll.Element>
    );
  }
}
export const DatePicker = injectSheet(style)(
  responsive.HOC(responsive.media.max640)(DatePicker_),
);

export const TimePicker = injectSheet(style)(
  (p: {
    id: string,
    customErrorId?: string,
    selectedTime: ?string,
    onChange: string => void,
    validation: Validation,
    mandatory: boolean,
    classes: Classes,
    disabled: boolean,
    focusElement?: boolean,
  }) => {
    const settings = getSettings();
    return (
      <div className={p.classes.inputWithLabel}>
        <label htmlFor={p.id}>
          <Trans>Tid (tt:mm)</Trans>
        </label>
        <AccessibleInput
          focusElement={p.focusElement}
          id={p.id}
          customErrorId={p.customErrorId}
          type="text"
          disabled={p.disabled}
          data-textinputtype="time"
          defaultValue={p.selectedTime || ''}
          aria-required={p.mandatory.toString()}
          size={5}
          maxLength={5}
          onChange={event => {
            if (
              invalid(p.validation) ||
              event.target.value.length === settings.TIME_FORMAT.length
            ) {
              p.onChange(event.target.value);
            }
          }}
          onBlur={event => {
            const val = event.target.value;
            if (!val.includes(':') && val.length > 0) {
              event.target.value = val.slice(0, 2) + ':' + val.slice(2);
            }
            p.onChange(event.target.value);
          }}
          formElementId={p.id}
          validation={p.validation}
          className={p.classes.timePicker}
        />
      </div>
    );
  },
);
type DateTimePickerProps = {
  id: string,
  mandatory: boolean,
  disabled: boolean,
  selectedDate: ?string,
  selectedTime: ?string,
  onChange: (string, string) => void,
  validation: Validation,
  classes: Classes,
};
class DateTimePicker_ extends React.Component<
  DateTimePickerProps,
  { selectedDate: ?string, selectedTime: ?string },
> {
  constructor(props: DateTimePickerProps) {
    super(props);
    const { selectedDate, selectedTime } = this.props;
    this.state = { selectedDate, selectedTime };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedDate === null &&
      prevProps.selectedTime === null &&
      this.props.selectedDate !== null &&
      this.props.selectedTime !== null
    ) {
      this.setState(
        {
          selectedTime: this.props.selectedTime,
          selectedDate: this.props.selectedDate,
        },
        this.propagateValues,
      );
    }
  }
  onChangeDate = (newDate: string) => {
    this.setState({ selectedDate: newDate }, this.propagateValues);
  };
  onChangeTime = (newTime: string) => {
    this.setState({ selectedTime: newTime }, this.propagateValues);
  };
  propagateValues = () => {
    if (this.state.selectedDate != null || this.state.selectedTime != null) {
      this.props.onChange(
        this.state.selectedDate || '',
        this.state.selectedTime || '',
      );
    }
  };
  render() {
    const p = this.props;
    return (
      <div className={p.classes.dateTimePicker}>
        <DatePicker
          id={`date-${p.id}`}
          customErrorId={p.id}
          focusClass={p.id}
          mandatory={p.mandatory}
          selectedDate={this.state.selectedDate}
          onChange={this.onChangeDate}
          validation={p.validation}
          disabled={p.disabled}
        >
          <TimePicker
            id={`time-${p.id}`}
            customErrorId={p.id}
            focusElement={false}
            mandatory={p.mandatory}
            selectedTime={this.state.selectedTime}
            onChange={this.onChangeTime}
            validation={p.validation}
            disabled={p.disabled}
          />
        </DatePicker>
      </div>
    );
  }
}

export const DateTimePicker = injectSheet(style)(DateTimePicker_);

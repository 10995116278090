// @flow
// $TSConversion

// import * as Sentry from '@sentry/browser';

export function logError(error: Error | string) {
  const errorObject = typeof error === 'string' ? new Error(error) : error;
  console.error(errorObject);

  // Sentry.withScope(scope => {
  //   if (extraInfo != null) {
  //     const presentExtraInfo = extraInfo;
  //     Object.keys(presentExtraInfo).forEach(key => {
  //       scope.setExtra(key, presentExtraInfo[key]);
  //     });
  //   }
  //   Sentry.captureException(errorObject);
  // });
}

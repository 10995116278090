import * as Sentry from '@sentry/browser';
import axe from 'axe-core';
import { result } from 'lodash';
import * as React from 'react';
import { Store } from 'redux';

import { Nettskjema } from './nettskjema';

export function createEntryPoint(
  RootComponent: React.ComponentType,
  store?: Store,
  elementId: string = 'react-content',
) {
  // TODO: Kommenterer ut fordi sentry.uio.no blir faset ut for sentry2.uio.no, og det er ikke aktivt brukt i dag
  // const sentryProxyDsn = `${window.location.protocol}//nettskjema@${
  //   window.location.host
  // }${Nettskjema.getContextPath()}/sentry/respondent`;
  // Sentry.init({
  //   dsn: sentryProxyDsn,
  //   integrations: [
  //     new Sentry.Integrations.Breadcrumbs({
  //       console: false,
  //       dom: true,
  //       fetch: true,
  //       history: true,
  //       sentry: true,
  //       xhr: true,
  //     }),
  //   ],
  // });
  if (
    window.location.search &&
    window.location.search.includes('?forceSentryCrash=true')
  ) {
    throw new Error('hi sentry');
  }

  const ReactDOM = require('react-dom');
  const { Provider } = require('react-redux');
  const domNode = document.getElementById(elementId);
  const element =
    store == null ? (
      <RootComponent />
    ) : (
      <Provider store={store}>
        <RootComponent />
      </Provider>
    );
  if (domNode != null) {
    ReactDOM.render(element, domNode);
  } else {
    throw new Error(
      `React container node not found  ${elementId} : ${element}`,
    );
  }
}

// @flow

import ValidationSequence from './ValidationSequence';

const isValidEmail = value =>
  /^\s*[^@\s]+@[^.@\s]+(\.+[^.@\s]+)+\s*$/.test(value);

const validEmail = (value: string) =>
  ValidationSequence().when(!isValidEmail(value), {
    includeError: 'invalid_email',
  });

export default validEmail;

// @flow

import * as React from 'react';

import { UploadedImage } from '../backend/resources';
import Image from './Image';

type ImageURL = string;
type ImageObject = { imageId: string };

const ReferencedImage = (p: {
  altText?: ?string,
  imageUrl?: ?ImageURL,
  image?: ?ImageObject,
}) => {
  if (p.image != null) {
    return <UploadedImage fileName={p.image.imageId} alt={p.altText || ''} />;
  } else if (p.imageUrl != null) {
    return <Image src={p.imageUrl} alt={p.altText || ''} />;
  } else {
    return false;
  }
};

export default ReferencedImage;

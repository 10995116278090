//@flow
import type { ModalAction } from './actions';

export const initialState = {
  modalType: null,
  modalProps: {},
};

export type ModalState = {
  modalType: ?string,
  modalProps: Object,
};

export function modalReducer(
  state: ModalState = initialState,
  action: ModalAction<string>,
) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case 'HIDE_MODAL':
      return initialState;
    default:
      return state;
  }
}

export default modalReducer;

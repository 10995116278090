// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useDispatch } from 'react-redux';
import { Button, responsive } from 'react-usit-ui';

import { createModal } from '../../../components/modal/Modal';
import { ns } from '../design/colors';
import Nettverksbrudd from './nettverksbrudd.inline.svg';
import Spinner from './spinner.inline.svg';

const style = {
  controls: {
    paddingTop: '36px',
    paddingBottom: '52px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: 50,
    width: 180,
  },
  heading: {
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: 1.28,
  },
  text: {
    fontSize: 18,
    lineHeight: 2.06,
  },
  failedImage: {
    height: 206,
    width: 216,
    alignSelf: 'center',
    marginTop: 15,
  },
  spinner: {
    height: 72,
    width: 72,
    marginTop: 150,
    marginBottom: 37,
    alignSelf: 'center',
  },
  loadingText: {
    fontSize: 26,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  errorMessage: {
    fontSize: 18,
    lineHeight: 2.06,
  },

  [responsive.media.max640]: {
    controls: {
      display: 'block',
    },
    button: {
      display: 'block',
      width: '100%',
      margin: [0, 0, 20, 0],
    },
  },
};

const customModalStyle = {
  dialog: {
    zIndex: 199,
    maxWidth: '530px',
    minWidth: '350px',
    minHeight: '430px',
    backgroundColor: '#ffffff',
    boxShadow: '0 10px 40px 0 rgba(204, 204, 204, 0.5)',
    borderRadius: '8px',
  },
  body: {
    color: ns.black,
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: 392,
    margin: '0 69px',
  },
  responsive: {
    dialog: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      border: 'none',
      borderRadius: 0,
      overflow: 'scroll',
    },
  },
};

export const RetryModal = injectSheet(style)(
  (p: {
    modalProps: {
      retryType:
        | 'submit'
        | 'submitAttachment'
        | 'loading'
        | 'postpone'
        | 'generic',
      text?: string,
      message?: string,
      newDelivery?: boolean,
    },
    classes: Object,
  }) => {
    const dispatch = useDispatch();
    const abortAction = () => dispatch({ type: 'HIDE_MODAL' });
    const CustomModal = createModal();
    let ModalContent = null;
    if (p.modalProps.retryType === 'generic') {
      ModalContent = (
        <GenericRetryModal
          newDelivery={p.modalProps.newDelivery}
          abortAction={abortAction}
          classes={p.classes}
          dispatch={dispatch}
          text={p.modalProps.text}
          message={p.modalProps.message}
        />
      );
    } else if (p.modalProps.retryType === 'submit') {
      ModalContent = (
        <SubmitContent
          newDelivery={p.modalProps.newDelivery}
          abortAction={abortAction}
          classes={p.classes}
          dispatch={dispatch}
        />
      );
    } else if (p.modalProps.retryType === 'submitAttachment') {
      ModalContent = (
        <SubmitAttachment
          text={p.modalProps.text}
          newDelivery={p.modalProps.newDelivery}
          abortAction={abortAction}
          classes={p.classes}
          dispatch={dispatch}
        />
      );
    } else if (p.modalProps.retryType === 'postpone') {
      ModalContent = (
        <PostponeContent
          abortAction={abortAction}
          classes={p.classes}
          dispatch={dispatch}
        />
      );
    } else if (p.modalProps.retryType === 'loading') {
      ModalContent = (
        <Loadingcontent classes={p.classes} text={p.modalProps.text} />
      );
    } else {
      throw new Error('Invalid modal type');
    }
    return (
      <CustomModal
        customModalStyle={customModalStyle}
        abortAction={abortAction}
        mainContent={ModalContent}
      />
    );
  },
);

const ActionFailedContent = (p: {
  classes: Object,
  heading: Trans,
  text?: string | React.Element<Trans>,
  message?: string,
  abortAction: () => void,
  retryAction: () => void,
}) => (
  <>
    <Nettverksbrudd className={p.classes.failedImage} />
    <span className={p.classes.heading}>{p.heading}</span>
    {p.message && (
      <div
        className={p.classes.errorMessage}
        dangerouslySetInnerHTML={{
          __html: p.message,
        }}
      ></div>
    )}
    {!p.message && p.text && (
      <div className={p.classes.errorMessage}>{p.text}</div>
    )}
    <div className={p.classes.controls}>
      <Button
        className={p.classes.button}
        type="secondary"
        onClick={p.abortAction}
      >
        <Trans>Avbryt</Trans>
      </Button>
      <Button
        className={p.classes.button}
        type="primary"
        onClick={p.retryAction}
      >
        Prøv igjen
      </Button>
    </div>
  </>
);

const GenericRetryModal = (p: {
  abortAction: () => void,
  classes: Object,
  dispatch: Object => void,
  newDelivery?: boolean,
  text?: string,
  message?: string,
}) => {
  return (
    <I18n>
      {({ i18n }) => (
        <ActionFailedContent
          classes={p.classes}
          heading={<Trans>Svaret ditt kunne ikke leveres</Trans>}
          message={p.message}
          text={<Trans>Det har oppstått en ukjent feil: {p.text}</Trans>}
          abortAction={p.abortAction}
          retryAction={() => {
            p.dispatch({
              type: 'SHOW_MODAL',
              modalType: 'retry',
              modalProps: {
                retryType: 'loading',
                text: i18n._(t`Sender svar`) + ' …',
              },
            });
            p.dispatch({ type: 'INCREMENT_RETRIES' });
            p.dispatch(
              p.newDelivery
                ? { type: 'SUBMIT_FORM_AS_JSON_REQUESTED' }
                : { type: 'SUBMIT_FORM_REQUESTED' },
            );
          }}
        />
      )}
    </I18n>
  );
};

const SubmitContent = (p: {
  abortAction: () => void,
  classes: Object,
  dispatch: Object => void,
  newDelivery?: boolean,
}) => {
  return (
    <I18n>
      {({ i18n }) => (
        <ActionFailedContent
          classes={p.classes}
          heading={<Trans>Svaret ditt kunne ikke leveres</Trans>}
          text={<Trans>Sjekk internettkoblingen din og prøv på nytt.</Trans>}
          abortAction={p.abortAction}
          retryAction={() => {
            p.dispatch({
              type: 'SHOW_MODAL',
              modalType: 'retry',
              modalProps: {
                retryType: 'loading',
                text: i18n._(t`Sender svar`) + ' …',
              },
            });
            p.dispatch({ type: 'INCREMENT_RETRIES' });
            p.dispatch(
              p.newDelivery
                ? { type: 'SUBMIT_FORM_AS_JSON_REQUESTED' }
                : { type: 'SUBMIT_FORM_REQUESTED' },
            );
          }}
        />
      )}
    </I18n>
  );
};

const SubmitAttachment = (p: {
  abortAction: () => void,
  classes: Object,
  dispatch: Object => void,
  newDelivery?: boolean,
  text?: Trans,
}) => {
  return (
    <I18n>
      {({ i18n }) => (
        <ActionFailedContent
          classes={p.classes}
          heading={<Trans>Vedlegg kunne ikke leveres</Trans>}
          text={p.text}
          abortAction={p.abortAction}
          retryAction={() => {
            p.dispatch({
              type: 'SHOW_MODAL',
              modalType: 'retry',
              modalProps: {
                retryType: 'loading',
                text: i18n._(t`Sender vedlegg`) + ' …',
              },
            });
            p.dispatch({ type: 'INCREMENT_RETRIES' });
            p.dispatch(
              p.newDelivery
                ? { type: 'SUBMIT_FORM_AS_JSON_REQUESTED' }
                : { type: 'SUBMIT_FORM_REQUESTED' },
            );
          }}
        />
      )}
    </I18n>
  );
};

const PostponeContent = (p: {
  abortAction: () => void,
  classes: Object,
  dispatch: Object => void,
}) => {
  return (
    <I18n>
      {({ i18n }) => (
        <ActionFailedContent
          classes={p.classes}
          heading={<Trans>Svaret ditt kunne ikke lagres</Trans>}
          abortAction={p.abortAction}
          retryAction={() => {
            p.dispatch({
              type: 'SHOW_MODAL',
              modalType: 'retry',
              modalProps: {
                retryType: 'loading',
                text: i18n._(t`Lagrer svar`) + ' …',
              },
            });
            p.dispatch({ type: 'FORM_POSTPONE_REQUESTED' });
          }}
        />
      )}
    </I18n>
  );
};

const Loadingcontent = (p: { classes: Object, text?: string }) => (
  <>
    <Spinner className={p.classes.spinner} />
    <span className={p.classes.loadingText}>{p.text}</span>
  </>
);

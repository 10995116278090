// @flow

import type {
  PerformedValidation,
  ValidationError,
  ValidationErrorData,
} from './types';
import { create } from './types';

type ValidationSequenceCreator = (
  errors?: ValidationError[],
  throws?: Error[],
  skipRest?: true,
) => ValidationSequenceType;

export type ValidationSequenceType = {
  when: (
    predicate: ?boolean,
    then: {
      includeError?: string | [string, ValidationErrorData],
      doThrow?: Error,
      alsoPerform?: () => ValidationSequenceType,
      alwaysPass?: boolean,
      customError?: string,
    },
  ) => ValidationSequenceType,
  getResult: () => PerformedValidation,
  _params: { errors?: ValidationError[], throws?: Error[], skipRest?: true },
};

function hasData(error): %checks {
  return typeof error !== 'string';
}

class FatalValidationError extends Error {
  // These errors indicate an unexpected, unhandled condition. You probably don't want to catch them. Instead you should fix the code so that the condition doesn't happen.
}

class ValidationSequenceUsageError extends Error {}

const ValidationSequence: ValidationSequenceCreator = (
  errors = [],
  throws = [],
  skipRest,
) => {
  return {
    when: (predicate, args) => {
      if (skipRest) return ValidationSequence(errors, throws, true);
      if (!predicate) {
        return ValidationSequence(errors, throws);
      }
      if (args.alwaysPass) {
        return ValidationSequence([], [], true);
      }
      if (args.alsoPerform) {
        const nested = args.alsoPerform();
        return ValidationSequence(
          nested._params.errors
            ? [...errors, ...nested._params.errors]
            : errors,
          nested._params.throws
            ? [...throws, ...nested._params.throws]
            : throws,
          skipRest || nested._params.skipRest,
        );
      }
      if (args.doThrow) {
        return ValidationSequence(errors, [...throws, args.doThrow]);
      }
      if (args.includeError) {
        return ValidationSequence(
          [
            ...errors,
            hasData(args.includeError)
              ? create.error(args.includeError[0], args.includeError[1])
              : create.error(args.includeError),
          ],
          throws,
        );
      }
      if (args.customError) {
        return ValidationSequence([
          ...errors,
          create.customError(args.customError),
        ]);
      }
      throw new ValidationSequenceUsageError(
        '`when` is not false, but no action specified.',
      );
    },
    getResult: () => {
      if (throws.length > 0) {
        const messages = throws.map(t => t.message).join('\n');
        throw new FatalValidationError(`Errors in sequence:
                                       ${messages}`);
      }
      if (errors.length > 0) {
        return create.failing(errors);
      } else {
        return create.passing();
      }
    },
    _params: { errors, throws, skipRest },
  };
};

export default ValidationSequence;

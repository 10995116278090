// @flow
import moment_tz from 'moment-timezone';

const nettskjemaLanguageToLocale = {
  no: 'nb',
  nn: 'nn',
  en: 'en',
};

const isFormPreviewInIframe =
  'isFormPreview' in window.Nettskjema &&
  window.Nettskjema.isFormPreview &&
  window !== window.top;

export default function getCurrentLocale() {
  if ('messages' in window && !('languageCode' in window.Nettskjema)) {
    // used in legacy frontend pages. Returns 'no' for bokmål.
    return nettskjemaLanguageToLocale[window.messages.languageCode];
  }
  if (isFormPreviewInIframe) {
    return nettskjemaLanguageToLocale[window.top.messages.languageCode];
  }
  if ('languageCode' in window.Nettskjema) {
    // used in the new respondent frontend. Returns 'nb' for bokmål.
    return window.Nettskjema.languageCode;
  }
  throw new Error('Unable to determine current language');
}

moment_tz.locale(getCurrentLocale());

export const moment = moment_tz;

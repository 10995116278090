// @flow

import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* NETTSKJEMA-5992: Fjerner 'font-size: inherit' fra fontawesome CSS
som skaper problemer med skalering av svg i Safari */
config.autoAddCss = false;
const css = dom.css().replace('font-size: inherit;', '');
const head = document.head;
const style = document.createElement('style');

style && style.appendChild(document.createTextNode(css));
head && head.appendChild(style);

library.add(
  faArrowLeft,
  faArrowRight,
  faCheckSquare,
  faSquare,
  faCircle,
  faDotCircle,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faTimes,
);

export default FontAwesomeIcon;

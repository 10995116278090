// @flow

import { applyMiddleware, compose, createStore } from 'redux';
import type { Reducer } from 'redux';
import type { Saga } from 'redux-saga';
import createSagaMiddleware from 'redux-saga';

import { isProduction } from './environment';

const composeEnhancers =
  (!isProduction &&
    window != null &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

export const createNettskjemaStore = <S>(args: {
  appReducer: Reducer<any, any>,
  initialState?: S,
  rootSaga: (...args: any) => Saga<any>,
}) => {
  const store = createStore(
    args.appReducer,
    args.initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  sagaMiddleware.run(args.rootSaga);
  return store;
};

export default createNettskjemaStore;

// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';

import * as Backend from '../../backend/legacyJsonBackend';
import * as colors from '../../design/colors/index';
import { focusClassNameFromStringId } from '../../design/focusFrame';
import { underlineButtonLink } from '../../design/focusFrame';
import { QuizMark, QuizSolution } from '../multiple-choice/quizScores';
import collapse from './collapse.svg';
import type { DropdownProps } from './dropdown';
import expand from './expand.svg';
import { rootStyle } from './style';

export type Correction = { correct: boolean };

const style = (theme: Object) => ({
  ...rootStyle(theme.typography),
  quiz: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 17px 7px 17px',
    marginBottom: '20px',
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'Bold',
    fontStyle: 'Normal',
  },
  correct: {
    borderColor: '#48ab5d !important',
    backgroundColor: ['#e6f4e9', '!important'],
  },
  incorrect: {
    borderColor: '#d6706c !important',
    backgroundColor: ['#fff0f0', '!important'],
  },
  correctButNotSelected: {
    borderColor: [colors.primary.border, '!important'],
    backgroundColor: [colors.primary.border, '!important'],
  },
  listOptions: {
    color: colors.primary.dark,
    background: `url(${expand}) no-repeat center right`,
    backgroundSize: 21,
    display: 'inline-block',
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'Bold',
    fontStyle: 'Normal',
    paddingRight: 30,
    ...underlineButtonLink,
  },
  listOptionsCollapse: {
    background: `url(${collapse}) no-repeat center right`,
    backgroundSize: 21,
  },
  title: {
    color: colors.primary.dark,
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'Bold',
    fontStyle: 'Normal',
  },
  expandButton: {
    marginLeft: '6px',
  },
  answerDescription: {
    display: 'inline-block',
    marginBottom: 10,
  },
});

type classes = { [$Keys<$Call<typeof style, Object>>]: string };
type Props = DropdownProps & { classes: classes };

const DropdownQuizResult = injectSheet(style)((p: Props) => {
  const className = classNames(
    p.classes.root,
    {
      [p.classes.invalid]: p.invalid,
    },
    p.classes.quiz,
    getCorrectionClass(p.correction, p.classes),
    focusClassNameFromStringId(p.id),
  );
  const answer =
    p.selectedOptionId &&
    p.answerOptions.find(
      option => option.answerOptionId === p.selectedOptionId,
    );
  const correctAnswer = p.answerOptions.find(
    option => option.answerOptionId === p.correctAnswer,
  );
  const otherAnswerOptions = p.answerOptions.filter(
    option =>
      option.answerOptionId !== p.correctAnswer &&
      option.answerOptionId !== p.selectedOptionId,
  );
  const shouldShowSolution =
    p.correctAnswer && p.correctAnswer !== p.selectedOptionId;

  return (
    <React.Fragment>
      {answer && (
        <React.Fragment>
          <span className={classNames(p.classes.answerDescription)}>
            <Trans>Du svarte</Trans>:
          </span>
          <div className={className}>
            {answer.text}
            {p.correction && <QuizMark correct={p.correction.correct} />}
          </div>
        </React.Fragment>
      )}
      {shouldShowSolution && <CorrectAnswerElement correct={correctAnswer} />}
      {otherAnswerOptions.length > 0 && (
        <OtherAnswerOptions answerOptions={otherAnswerOptions} />
      )}
    </React.Fragment>
  );
});

const CorrectAnswerElement = injectSheet(style)(
  (p: { classes: classes, correct: Backend.AnswerOption }) => {
    const className = classNames(
      p.classes.root,
      p.classes.quiz,
      p.classes.correctButNotSelected,
    );
    return (
      <React.Fragment>
        <span className={classNames(p.classes.answerDescription)}>
          <Trans>Riktig svar</Trans>:
        </span>
        <div className={className}>
          <span>{p.correct.text} </span>
          <QuizSolution />
        </div>
      </React.Fragment>
    );
  },
);

const OtherAnswerOptions = injectSheet(style)(
  (p: { classes: any, answerOptions: Backend.AnswerOption[] }) => {
    //$FlowIssue React hooks not supported in the version of flow (0.78.0)
    const [showAnswerOptions, setToggleState] = React.useState(false);
    const toggleShowOptions = () => setToggleState(!showAnswerOptions);
    const keyEvent = e => {
      e.preventDefault();
      toggleShowOptions();
    };
    const listClass = classNames(p.classes.listOptions, {
      [p.classes.listOptionsCollapse]: showAnswerOptions,
    });
    return (
      <React.Fragment>
        <div
          role="button"
          onClick={toggleShowOptions}
          className={listClass}
          onKeyPress={keyEvent}
          tabIndex={0}
          aria-expanded={showAnswerOptions}
        >
          <Trans>De andre svaralternativene</Trans>
        </div>
        <ul>
          {showAnswerOptions &&
            p.answerOptions.map(elem => (
              <li key={elem.answerOptionId}>{elem.text}</li>
            ))}
        </ul>
      </React.Fragment>
    );
  },
);

const getCorrectionClass = (correction: ?Correction, classes: classes) => {
  if (!correction) {
    return {};
  } else if (correction.correct) {
    return classes.correct;
  } else if (!correction.correct) {
    return classes.incorrect;
  }
};

export default DropdownQuizResult;

// @flow

import { Trans } from '@lingui/macro';
import * as React from 'react';
import injectSheet from 'react-jss';
import { Heading } from 'react-usit-ui';

import * as Backend from '../../backend/legacyJsonBackend';
import { ConnectedAllPages } from '../../form/AllPages';

const style = {
  quizResult: { margin: [40, 0] },
  score: { fontSize: 22 },
};
type Classes = { [$Keys<typeof style>]: string };

export const Score = injectSheet(style)(
  (p: { classes: Classes, quizFeedback: Backend.QuizFeedback }) => {
    const percentageScore =
      p.quizFeedback.score != null &&
      Math.round((p.quizFeedback.score / p.quizFeedback.maxScore) * 100);
    return (
      <div className={p.classes.quizResult}>
        <Heading level={2}>
          <Trans>Poengsum</Trans>
        </Heading>
        <p className={p.classes.score}>
          <Trans>
            {p.quizFeedback.score} riktige av {p.quizFeedback.maxScore} (
            {percentageScore} %)
          </Trans>
        </p>
      </div>
    );
  },
);

export const QuizFeedback = (p: { quizFeedback: Backend.QuizFeedback }) => {
  return (
    <React.Fragment>
      {p.quizFeedback.score != null && <Score {...p} />}
      <ConnectedAllPages disabled />
    </React.Fragment>
  );
};

// @flow

export type ValidationErrorData = { [string]: string | number };

export type ValidationError = {
  code: string,
  data?: ValidationErrorData,
  customError?: string,
};

export type PerformedValidation = {
  performed: true,
  errors: ValidationError[],
};
type UnperformedValidation = { performed: false };

export type Validation = PerformedValidation | UnperformedValidation;

export type BackendValidationError = { questionId: number, message: string };

export function invalid(validation: Validation): boolean {
  return validation.performed && validation.errors.length > 0;
}

export const create = {
  unperformed(): UnperformedValidation {
    return { performed: false };
  },
  passing(): PerformedValidation {
    return { performed: true, errors: [] };
  },
  error(code: string, data?: ValidationErrorData): ValidationError {
    return { code, data };
  },
  customError(message: string) {
    return { code: 'custom_error', customError: message };
  },
  failing(reason: ValidationError[] | ValidationError): PerformedValidation {
    return {
      performed: true,
      errors: Array.isArray(reason) ? reason : [reason],
    };
  },
};

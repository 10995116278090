// @flow

import type { Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';

export function alertDataMayBeLost(e: Event & { returnValue: string }) {
  // Trigger the browser default 'onbeforeunload'. Modern browsers don't allow customizing the message here.
  e.preventDefault();
  e.returnValue = '';
  return '';
}

function assignUrlToLocation(url: string) {
  window.location.assign(url);
}

export function* removeAlertDataMayBeLost(): Saga<void> {
  yield call(
    [window, window.removeEventListener],
    'beforeunload',
    alertDataMayBeLost,
  );
}

export function* redirectToUrl(url: string): Saga<void> {
  yield call(removeAlertDataMayBeLost);
  yield call(assignUrlToLocation, url);
}

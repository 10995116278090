// @flow

import { Trans } from '@lingui/macro';
import * as React from 'react';

import Image from '../question-elements/Image';
import getSettings from './settings';

export const StaticImage = (p: {
  alt: React.Element<Trans>,
  fileName: string,
}) => (
  <Image
    alt={p.alt}
    src={`${getSettings().CONTEXT_PATH}/static/images/${p.fileName}`}
  />
);

export const UploadedImage = (p: { alt: string, fileName: string }) => (
  <Image
    alt={p.alt}
    src={`${getSettings().CONTEXT_PATH}/image/${p.fileName}`}
  />
);

export const Link = (p: { relativeHref: string, children: React.Node }) => (
  <a href={absoluteUrl(p.relativeHref)}>{p.children}</a>
);

export const absoluteUrl = (relativeUrl: string) =>
  `${getSettings().CONTEXT_PATH}${relativeUrl}`;

export const logoutUrl = absoluteUrl(
  getSettings().authenticatedInfo.logoutLink,
);

export const displayName = getSettings().authenticatedInfo.displayName;

export const isAuthenticated = getSettings().authenticatedInfo.isAuthenticated;

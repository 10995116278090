// @flow

import ValidationSequence from './ValidationSequence';

const validCheckboxes = (
  value: number[],
  properties: {
    maxNumberOfSelected: ?number,
  },
) => {
  return validNonEmptyCheckboxes(properties.maxNumberOfSelected, value);
};

const validNonEmptyCheckboxes = (maxNumberOfSelected, value) =>
  ValidationSequence().when(
    maxNumberOfSelected != null &&
      maxNumberOfSelected > 0 &&
      value.length > maxNumberOfSelected,
    {
      includeError: [
        'you_can_only_select',
        { n: ((maxNumberOfSelected: any): string) },
      ],
    },
  );

export default validCheckboxes;

// @flow

import * as Backend from '../backend/legacyJsonBackend';
import type { InputValue } from '../inputs/types';
import { structure } from '../inputs/types';

function possiblyContainsType(value: InputValue, type: string): %checks {
  return value.type === structure.notProvided.type || value.type === type;
}

export function attachment(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    elementType === 'ATTACHMENT' &&
    possiblyContainsType(value, structure.file.type)
  );
}

export function storedAttachment(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    elementType === 'ATTACHMENT' &&
    possiblyContainsType(value, structure.storedFile.type)
  );
}

export function date(
  value: InputValue,
  elementType: Backend.ElementType,
  dateFormat: Backend.DateFormat,
): %checks {
  return (
    elementType === 'DATE' &&
    dateFormat === 'DATE' &&
    possiblyContainsType(value, structure.date.type)
  );
}

export function dateTime(
  value: InputValue,
  elementType: Backend.ElementType,
  dateFormat: Backend.DateFormat,
): %checks {
  return (
    elementType === 'DATE' &&
    dateFormat === 'DATE_TIME' &&
    possiblyContainsType(value, structure.dateTime.type)
  );
}

export function time(
  value: InputValue,
  elementType: Backend.ElementType,
  dateFormat: Backend.DateFormat,
): %checks {
  return (
    elementType === 'DATE' &&
    dateFormat === 'TIME' &&
    possiblyContainsType(value, structure.time.type)
  );
}

export function dropdown(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    elementType === 'SELECT' &&
    possiblyContainsType(value, structure.singleOption.type)
  );
}

export function radio(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    elementType === 'RADIO' &&
    possiblyContainsType(value, structure.singleOption.type)
  );
}

export function checkbox(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    elementType === 'CHECKBOX' &&
    possiblyContainsType(value, structure.multipleOptions.type)
  );
}

export function slider(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return elementType === 'LINEAR_SCALE_SLIDER';
}

export function linearScaleRadio(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return elementType === 'LINEAR_SCALE_RADIO';
}

export function textInput(
  value: InputValue,
  elementType: Backend.ElementType,
): %checks {
  return (
    [
      'QUESTION',
      'QUESTION_MULTILINE',
      'NATIONAL_ID_NUMBER',
      'IDPORTEN_NATIONAL_ID',
      'SUBMISSION_REFERENCE',
      'EMAIL',
      'NUMBER',
      'PHONE',
      'USERNAME',
      'NAME',
    ].includes(elementType) && possiblyContainsType(value, 'TEXT')
  );
}

// @flow

import type { I18n as I18nType } from '@lingui/core';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
import { responsive } from 'react-usit-ui';

import * as colors from '../design/colors';
import {
  errorFocusFrameNoBorder,
  focusFrameNoBorder,
} from '../design/focusFrame';
import { textInputHeight } from '../question-elements/accessibleInputs';
import { invalid } from '../submission-validation/types';
import type {
  Validation,
  ValidationError,
} from '../submission-validation/types';
import validationErrorTexts from '../submission-validation/validationErrorTexts';

const labelClassName = (className: string, invalid: boolean) =>
  classNames([className, invalid && 'invalid']);

const style = {
  optionGroup: { marginTop: 10 },
  textField: {
    minWidth: 322,
    height: [45, '!important'],
  },
  field: {
    maxWidth: '100%',
  },
  validatedField: {
    display: 'flex',
    '& input, & textarea': {
      borderWidth: '2px',
      borderRadius: 4,
      fontSize: 18,
    },
    '& input:focus, & textarea:focus': { ...focusFrameNoBorder },
    '&.invalid input, &.invalid textarea': {
      border: `solid 2px ${colors.warning.dark}`,
      '&:focus': errorFocusFrameNoBorder,
    },
  },
  fieldError: {
    backgroundColor: colors.warning.light,
    border: `solid 2px ${colors.warning.lighter}`,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    color: colors.ns.black,
    fontSize: 18,
    minWidth: 265,
    maxWidth: 397,
    minHeight: textInputHeight,
    borderRadius: 4,
    '& ul': { margin: '15px 0' },
    '& ul li': { margin: '5px 10px 5px 25px' },
  },
  inputFieldError: {
    marginLeft: 7,
  },
  matrixFieldError: {
    flex: 1,
    maxWidth: '100%',
  },
  [responsive.media.max640]: {
    field: {},
    textField: {},
    validatedField: {
      display: 'block',
      width: '100%',
      '& input, & select': {
        width: '100%',
      },
    },
    fieldError: {
      border: `solid 2px ${colors.warning.lighter}`,
      borderRadius: 4,
      width: '100%',
      maxWidth: '100%',
      marginBottom: 20,
      padding: 10,
    },
    inputFieldError: {
      marginLeft: 0,
    },
  },
};
type Classes = { [$Keys<typeof style>]: string };

export const ValidatedField = injectSheet(style)(
  responsive.HOC(responsive.media.max640)(
    (p: {
      validation: Validation,
      children: React.Node,
      fieldErrorId: string,
      classes: Classes,
      responsive: boolean,
    }) => {
      const ValidationErrorsElement = (
        <AnyValidationErrors
          validation={p.validation}
          formElementId={p.fieldErrorId}
          nextToInput
        />
      );
      const ChildrenElement = (
        <div className={p.classes.field}>{p.children}</div>
      );
      return (
        <div
          className={labelClassName(
            p.classes.validatedField,
            invalid(p.validation),
          )}
        >
          {p.responsive ? (
            <React.Fragment>
              {ValidationErrorsElement}
              {ChildrenElement}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {ChildrenElement}
              {ValidationErrorsElement}
            </React.Fragment>
          )}
        </div>
      );
    },
  ),
);

export const PossiblyInvalidOptionGroup = injectSheet(style)(
  (p: {
    validation: Validation,
    children: React.Node,
    formElementId: string,
    classes: Classes,
  }) => (
    <div>
      <AnyValidationErrors
        validation={p.validation}
        formElementId={p.formElementId}
      />
      <div
        className={labelClassName(p.classes.optionGroup, invalid(p.validation))}
      >
        {p.children}
      </div>
    </div>
  ),
);

export const PossiblyInvalidQuestion = injectSheet(style)(
  (p: {
    validation: Validation,
    children: React.Node,
    formElementId: string,
    classes: Classes,
  }) => (
    <>
      <AnyValidationErrors
        validation={p.validation}
        formElementId={p.formElementId}
      />
      <div
        className={labelClassName(p.classes.optionGroup, invalid(p.validation))}
      >
        {p.children}
      </div>
    </>
  ),
);

const AnyValidationErrors = (p: {
  validation: Validation,
  formElementId: string,
  nextToInput?: boolean,
}) => {
  if (!p.validation.performed || p.validation.errors.length === 0) return false;
  return (
    <ValidationErrors
      nextToInput={p.nextToInput}
      errors={p.validation.errors}
      formElementId={p.formElementId}
    />
  );
};

export const ValidationErrors = injectSheet(style)(
  (p: {
    errors: ValidationError[],
    formElementId: string,
    classes: Classes,
    inMatrix?: boolean,
    nextToInput?: boolean,
  }) => (
    <div
      className={classNames([
        p.classes.fieldError,
        p.nextToInput && p.classes.inputFieldError,
        p.inMatrix && p.classes.matrixFieldError,
      ])}
      aria-hidden={p.errors.length === 0}
      id={errorIdForField(p.formElementId)}
    >
      <I18n>
        {({ i18n }) =>
          p.errors.length === 1 ? (
            <LocalizedError error={p.errors[0]} i18n={i18n} />
          ) : (
            <ul>
              {p.errors.map(error => (
                <li key={error.code}>
                  {<LocalizedError error={error} i18n={i18n} />}
                </li>
              ))}
            </ul>
          )
        }
      </I18n>
    </div>
  ),
);

const errorIdForField = (field: string) => `${field}-error`;

export const LocalizedError = (p: {
  i18n: I18nType,
  error: ValidationError,
}) => {
  const localizer = validationErrorTexts(p.i18n)[p.error.code];
  if (localizer && !p.error.customError) {
    return localizer(p.error.data);
  } else if (p.error.customError) {
    return p.error.customError;
  }
  throw new MissingLocalization(
    `Missing localization for validation error: ${p.error.code}`,
  );
};

class MissingLocalization extends Error {}

import React from 'react';
import { useSelector } from 'react-redux';

import info from '../info-blue.svg';
import style from './style.less';

const InfoSummary = ({ element }) => {
  const pages = useSelector(state => state.answer.form.pages);
  const inputs = useSelector(state => state.answer.inputs);

  const currentPage = pages.find(page => page.elements.includes(element));

  if (!currentPage) {
    console.warn('Cannot find page');
    return null;
  }

  const elementIndex = currentPage.elements.findIndex(
    e => e.elementId === element.elementId,
  );

  if (!elementIndex) {
    console.warn('Cannot find element');
    return null;
  }

  const elementsPrecedeingInfoElement = currentPage.elements.slice(
    0,
    elementIndex,
  );
  const previousElement = currentPage.elements[elementIndex - 1];
  const latestRadioMatrixQuestion = elementsPrecedeingInfoElement.filter(
    a => a.elementType === 'MATRIX_RADIO',
  );

  if (latestRadioMatrixQuestion.length < 1) {
    console.warn('No matrix radio question on this page');
    return null;
  }

  const matrixElement =
    latestRadioMatrixQuestion[latestRadioMatrixQuestion.length - 1];

  if (!matrixElement) {
    console.warn('Element does not excist');
    return null;
  }

  if (matrixElement.elementType !== 'MATRIX_RADIO') {
    console.warn('Matrixtype is not radio');
    return null;
  }

  const isInfoBlock = matrixElement === previousElement;

  const matrixQuestions = matrixElement.questions;
  const questionIds = matrixQuestions.map(q => q.questionId);

  inputs.filter(input => questionIds.includes(input.questionId));

  const selectedValues = inputs
    .map(input => input.value.given)
    .filter(item => item !== undefined);

  const codebookValues = selectedValues.map(value => {
    const element = matrixElement.answerOptions.find(
      ao => ao.answerOptionId === value,
    );
    if (element) {
      return element.externalAnswerOptionId;
    } else {
      return '0';
    }
  });

  const values = codebookValues.map(value => parseInt(value));
  const count = values.reduce((a, b) => a + b, 0) * 7;

  if (isInfoBlock) {
    return (
      <div className={style.infoSummary}>
        <img className={style.logo} src={info} alt="" />
        <p className={style.text}>
          {`Vi har regnet ut at du bruker ${count} brødskiver og knekkebrød per uke`}
        </p>
      </div>
    );
  } else {
    return <InfoBlockTwo count={count} />;
  }
};

const InfoBlockTwo = ({ count }) => (
  <p className={style.text}>
    {`Hvor mye pålegg har du vanligvis på de ${count} brødskivene og eller knekkebrødene (viser til antall brødskiver og knekkebrød per uke beregnet i spørsmålet ovenfor)?`}
  </p>
);

export default InfoSummary;

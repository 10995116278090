import Nettskjema from '../nettskjema';

function handleResponseErrors(response: Response) {
  if (!response.ok || response.redirected) {
    throw Error(
      `${response.status} (${response.statusText}), request to ${response.url}`,
    );
  }
  return response;
}

/*  Post to old backend and handle errors. All responses is treated as errors if 
    the response is not ok, or if the response is redirected. Old backend some
    times use redirect to show error messages, while we want to handle those in
    frontend for the react apps
*/
export function post(endpoint: string, body: FormData, csrf?: string) {
  return fetch(Nettskjema.getContextPath() + endpoint, {
    credentials: 'same-origin',
    method: 'POST',
    body: body,
    headers: {
      Accept: 'application/json',
      NETTSKJEMA_CSRF_PREVENTION: csrf
        ? csrf
        : Nettskjema.getCsrfPreventionToken(),
    },
  })
    .then(handleResponseErrors)
    .then(response => response.json());
}

import { modalComponents } from './ModalRoot';

export type hide = () => {
  type: 'HIDE_MODAL';
  data: any;
};

export const hide = () => ({
  type: 'HIDE_MODAL',
});

export type show = {
  type: 'SHOW_MODAL';
  modalType: modalComponents;
  modalProps?: any;
};

export const show = (modalType: modalComponents, modalProps?: any): show => ({
  type: 'SHOW_MODAL',
  modalType,
  modalProps,
});

export type modalActions = show | hide;

// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import { responsive } from 'react-usit-ui';

import * as Backend from '../../backend/legacyJsonBackend';
import * as colors from '../../design/colors';
import {
  errorFocusFrame,
  errorFocusMobileMatrix,
  focusFrame,
} from '../../design/focusFrame';
import FontAwesomeIcon from '../../design/icons';
import * as Dimensions from '../answerOptionDimensions.js';
import ReferencedImage from '../ReferencedImage';
import * as ScreenReaderDescription from '../ScreenReaderDescription';
import type { Correction } from './OptionElementWithPossibleQuizResult';
import { QuizMark, QuizSolution } from './quizScores';

const magicClassNames = {
  // factor out the name this class since it's used in a wildcard query selector
  imageInputElementContainer: 'imageInputElementContainer',
};
const optionStyle = {
  padding: [7, 15, 7, 15],
  backgroundColor: colors.primary.light,
  borderRadius: 8,
  '&:focus, &.image-alternative:focus': focusFrame,
};
export const optionMaxWidth = 705;
export const style = {
  option: {
    ...optionStyle,
    minWidth: 460,
    maxWidth: optionMaxWidth,
    minHeight: 40,
    display: 'inline-block',
    alignItems: 'center',
    marginBottom: 5,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    cursor: 'pointer',
    '&.image-alternative:hover, &.image-alternative:focus': {
      '& .image-alternative-label': {
        backgroundColor: colors.primary.lighter,
      },
    },
  },
  hoverableOption: {
    '&:hover, &:focus': { backgroundColor: colors.primary.lighter },
  },
  optionLabel: {
    display: 'flex',
    flexDirection: ['row', '!important'],
    width: '100%',
    position: 'relative',
    alignItems: 'end',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  imageLabel: {
    alignSelf: 'flex-end',
  },
  optionLabelContent: {
    width: '100%',
  },
  optionImage: {
    marginBottom: 10,
    maxWidth: Dimensions.HORIZONTAL_MAX_WIDTH,
  },

  iconBackground: {
    width: 16,
    height: 16,
    position: 'absolute',
    background: colors.white,
  },
  radioIconBackground: {
    width: 18,
    height: 18,
    borderRadius: 50,
    position: 'absolute',
  },
  ieIconBackground: {
    marginTop: 2,
    marginLeft: 2,
  },
  ieRadioIconBackground: {
    marginTop: 1,
    marginLeft: 1,
  },
  ieFlexSVG: {
    '& svg': {
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  icon: {
    width: [21, '!important'],
    height: 21,
    color: colors.primary.dark,
    zIndex: 1,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ieIconContainer: {
    display: 'flex',
    alignSelf: 'center',
  },
  disabledIcon: {
    color: '#888888',
  },
  invalidIcon: {
    color: [colors.warning.dark, '!important'],
  },
  invalidBorder: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.warning.dark,
  },
  inputElement: { position: 'absolute', left: -9999 },
  inputElementContainer: {
    alignSelf: 'flex-start',
    height: 25,
    display: 'flex',
  },
  [magicClassNames.imageInputElementContainer]: {
    ...optionStyle,
    minHeight: '40px',
  },
  imageInputElement: {
    minHeight: 25,
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
  },
  // Astrid 03.04.2019: Denne klassen tror jeg ikke har noen effekt
  horizontalImageOptionContainer: {
    width: '100%',
    minWidth: Dimensions.HORIZONTAL_MIN_WIDTH,
    paddingTop: optionStyle.padding[0] - 2,
  },
  horizontalOptionContainer: {
    minWidth: Dimensions.HORIZONTAL_MIN_WIDTH,
    maxWidth: Dimensions.HORIZONTAL_MAX_WIDTH,
    display: 'flex',
    margin: [0, 23, 11.5, 0],
  },
  imageOption: {
    margin: [0, 20, 20, 0],
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 18,
    lineHeight: 1.39,
    color: colors.ns.black,
    marginLeft: 15,
    marginRight: 'auto',
    hyphens: 'auto',
    display: 'inline-block',
  },
  matrixOption: {
    height: 44,
    width: 80,
    backgroundColor: colors.primary.light,
    margin: 0,
    padding: 0,
    position: 'relative',
    borderRadius: 8,
    cursor: 'pointer',
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedOption: { backgroundColor: colors.primary.lighter },
  invalidOption: {
    backgroundColor: colors.warning.light,
    '&:not(.image-alternative):hover, &:not(.image-alternative):focus': {
      backgroundColor: colors.warning.lighter,
    },
    '&:not(.image-alternative):focus': {
      borderColor: 'transparent',
    },
    '&.image-alternative .image-alternative-label': {
      backgroundColor: colors.warning.light,
    },
    '&.image-alternative:hover, &.image-alternative:focus': {
      '& .image-alternative-label': {
        backgroundColor: colors.warning.lighter,
      },
    },
    '&:focus, &.image-alternative:focus': errorFocusFrame,
  },
  optionWithoutBackground: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    '&:focus': { boxShadow: 'none' },
  },
  separateOptionInput: {
    backgroundColor: colors.primary.light,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.primary.light,
    height: 60,
    minWidth: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': { alignSelf: 'center' },
    '&:hover, &:focus': {
      backgroundColor: colors.primary.lighter,
      borderColor: colors.primary.lighter,
    },
    '&:focus': focusFrame,
  },
  separateOptionInputSelected: {
    borderColor: colors.primary.border,
  },
  separateOptionInputInvalid: {
    backgroundColor: colors.warning.light,
    borderColor: colors.warning.dark,
    '&:hover, &:focus': {
      backgroundColor: colors.warning.lighter,
      borderColor: colors.warning.dark,
    },
    '&:focus': errorFocusMobileMatrix,
  },
  separateOptionText: {
    marginLeft: 14,
    color: colors.ns.black,
    fontSize: 18,
    width: 'calc(100% - 60px)',
  },
  disabled: {
    backgroundColor: colors.disabledBackground,
    '& .image-alternative-label': {
      backgroundColor: colors.disabledBackground,
    },
    '&.image-alternative:hover, &.image-alternative:focus': {
      '& .image-alternative-label': {
        backgroundColor: colors.disabledBackground,
      },
    },
  },
  [responsive.media.max640]: {
    option: { minWidth: 0, maxWidth: '100%' },
    optionLabel: { alignItems: 'center' },
  },
  correct: {
    border: ['2px', 'solid', '#48ab5d'],
    backgroundColor: ['#e6f4e9', '!important'],
  },
  incorrect: {
    border: ['2px', 'solid', '#d6706c'],
    backgroundColor: ['#fff0f0', '!important'],
  },
};

type Classes = { [$Keys<typeof style>]: string };

const onSelectorKey = (onKeyPressed: () => void) => (
  event: SyntheticKeyboardEvent<HTMLElement>,
) => {
  if (event.key === ' ') {
    event.preventDefault();
    onKeyPressed();
  }
};

class UnstyledMultipleOptions extends React.Component<{
  answerOptions: Backend.AnswerOption[],
  children: React.Node,
  horizontal: boolean,
  disabled: boolean,
  classes: Classes,
  responsive: boolean,
  labelledBy: string,
  mandatoryMark: {
    mandatory: boolean,
    useAriaRequired: boolean,
  },
  role?: string,
}> {
  divRef = null;
  componentDidMount() {
    this.onLoad();
  }
  componentDidUpdate() {
    // Component may update as `responsive` becomes true
    this.onLoad();
  }
  onLoad = reset => {
    this.harmonizeOptionWidths(reset);
    this.harmonizeOptionHeights();
  };
  harmonizeOptionWidths = reset => {
    if (this.divRef == null) {
      throw new Error('unable to obtain element reference for container');
    }
    Dimensions.harmonizeWidths(this.divRef, reset);
  };
  harmonizeOptionHeights = () => {
    if (this.divRef == null) {
      throw new Error('unable to obtain element reference for container');
    }
    const {
      divRef,
      props: { horizontal, responsive },
    } = this;
    const imageOptionContainers = divRef.querySelectorAll(
      `[class*='${magicClassNames.imageInputElementContainer}']`,
    );
    Dimensions.harmonizeHeights({
      containers: imageOptionContainers,
      horizontal,
      responsive,
    });
  };
  render() {
    const p = this.props;
    return (
      <div
        onLoad={() => this.onLoad(true)}
        ref={ref => {
          this.divRef = ref;
        }}
        role={p.role}
        aria-labelledby={p.labelledBy}
        aria-required={
          p.mandatoryMark.useAriaRequired
            ? p.mandatoryMark.mandatory.toString()
            : undefined
        }
        aria-describedby={
          p.mandatoryMark.useAriaRequired || !p.mandatoryMark.mandatory
            ? undefined
            : ScreenReaderDescription.descriptionOf(p.labelledBy)
        }
        aria-disabled={p.disabled}
        className={classNames([p.horizontal && p.classes.horizontalContainer])}
      >
        {!p.mandatoryMark.useAriaRequired && p.mandatoryMark.mandatory && (
          <ScreenReaderDescription.InvisibleComponent
            forElementId={p.labelledBy}
          >
            <Trans>Du må velge minst ett svaralternativ.</Trans>
          </ScreenReaderDescription.InvisibleComponent>
        )}
        {p.children}
      </div>
    );
  }
}

export const MultipleOptions = injectSheet(style)(UnstyledMultipleOptions);

export const answerIdString = (id: number | string) => `answer-${id}`;

export const OptionInputElement = injectSheet(style)(
  (p: {
    id: string,
    type: 'radio' | 'checkbox',
    checked: boolean,
    disabled: boolean,
    onClick: () => void,
    ariaLabel?: string,
    classes: Classes,
  }) => (
    <input
      className={p.classes.inputElement}
      key={p.id}
      id={answerIdString(p.id)}
      tabIndex={-1}
      disabled={p.disabled}
      type={p.type}
      checked={p.checked}
      value={p.id}
      aria-label={p.ariaLabel}
      onClick={event => {
        event.stopPropagation();
        p.onClick();
      }}
      onChange={event => {
        // Clicks are handled by onClick, keyboard events by enclosing div
        event.stopPropagation();
      }}
    />
  ),
);

// $FlowIgnore Flow 0.78 mangler type React.memo
export const InputIconWithBackground = React.memo(
  injectSheet(style)(
    (p: {
      type: 'radio' | 'checkbox',
      checked: boolean,
      classes: Object,
      disabled: boolean,
      inMatrix?: boolean,
      inInvalidQuestion?: boolean,
      correction?: Correction,
    }) => (
      <div
        className={classNames([
          isIE11 && p.classes.ieFlexSVG,
          isIE11 && p.classes.ieIconContainer,
          !isIE11 && p.classes.iconContainer,
        ])}
      >
        <span
          className={classNames([
            p.type === 'radio' && p.classes.radioIconBackground,
            p.type === 'radio' && isIE11 && p.classes.ieRadioIconBackground,
            p.type !== 'radio' && isIE11 && p.classes.ieIconBackground,
            p.correction && !p.correction.correct && p.classes.invalidIcon,
            p.classes.iconBackground,
          ])}
        />
        <InputIcon
          type={p.type}
          disabled={p.disabled}
          checked={p.checked}
          inMatrix={p.inMatrix}
          inInvalidQuestion={p.inInvalidQuestion}
          correction={p.correction}
        />
      </div>
    ),
  ),
);

const InputIcon = injectSheet(style)(
  (p: {
    type: 'radio' | 'checkbox',
    checked: boolean,
    classes: Classes,
    inMatrix?: boolean, // used by stylesheet
    inInvalidQuestion?: boolean,
    correction?: Correction,
    disabled: boolean, // used by stylesheet
  }) => {
    const iconClassNames = classNames([
      p.inInvalidQuestion && p.classes.invalidIcon,
      p.correction &&
        p.correction.correct != null &&
        !p.correction.correct &&
        p.classes.invalidIcon,
      p.classes.icon,
      p.disabled && p.classes.disabledIcon,
    ]);
    return p.type === 'radio' ? (
      p.checked ? (
        <FontAwesomeIcon
          icon={['far', 'dot-circle']}
          className={iconClassNames}
        />
      ) : (
        <FontAwesomeIcon icon={['far', 'circle']} className={iconClassNames} />
      )
    ) : p.checked ? (
      <FontAwesomeIcon icon={'check-square'} className={iconClassNames} />
    ) : (
      <FontAwesomeIcon icon={['far', 'square']} className={iconClassNames} />
    );
  },
);

const keepVisibleHorizontally = (matrixBody, matrixCell) => {
  // Magic number - make sure this matches width in rowHeading in the matrix stylesheet + 4px focus frame
  const rowHeadingWidth = 199;
  const elementWidth = matrixCell.offsetWidth + 4; // 4px focus frame
  const matrixWidth = matrixBody.offsetWidth;
  const matrixOffset = matrixBody.offsetLeft;
  const scrollPosition = matrixBody.scrollLeft;
  const horizontalPosition = matrixCell.offsetLeft - matrixOffset;
  const maxPosition = scrollPosition + matrixWidth - elementWidth;

  if (horizontalPosition < scrollPosition + rowHeadingWidth) {
    matrixBody.scrollLeft = horizontalPosition - rowHeadingWidth;
  } else if (horizontalPosition > maxPosition) {
    matrixBody.scrollLeft += horizontalPosition - maxPosition;
  }
};

const keepVisibleVertically = (matrixBody, cellContainer, matrixHeader) => {
  if (document.body) {
    const headerHeight = matrixHeader.offsetHeight;
    // $FlowIgnore
    const elementPosition = cellContainer.offsetTop;
    const currentScroll = document.body.scrollTop;

    if (elementPosition < currentScroll + headerHeight) {
      document.body.scrollTop = elementPosition - headerHeight;
    }
  }
};

const onFocusKeepVisible = (matrixId: string, id: string) => {
  const matrix = document.getElementById(matrixId);
  const matrixBody = matrix && matrix.children[1];
  const matrixHeader = matrix && matrix.children[0];
  const matrixCell = document.getElementById(`cell-${id}`);
  const cellContainer = matrixCell && matrixCell.parentElement;
  if (matrixBody && matrixHeader && matrixCell && cellContainer) {
    window.setTimeout(() => {
      keepVisibleHorizontally(matrixBody, matrixCell);
      keepVisibleVertically(matrixBody, cellContainer, matrixHeader);
    }, 150);
  }
};

export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export const UnstyledOptionElementContainer = (p: {
  checked: boolean,
  type: 'radio' | 'checkbox',
  onSelect: () => void,
  children: React.Node,
  disabled: boolean,
  horizontal?: boolean,
  hasImage?: boolean,
  inMatrix?: boolean,
  noBackground?: boolean,
  onKeyDown?: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  first?: boolean,
  last?: boolean,
  classes: Classes,
  inInvalidQuestion?: boolean,
  id: string,
  matrixId: string,
  focusClass?: string,
  focusElement?: boolean,
  correction?: Correction,
  setHeading?: Function,
  unsetHeading?: Function,
  cellRef?: Object,
  overrideStyle?: string,
}) => (
  <React.Fragment>
    {/* The 'type' prop guarantees that this element is interactive */
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */}
    <div
      className={
        p.overrideStyle
          ? p.overrideStyle
          : classNames([
              p.classes.option,
              !p.disabled && p.classes.hoverableOption,
              !p.disabled && p.checked && p.classes.selectedOption,
              p.horizontal && p.classes.horizontalOptionContainer,
              p.hasImage && p.classes.imageOption,
              p.hasImage && 'image-alternative',
              p.inInvalidQuestion && p.classes.invalidOption,
              p.inInvalidQuestion && p.inMatrix && p.classes.invalidBorder,
              p.inMatrix && p.classes.matrixOption,
              (p.noBackground || p.hasImage) &&
                p.classes.optionWithoutBackground,
              p.focusElement && p.focusClass,
              p.disabled && p.classes.disabled,
              !p.hasImage &&
                p.correction &&
                p.correction.correct != null &&
                (p.correction.correct
                  ? p.classes.correct
                  : p.classes.incorrect),
            ])
      }
      role={p.type}
      id={p.id}
      aria-checked={p.checked}
      onKeyDown={p.onKeyDown}
      ref={p.cellRef}
      onMouseEnter={p.inMatrix && p.setHeading}
      onMouseLeave={p.inMatrix && p.unsetHeading}
      onFocus={
        p.inMatrix && !isIE11
          ? () => {
              onFocusKeepVisible(p.matrixId, p.id);
              p.setHeading && p.setHeading();
            }
          : p.inMatrix && p.setHeading
      }
      onBlur={p.inMatrix && p.unsetHeading}
      tabIndex={
        p.first
          ? 0
          : p.noBackground || (!p.checked && p.type === 'radio')
          ? -1
          : 0
      }
      onKeyPress={onSelectorKey(p.onSelect)}
      onClick={p.onSelect}
    >
      {p.children}
    </div>
    {/* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */}
    {!p.horizontal && !p.last && !p.inMatrix && <br />}
  </React.Fragment>
);

export const OptionElementContainer = injectSheet(style)(
  UnstyledOptionElementContainer,
);

const InputElement = (p: {
  type: 'radio' | 'checkbox',
  checked: boolean,
  onClick: () => void,
  id: string,
  disabled: boolean,
  ariaLabel?: string,
  inInvalidQuestion?: boolean,
  correction?: Correction,
  classes: Classes,
}) => (
  <div className={p.classes.inputElementContainer}>
    <OptionInputElement
      id={p.id}
      disabled={p.disabled}
      checked={p.checked}
      onClick={p.onClick}
      ariaLabel={p.ariaLabel}
      type={p.type}
    />
    <InputIconWithBackground
      type={p.type}
      checked={p.checked}
      inInvalidQuestion={p.inInvalidQuestion}
      correction={p.correction}
      disabled={p.disabled}
    />
  </div>
);
export const StyledInputElement = injectSheet(style)(InputElement);

const ImageLabelContents = injectSheet(style)(
  ({
    classes,
    imageElement,
    labelContents,
    horizontal,
    correction,
    checked,
  }) => (
    <div className={classes.optionLabelContent}>
      <div className={classes.optionImage}>{imageElement}</div>
      <div
        className={classNames([
          classes[magicClassNames.imageInputElementContainer],
          horizontal && classes.horizontalImageOptionContainer,
          'image-alternative-label',
          checked && classes.selectedOption,
          correction &&
            correction.correct != null &&
            (correction.correct ? classes.correct : classes.incorrect),
        ])}
      >
        <div className={classNames([classes.imageInputElement])}>
          {labelContents}
        </div>
      </div>
    </div>
  ),
);

export const OptionElement = injectSheet(style)(
  (p: {
    answerOption: Backend.AnswerOption,
    disabled: boolean,
    checked: boolean,
    onSelect: () => void,
    onKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
    ariaLabel?: string,
    type: 'radio' | 'checkbox',
    inInvalidQuestion?: boolean,
    horizontal: boolean,
    questionAnswerOptionId?: string,
    focusClass?: string,
    focusElement?: boolean,
    isIdOfFirstOption?: string => boolean,
    last: boolean,
    classes: Classes,
    correction?: Correction,
    correctButNotSelected?: boolean,
    overrideStyle?: string,
  }) => {
    const {
      answerOptionId: id,
      text,
      imageUrl,
      image,
      altText,
    } = p.answerOption;
    const labelContents = (
      <React.Fragment>
        <StyledInputElement
          id={p.questionAnswerOptionId || id.toString()}
          disabled={p.disabled}
          checked={p.checked}
          onClick={p.onSelect}
          ariaLabel={p.ariaLabel}
          type={p.type}
          inInvalidQuestion={p.inInvalidQuestion}
          correction={p.correction}
          classes={p.classes}
        />
        <span className={p.classes.text}>{ReactHtmlParser(text)}</span>
        {p.correction && p.correction.correct != null && (
          <QuizMark correct={p.correction.correct} />
        )}
        {p.correctButNotSelected && <QuizSolution />}
      </React.Fragment>
    );
    const imageElement = (
      <ReferencedImage imageUrl={imageUrl} image={image} altText={altText} />
    );
    const hasImage = !!(imageUrl || image);
    return (
      <OptionElementContainer
        overrideStyle={p.overrideStyle}
        classes={p.classes}
        horizontal={p.horizontal}
        disabled={p.disabled}
        checked={p.checked}
        correction={p.correction}
        onSelect={p.disabled ? () => {} : p.onSelect}
        type={p.type}
        onKeyDown={p.disabled ? () => {} : p.onKeyDown}
        hasImage={hasImage}
        focusElement={p.focusElement}
        focusClass={p.focusClass}
        first={
          p.isIdOfFirstOption &&
          p.isIdOfFirstOption(
            p.questionAnswerOptionId ? p.questionAnswerOptionId : '',
          )
        }
        last={p.last}
        id={p.questionAnswerOptionId}
        inInvalidQuestion={p.inInvalidQuestion}
      >
        {/* eslint-disable jsx-a11y/label-has-for */}
        <label
          onClick={event => {
            // The label is inside of a div that has its own onClick handler, so clicking the label will select the option as expected.
            event.preventDefault();
          }}
          className={classNames([
            p.classes.optionLabel,
            hasImage && p.classes.imageLabel,
          ])}
          htmlFor={answerIdString(id)}
        >
          {hasImage ? (
            <ImageLabelContents
              horizontal={p.horizontal}
              imageElement={imageElement}
              labelContents={labelContents}
              correction={p.correction}
              checked={p.checked}
            />
          ) : (
            labelContents
          )}
        </label>
      </OptionElementContainer>
    );
  },
);

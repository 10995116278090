// @flow

const IFRAME_MODES = {
  iframeEmbedded: 'iframeEmbedded',
};

const ADMIN_MODES = {
  preview: 'preview',
};

const STANDALONE_MODES = {
  showSubmission: 'showSubmission',
  regular: 'regular',
  editSubmission: 'editSubmission',
};

const INTERNAL_MODES = { reactEmbedded: 'reactEmbedded' };

export type StandaloneMode = $Keys<typeof STANDALONE_MODES>;
export type IframeMode = $Keys<typeof IFRAME_MODES>;
export type AdminMode = $Keys<typeof ADMIN_MODES>;
export type ModeWithEndpoint = StandaloneMode | IframeMode | AdminMode;
export type InternalMode = $Keys<typeof INTERNAL_MODES>;
export type Mode = ModeWithEndpoint | InternalMode;

export const MODES_WITH_ENDPOINTS: ModeWithEndpoint[] = Object.keys({
  ...IFRAME_MODES,
  ...ADMIN_MODES,
  ...STANDALONE_MODES,
});

// @flow

import * as React from 'react';
import injectSheet from 'react-jss';

import { ConnectedFormContainer } from '../form/FormContainer';
import type { Mode } from '../modes';

const StyledConnectedFormContainer = injectSheet({
  formWrapper: { '& textarea': { width: 400 } },
})(ConnectedFormContainer);

export const ReactEmbeddedFormContainer = (p: {
  onAbort?: () => void,
  submitText?: string,
  abortText?: string,
  mode: ?Mode,
}) => (
  <StyledConnectedFormContainer
    noHeading
    noInfoMessages
    onAbort={p.onAbort}
    submitText={p.submitText}
    abortText={p.abortText}
    mode={p.mode}
  />
);

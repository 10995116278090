// @flow

import * as React from 'react';
import injectSheet from 'react-jss';

const style = {
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

const Image = (p: { classes: Object, src: string, alt: string }) => (
  <img className={p.classes.image} src={p.src} alt={p.alt || null} />
);

const StyledImage = injectSheet(style)(Image);

export default StyledImage;

// @flow

import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import type { FormType } from '../../backend/legacyJsonBackend';
import { ConfirmDialog } from '../../modals/ConfirmDialog';
import type { Dispatch } from '../../store';

const style = {};
type Classes = { [$Keys<typeof style>]: string };

const Dialog = injectSheet(style)(
  (p: {
    classes: Classes,
    hideModal: () => void,
    onOK: () => void,
    formType: FormType,
  }) => {
    return (
      <I18n>
        {({ i18n }) => (
          <ConfirmDialog
            destructive
            confirmText={i18n._(
              p.formType === 'SIGN_UP'
                ? t`OK, slett påmeldingen`
                : t`OK, slett svaret`,
            )}
            confirmAction={p.onOK}
            abortAction={p.hideModal}
            dialogTitle={i18n._(
              p.formType === 'SIGN_UP'
                ? t`Er du sikker på at du vil slette påmeldingen?`
                : t`Er du sikker på at du vil slette svaret?`,
            )}
            lockWhenSubmitting
          />
        )}
      </I18n>
    );
  },
);

export const ConfirmDeleteModal = connect(
  state => ({ formType: state.answer.form.meta.type }),
  (dispatch: Dispatch) => ({
    hideModal: () => {
      dispatch({ type: 'HIDE_MODAL' });
    },
    onOK: () => {
      dispatch({
        type: 'DELETE_SUBMISSION_REQUESTED',
      });
    },
  }),
)(Dialog);

export const triggerDeleteModal = (dispatch: Dispatch) => {
  dispatch({
    type: 'SHOW_MODAL',
    modalType: 'confirmDeleteSubmission',
  });
};

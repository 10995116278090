// @flow

import * as React from 'react';
import injectSheet from 'react-jss';
import { responsive } from 'react-usit-ui';

import { noScroll } from '../preventScrolling';
import { focusableContainerId } from './ModalRoot';

const style = {
  ...noScroll,
  backdrop: {
    position: 'fixed',
    zIndex: 198,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    overflow: 'scroll',
    display: 'flex',
  },
  dialogContainer: {
    margin: 'auto',
    padding: '5px',
  },
  dialog: props => props.customModalStyle.dialog,
  header: props => props.customModalStyle.header,
  body: props => props.customModalStyle.body,
  [responsive.media.max640]: {
    dialog: props => props.customModalStyle.responsive.dialog,
    header: props => props.customModalStyle.responsive.header,
    body: props => props.customModalStyle.responsive.body,
  },
};

const ModalTemplate = (p: {
  classes: Object,
  abortAction: () => void,
  mainContent: Object,
  headerContent: Object,
  customModalStyle: Object,
}) => (
  <div
    className={p.classes.backdrop}
    id="modal-backdrop"
    role="presentation"
    onClick={(e: SyntheticInputEvent<HTMLElement>) => {
      if (e.target.id === 'modal-backdrop') {
        p.abortAction();
      }
    }}
  >
    <div className={p.classes.dialogContainer}>
      <div id={focusableContainerId} tabIndex={-1} className={p.classes.dialog}>
        <div className={p.classes.header}>{p.headerContent}</div>
        <div className={p.classes.body}>{p.mainContent}</div>
      </div>
    </div>
  </div>
);

export const createModal = () => injectSheet(style)(ModalTemplate);

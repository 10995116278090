// @flow

import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';

import * as Backend from '../backend/legacyJsonBackend';
import Accordion from '../components/Accordion';
import InfoSummary from '../components/InfoSummary';
import * as descriptives from './descriptives';
import ReferencedImage from './ReferencedImage';

type Classes = { [$Keys<typeof style>]: string };

const style = {
  descriptionContainer: {
    fontSize: '18px',
    '& span': {
      display: 'inline-block',
      fontSize: '18px',
      verticalAlign: 'top',
    },
  },
  image: {
    maxWidth: '100%',
  },
};

const Content = (p: { element: Backend.Element }) => {
  const { elementType: type } = p.element;
  if (type === 'HEADING') {
    return (
      <descriptives.Heading
        id={p.element.elementId}
        text={ReactHtmlParser(p.element.title)}
      />
    );
  } else if (
    /* TODO: Experimental feature for DIGIKOST project
     * Works for Matrix element followed by a text element with this string
     * Matrix answer options must contain numeric codebook values.
     */
    type === 'TEXT' &&
    p.element.description === '<p>NETTSKJEMA_INFO_BLOCK_MATRIX_COUNTER</p>\n'
  ) {
    return <InfoSummary element={p.element} />;
  } else if (type === 'TEXT') {
    return <descriptives.Text text={p.element.description} />;
  } else if (type === 'IMAGE') {
    return (
      <ReferencedImage
        altText={p.element.altText}
        image={p.element.image}
        imageUrl={p.element.imageUrl}
      />
    );
  } else if (type === 'ACCORDION') {
    return <Accordion element={p.element} />;
  }
};

function shouldRenderElement(element: Backend.Element) {
  if (element.elementType === 'IMAGE' && !(element.image || element.imageUrl)) {
    return false;
  }
  return true;
}

const NoQuestionsElement = injectSheet(style)(
  (p: {
    element: Backend.Element,
    preContent?: React.Node,
    classes: Classes,
  }) => {
    return (
      <React.Fragment>
        {p.preContent}
        {p.element && shouldRenderElement(p.element) ? (
          <div className={p.classes.descriptionContainer}>
            <Content element={p.element} />
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  },
);

export default NoQuestionsElement;

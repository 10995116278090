// @flow

import queryString from 'query-string';

import * as ApiV2 from '../backend/apiV2.js';
import * as LegacyBackend from '../backend/legacyJsonBackend';
import create from '../typeCreators';
import * as InputTypes from './types';

const getInitialInputFromLocation = () => {
  const parsed = queryString.parse(location.search);
  if (parsed.referenceId) {
    return parsed.referenceId;
  } else {
    return 'missing';
  }
};

const valueTypeForElement = (element: LegacyBackend.Element) => {
  const { elementType, dateFormat } = element;
  if (elementType === 'CHECKBOX' || elementType === 'MATRIX_CHECKBOX') {
    return 'MULTIPLE_OPTIONS';
  }
  if (['RADIO', 'MATRIX_RADIO', 'SELECT'].includes(elementType)) {
    return 'SINGLE_OPTION';
  }
  if (elementType === 'DATE') {
    return dateFormat;
  }
  if (elementType === 'ATTACHMENT') {
    return 'STORED_FILE';
  }
  return 'TEXT';
};

type GivenValue =
  | string
  | number
  | number[]
  | { answerAttachmentId: number, filename: string };

const inputWithValue: (
  InputTypes.FormInput,
  LegacyBackend.Element,
  GivenValue,
) => InputTypes.FormInput = (input, element, value) => {
  const newValue: $FlowIgnore = {
    given: value,
    type: valueTypeForElement(element),
  };
  return {
    ...input,
    value: newValue,
  };
};

const dateTimeInputWithValue: (
  InputTypes.FormInput,
  string,
) => InputTypes.FormInput = (input, answer) => {
  const splitDateTime = answer.split(' ');
  const givenDate: string = splitDateTime[0];
  const givenTime: string = splitDateTime[1];
  return {
    ...input,
    value: {
      givenDate: givenDate,
      givenTime: givenTime,
      type: 'DATE_TIME',
    },
  };
};

export const inputsFromSubmissionAnswers: (
  ApiV2.Answer[],
  InputTypes.FormInput[],
  LegacyBackend.Element[],
) => InputTypes.FormInput[] = (answers, inputs, elements) =>
  inputs.map(input => {
    if (answers == null) {
      return input;
    }

    const answer = answers.find(
      answer => answer.questionId === input.questionId,
    );

    if (answer == null) {
      return input;
    }
    const element = elements.find(
      element =>
        element.questions != null &&
        element.questions.find(
          question => question.questionId === input.questionId,
        ) != null,
    );
    if (element == null) {
      throw new Error('Input does not have matching element');
    }
    if (answer.attachments != null && answer.attachments.length > 0) {
      const attachment = answer.attachments[0];
      return inputWithValue(input, element, {
        answerAttachmentId: attachment.answerAttachmentId,
        filename: attachment.fileName,
      });
    }
    if (answer.textAnswer != null) {
      if (
        element.elementType === 'DATE' &&
        element.dateFormat === 'DATE_TIME'
      ) {
        return dateTimeInputWithValue(input, answer.textAnswer);
      }
      return inputWithValue(input, element, answer.textAnswer);
    }
    if (answer.answerOptions != null) {
      const selectedOptionIds = answer.answerOptions.map(
        answerOption => answerOption.answerOptionId,
      );
      return inputWithValue(
        input,
        element,
        element.elementType === 'CHECKBOX' ||
          element.elementType === 'MATRIX_CHECKBOX'
          ? selectedOptionIds
          : selectedOptionIds[0],
      );
    }
    return input;
  });

export const initialInputsWithAutofills = (
  answers: LegacyBackend.Answers,
  elements: LegacyBackend.Element[],
) => initialAutofill(answers, elements);

const initialAutofill: (
  LegacyBackend.Answers,
  LegacyBackend.Element[],
) => InputTypes.FormInput[] = (answers, elements) => {
  const inputs = initialInputs(elements);
  return inputs.map(input => {
    const answer = answers[input.questionId.toString()];
    if (
      input.allowsAutofill &&
      answer !== undefined &&
      answer.textAnswer !== undefined
    ) {
      return {
        ...input,
        value: {
          given: answer.textAnswer,
          disabled: answer.readOnly,
          type: 'TEXT',
        },
      };
    }
    return input;
  });
};
function initialInputs(
  elements: LegacyBackend.Element[],
): InputTypes.FormInput[] {
  const NonNull = Boolean;
  return elements
    .map(initialInput)
    .filter(NonNull)
    .reduce(
      (
        a: InputTypes.FormInput[],
        b: InputTypes.FormInput | InputTypes.FormInput[],
      ) => (Array.isArray(b) ? [...a, ...b] : [...a, b]),
      [],
    );
}

function initialInput(element: LegacyBackend.Element) {
  switch (element.elementType) {
    case 'RADIO':
    case 'SELECT': {
      const preselectedRadios = element.questions[0].answerOptions.find(
        option => option.preselected,
      );
      return {
        value:
          preselectedRadios == null
            ? create.input.notProvided()
            : create.input.singleOption(preselectedRadios.answerOptionId),
        validation: create.validation.unperformed(),
        questionId: element.questions[0].questionId,
      };
    }
    case 'CHECKBOX': {
      const preselectedBoxes = element.questions[0].answerOptions.filter(
        option => option.preselected,
      );
      return {
        value:
          preselectedBoxes.length === 0
            ? create.input.notProvided()
            : create.input.multipleOptions(
                preselectedBoxes.map(opt => opt.answerOptionId),
              ),
        validation: create.validation.unperformed(),
        questionId: element.questions[0].questionId,
      };
    }
    case 'MATRIX_CHECKBOX': {
      const preselectedCheckboxes = element.answerOptions.filter(
        option => option.preselected,
      );
      return element.questions.map(question => ({
        value:
          preselectedCheckboxes.length === 0
            ? create.input.notProvided()
            : create.input.multipleOptions(
                preselectedCheckboxes.map(opt => opt.answerOptionId),
              ),
        validation: create.validation.unperformed(),
        questionId: question.questionId,
      }));
    }
    case 'MATRIX_RADIO': {
      const preselectedRadioOption = element.answerOptions.find(
        option => option.preselected,
      );
      return element.questions.map(question => ({
        value:
          preselectedRadioOption == null
            ? create.input.notProvided()
            : create.input.singleOption(preselectedRadioOption.answerOptionId),
        validation: create.validation.unperformed(),
        questionId: question.questionId,
      }));
    }
    case 'SUBMISSION_REFERENCE':
      return {
        value: {
          given: getInitialInputFromLocation(),
          disabled: true,
          type: 'TEXT',
        },
        validation: create.validation.passing(),
        questionId: element.questions[0].questionId,
        allowsAutofill: true,
      };
    case 'LINEAR_SCALE':
    case 'QUESTION':
    case 'USERNAME':
    case 'NAME':
    case 'QUESTION_MULTILINE':
    case 'NATIONAL_ID_NUMBER':
    case 'IDPORTEN_NATIONAL_ID':
    case 'EMAIL':
    case 'NUMBER':
    case 'PHONE':
    case 'DATE':
    case 'ATTACHMENT':
      return {
        value: create.input.notProvided(),
        validation: create.validation.unperformed(),
        questionId: element.questions[0].questionId,
        allowsAutofill: !['ATTACHMENT', 'DATE'].includes(element.elementType),
      };
    default:
      return null;
  }
}

export const forTesting = { initialInput };
